/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import HttpClient from '../../common/utils/httpClient';
import {RECOVERY} from "../../common/constants/actionTypes";


export function recoveryPassword(data) {
   return dispatch =>
      HttpClient.postLogout(
         data,
         dispatch,
         RECOVERY,
         "v5/me/reset_password/"
      );
}

export function newPassword(data, uid, token) {
   return dispatch =>
      HttpClient.postLogout(
         data,
         dispatch,
         RECOVERY,
         `v5/me/reset_password/${uid}/${token}/`
      );
}
