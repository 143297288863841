import React, {Component} from 'react';
import {connect} from 'react-redux';
import PoliticsGeneral from "../../common/components/Custom/PoliticsGeneral";
import PoliticsLaPositiva from "../../common/components/Custom/PoliticsLaPositiva";
import PoliticsBancoElComercio from "../../common/components/Custom/PoliticsBancoElComercio";
import {getApp, getAppByApiKey} from "../Bederr/actions";
const $ = require('jquery');

class Politics extends Component {
    constructor(props) {
        super(props);
        this.state = {
           privacy_policies: ""
        };
    }

   componentDidMount() {
      $('html').removeClass("notOverflow");
      if(process.env.REACT_APP_NAME_APP==="banbif"){
         this.getUid(process.env.REACT_APP_APY_KEY)
      }
   }

   getUid = async uid => {
      const appFieldsResponse = await this.props.dispatch(
         getAppByApiKey(uid)
      );
      if (
         appFieldsResponse !== undefined &&
         appFieldsResponse != null
      ) {

         let {status, data} = appFieldsResponse.value;
         if (status === 200 || status === 201) {
            this.getTerms(data[0].uid)
         } else {
            if (status === 400) {
               this.setState({privacy_policies: ""})
            }
         }

      }
   }

   getTerms = async uid => {
      const appFieldsResponse = await this.props.dispatch(
         getApp(uid)
      );
      if (
         appFieldsResponse !== undefined &&
         appFieldsResponse != null
      ) {
         let {status, data} = appFieldsResponse.value;
         if (status === 200 || status === 201) {
            const {site} = data;
            if(site && site.privacy_policies){
               if(site.privacy_policies !== this.state.privacy_policies){
                  this.setState({privacy_policies: site.privacy_policies})
                  $("#terms-box").html(`<div class="col-lg-12 mt-4 mb-4">${site.privacy_policies}</div>`)
               }
            }
         } else {
            if (status === 400) {
               this.setState({privacy_policies: ""})
            }
         }

      }
   }

    render() {
        switch (process.env.REACT_APP_NAME_APP) {
           case "lapositiva":
              return <PoliticsLaPositiva/>;
           case "bancoelcomercio":
              return <PoliticsBancoElComercio/>;
           case "banbif":
              return (
                 <div className="container tyc">
                    <div className="row" id={"terms-box"}>
                    </div>
                 </div>
              );
            default:
                return <PoliticsGeneral/>
        }

    }

}

export default connect()(Politics);
