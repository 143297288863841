import React, {Component} from "react";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import Validator from "../../common/utils/validator";
import {APP_TYPE_WHITELABEL, buildConfig} from "../../common/utils/generic";


import {Link} from "react-router-dom";
import FacebookLogin from "../../common/components/widgets/FacebookLogin";
import {getCompany, logInFacebook, logInWithPassword} from "./actions";
import {setToken} from "../../reducersapp/auth";
import firebase from "firebase";


var images = require.context("../../common/images", true);

class LoginWhitePassword extends Component {
   constructor(props) {
      super(props);
      this.state = {
         business: "",
         password: "",
         text_title: "",
         text_bottom_logo: "",
         email: "",
         isLoading: false,
         app_allow_fb_login: buildConfig.app_allow_fb_login,
      };
   }

   componentDidMount() {
      this.setState({business: process.env.REACT_APP_NAME_APP});
      let remoteConfig = firebase.remoteConfig()
      if(remoteConfig != null){
         this.setState({text_title:remoteConfig.getString('app_landing_text')});
         this.setState({text_bottom_logo:remoteConfig.getString('text_bottom_logo')});
         this.setState({app_allow_fb_login:remoteConfig.getBoolean('app_allow_fb_login')});

      }
      this.validator = new Validator([
         {
            id: "email",
            ref: this.emailInput,
            rules: ["required", "email"]
         },
         {
            id: "password",
            ref: this.passwordInput,
            rules: ["required"]
         }
      ]);

      if (process.env.REACT_APP_TYPE === APP_TYPE_WHITELABEL) {
         let name_app = process.env.REACT_APP_NAME_APP;
         let api_key = process.env.REACT_APP_APY_KEY;
         window.localStorage.setItem(`apikey${name_app}`, api_key);
      }
   }


   handleDni = event => this.setFormValues({dni: event.target.value});
   handleEmail = event => this.setFormValues({email: event.target.value});

   handlePassword = event =>
      this.setFormValues({password: event.target.value});

   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   searchBusiness = async data => {
      try {
         const companyResponse = await this.props.dispatch(
            getCompany(data.business)
         );
         let these = this;
         let mydata = data;
         if (companyResponse !== undefined && companyResponse != null) {
            let {status, data} = companyResponse.value;
            if (status === 200) {
               const {logo, name} = data;
               let name_app = process.env.REACT_APP_NAME_APP;
               let api_key = process.env.REACT_APP_APY_KEY;

               window.localStorage.setItem(`apikey${name_app}`, api_key);
               window.localStorage.setItem(`logo${name_app}`, logo);
               window.localStorage.setItem(`name${name_app}`, name);
               if (mydata.login !== undefined && mydata.login === "FB") {
                  these.loginFB(mydata);
               } else {
                  these.loginUser(mydata);
               }
            } else {
               these.setState({isLoading: false});
               if (this.state.app_type !== APP_TYPE_WHITELABEL)
                  these.addNotification(
                     "Compañia no encontrada",
                     "error"
                  );
            }
         } else {
            these.setState({isLoading: false});
            these.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }

      } catch (error) {
         this.setState({isLoading: false});
         if (this.state.app_type !== APP_TYPE_WHITELABEL)
            this.addNotification("Compañia no encontrada", "error");
      }
   };

   loginUser = async data => {
      try {
         const logInResponse = await this.props.dispatch(
            logInWithPassword(data)
         );
         let these = this;
         if (
            logInResponse !== undefined &&
            logInResponse != null
         ) {
            let {status, data} = logInResponse.value;
            if (status === 200) {
               const {token} = data;
               let name = process.env.REACT_APP_NAME_APP;
               window.localStorage.setItem(`token${name}`, token);
               these.addNotification(
                  "Se inició sesión satisfactoriamente",
                  "success"
               );
               these.setState({isLoading: false});
               these.props.dispatch(setToken(token));
               setTimeout(() => {
                  (document.location.href = '/');
                  return true;
               }, 1000);
            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Documento o Contraseña incorrectos",
                  "error"
               );
            }

         } else {
            this.setState({isLoading: false});
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({isLoading: false});
         this.addNotification("Documento o Contraseña incorrectos", "error");
         //setTimeout(() => (document.location.href = `/register?dni=${dni}&document_type=${document_type}`), 1000);
      }
   };

   loginFB = async data => {
      try {
         const logInResponse = await this.props.dispatch(logInFacebook(data));
         let these = this;
         if (
            logInResponse !== undefined &&
            logInResponse != null
         ) {

            let {status, data} = logInResponse.value;
            if (status === 200) {
               const {token} = data;
               let name = process.env.REACT_APP_NAME_APP;
               window.localStorage.setItem(`token${name}`, token);
               these.addNotification(
                  "Se inició sesión satisfactoriamente",
                  "success"
               );
               these.setState({isLoading: false});
               these.props.dispatch(setToken(token));
               setTimeout(() => {
                  (document.location.href = '/');
                  return true;
               }, 1000);
            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Documento o Contraseña incorrectos",
                  "error"
               );
            }
         } else {
            this.setState({isLoading: false});
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({isLoading: false});
         this.addNotification("Documento o Contraseña incorrectos", "error");
         //setTimeout(() => (document.location.href = `/register?dni=${dni}&document_type=${document_type}`), 1000);
      }
   };

   selectDocumentType = (e, name, type_id) => {
      e.preventDefault();
      this.setState({type_text: name, type: type_id});
   };

   handleSubmit = async event => {
      event.preventDefault();

      const {
         email,
         business,
         password
      } = this.state;
      let data = {password, business};
      data.email = email;
      const isValid = this.validator.validate(data);

      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }

      this.setState({isLoading: true});
      if (!this.state.isLoading) {
         this.searchBusiness(data);
      }
   };

   handleOptionChange = event => {
      let val = event.currentTarget.querySelector("input").value;
      this.setState({
         selectedOption: val
      });
   };

   onFacebookLogin = (loginStatus, response) => {
      if (
         loginStatus === true &&
         response != null &&
         (response.authResponse !== undefined && response.authResponse !== null)
      ) {
         this.setState({
            username: response.authResponse.accessToken,
            userID: response.authResponse.userID
         });
         let {business} = this.state;
         let newData = {business};
         try {
            newData.access_token = response.authResponse.accessToken;
            newData.uid = response.authResponse.userID;
            newData.login = "FB";
            if (!this.state.isLoading) {
               this.setState({isLoading: true});
               this.searchBusiness(newData);
            }
         } catch (e) {
         }
      }
   };

   render() {
      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      return (
         <div className="col login-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container ">
               <header
                  className="hello"
                  style={{marginBottom: 25, marginTop: 25}}
               >
                  <h2 className="text-app">
                     {this.state.text_title}
                  </h2>
                  <img alt="logo" className="logo" src={logo}/>
                  <div className="text-bottom">
                     <span>{this.state.text_bottom_logo}</span>
                  </div>
               </header>
               {this.state.app_allow_fb_login === true && (
                  <div>
                     <FacebookLogin onLogin={this.onFacebookLogin}>
                        <button
                           className={
                              "btn btn-whitelabel btn-lg btn-block btn-fb"
                           }
                           disabled={!!this.state.isLoading}
                        >
                           Login con Facebook
                        </button>
                     </FacebookLogin>
                     <p id={"p-extra-login-fb"}>o</p>
                  </div>
               )}


               <form onSubmit={this.handleSubmit}>


                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.emailInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.email}
                           onChange={this.handleEmail}
                           type="email"
                           placeholder="Correo Electrónico"
                           required=""
                        />
                     </div>
                  </div>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.passwordInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.password}
                           onChange={this.handlePassword}
                           type="password"
                           placeholder="Contraseña"
                           required=""
                        />
                     </div>
                  </div>
                  <div style={{marginBottom: 10, marginTop: 20}}>
                     <button
                        type="submit"
                        className="btn btn-whitelabel btn-lg btn-block"
                        onClick={this.handleSubmit}
                        disabled={!!this.state.isLoading}
                     >
                        {this.state.isLoading && "Cargando..."}
                        {!this.state.isLoading && "Ingresar"}
                     </button>
                  </div>

                  <div className="links link-recovery">
                     <Link to={"/recovery"}>¿Olvidaste tu contraseña?</Link>
                  </div>
                  <div className="links link-register">
                     <Link to={"/register"}>

                        ¿Aún no tienes cuenta? <span>Regístrate{" "}</span>
                     </Link>
                  </div>
               </form>
            </div>
         </div>
      );
   }
}

export default connect()(LoginWhitePassword);
