/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */


import HttpClient from "../../common/utils/httpClient";
import {
   BANNERS_FETCH,
   CLEAN_PAYMENT,
   DETAIL_OFFER,
   DETAIL_OFFER_INTEREST,
   DETAIL_PROGRAM_PROMOTION,
   GET_PROGRAM_PROMOTIONS,
   MORE_PROGRAM_PROMOTIONS,
   PAY_PROGRAM_PROMOTION,
   PLACES_FETCH,
   PLACES_MORE_FETCH,
   REDEMPTIONS_FETCH,
   REDEMPTIONS_MORE_FETCH,
   RELATED_PROMOTIONS,
   TRANSFER_OFFER
} from "../../common/constants/actionTypes";


export function getProgramPromotions(programId, sections, localities, search,  categories, services, ordering, is_featured) {

   let params = "";
   if (sections != null && sections !== "")
      params = params + `sections=${sections}`;

   if (search != null && search !== "") {
      params =  params === ""  ? `search=${search}` : `${params}&search=${search}`;
   }
   if (localities != null && localities !== "") {
      params =  params === ""  ? `localities=${localities}` : `${params}&localities=${localities}`;
   }
   if (categories != null && categories !== "") {
      params =  params === ""  ? `categories=${categories}` : `${params}&categories=${categories}`;
   }
   if (services != null && services !== "") {
      params =  params === ""  ? `services=${services}` : `${params}&services=${services}`;
   }
   if (ordering != null && ordering !== "") {
      params =  params === ""  ? `ordering=${ordering}` : `${params}&ordering=${ordering}`;
   }
   /*if(is_featured===true){

     params =  params === ""  ? `is_featured=True` : `${params}&is_featured=True`;
   }*/

   if (params !== "")
      params = '?' + params;

   return dispatch => HttpClient.get(dispatch, GET_PROGRAM_PROMOTIONS, `v5/me/programs/${programId}/promotions/${params}`);
}

export function getMoreProgramPromotions(next) {
   return dispatch => HttpClient.getUrl(dispatch, MORE_PROGRAM_PROMOTIONS, next);
}


export function getDetailProgramPromotion(programId, promotionId) {
   if(programId!=="" && programId!=="")
   return dispatch => HttpClient.get(dispatch, DETAIL_PROGRAM_PROMOTION, `v5/me/programs/${programId}/promotions/${promotionId}/`);
}

export function getRelatedProgramPromotion(programId, promotionId) {
   return dispatch => HttpClient.get(dispatch, RELATED_PROMOTIONS, `v5/me/programs/${programId}/promotions/${promotionId}/related/`);
}



export function getMorePlaces(nexturl) {
   return dispatch =>
      HttpClient.getUrl(dispatch, PLACES_MORE_FETCH, nexturl);
}

export function getMoreRedemptions(nexturl) {
   return dispatch =>
      HttpClient.getUrl(dispatch, REDEMPTIONS_MORE_FETCH, nexturl);
}


export function getRedemptions(program, promotion) {
   let params = "";
   if (promotion != null && promotion !== "")
      params = params + `pp_uid=${promotion}`;

   if (params !== "")
      params = '?' + params;
   return dispatch =>
      HttpClient.get(
         dispatch,
         REDEMPTIONS_FETCH,
         `v5/me/programs/${program}/redemptions/${params}`
      );
}

export function getPlaces(offerId) {
   return dispatch =>
      HttpClient.get(
         dispatch,
         PLACES_FETCH,
         `v5/me/promotions/${offerId}/places/`
      );
}

export function getBannersByCategory(programId, categoryId) {
   return dispatch =>
      HttpClient.get(
         dispatch,
         BANNERS_FETCH,
         `v5/me/programs/${programId}/ads/?type=IMAGE&section=${categoryId}`
      );
}

export function getDetailOffer(programID, offerId) {
   return dispatch => HttpClient.get(dispatch, DETAIL_OFFER, `v5/me/programs/${programID}/promotions/${offerId}/`);
}


export function sendLead(data, offerId) {
   return dispatch => HttpClient.post( data, dispatch, DETAIL_OFFER_INTEREST, `v4/corporate-offers/${offerId}/interested/`);
}

export function payPromotion(data, programUid, promotionUid) {
   return dispatch => HttpClient.postResponse( data, dispatch, PAY_PROGRAM_PROMOTION, `v5/me/programs/${programUid}/promotions/${promotionUid}/purchase/`);
}

export function cleanPayment() {
   return {
      type: CLEAN_PAYMENT
   };
}


export function transferPromotion(data, programUid, promotionUid) {
   return dispatch => HttpClient.post( data, dispatch, TRANSFER_OFFER, `v5/me/programs/${programUid}/promotions/${promotionUid}/transferred/`);
}
