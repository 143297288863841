import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Badge from "../../common/components/widgets/Badge";
import {Link} from "react-router-dom";
import {PROFILE} from "../../common/constants/actionTypes";
import {changeMenu, editCity} from "../Menu/actions";
import {editUser, getClientDataBoc, getUser} from "../Register/actions";
import {getCountries} from "../../reducersapp/countries";
import firebase from "firebase"
import "./style.scss"
import {buildConfig, TextDefaul} from "../../common/utils/generic";
import Product from "../../common/components/widgets/Product";
import image_mapfre from "../../common/images/mapfre_image.jpg";
import NotificationSystem from "react-notification-system";
const $ = require('jquery');


class DetailProfile extends Component {
   constructor(props) {
      super(props);
      this.state = {
         document_number: "",
         first_name: "",
         first_name_h1: "",
         last_name: "",
         last_name_h1: "",
         email: "",
         mobile_number: "",
         first_level_name: "",
         active_level: "",
         first_level_points: 0,
         first_level: null,
         center_level_name: "",
         center_level_points: 0,
         center_level: null,
         last_level_name: "",
         last_level_points: 0,
         last_level: null,
         percent: "0%",
         percentPoints: "0/0",
         colors_main_color: "#ccc",
         explanation: "",
         isLoadingCity: false,
         isLoading: false,
         badges: [],
         app_profile_show_points: false,
         app_profile_show_levels: false,
         app_profile_allow_edit_profile: false,
         app_profile_show_level_rating_bar: false,
         app_profile_show_badges: false,
         app_profile_show_products: false,
         isFull: false

      };
   }

   changeCity(e, cityId) {
      e.preventDefault();
      this.props.dispatch(editCity(cityId));
   }

   static contextTypes = {
      router: PropTypes.object
   };

   componentDidMount() {
      const {benefit_program} = this.props;
      const badges = benefit_program.badges;
      const badges2 = [];
      const firebaseConf = firebase.remoteConfig()
      let app_profile_show_points = buildConfig.app_profile_show_points;
      let app_profile_show_levels = buildConfig.app_profile_show_levels;
      let app_profile_allow_edit_profile = buildConfig.app_profile_allow_edit_profile;
      let app_profile_show_level_rating_bar = buildConfig.app_profile_show_level_rating_bar;
      let app_profile_show_badges = buildConfig.app_profile_show_badges;
      let app_profile_show_products = buildConfig.app_profile_show_products;
      let colors_main_color = buildConfig.colors_main_color;
      if (firebaseConf != null) {
         app_profile_show_points = firebaseConf.getBoolean("app_profile_show_points");
         colors_main_color = firebaseConf.getBoolean("colors_main_color");
         app_profile_show_levels = firebaseConf.getBoolean("app_profile_show_levels");
         app_profile_allow_edit_profile = firebaseConf.getBoolean("app_profile_allow_edit_profile");
         app_profile_show_level_rating_bar = firebaseConf.getBoolean("app_profile_show_level_rating_bar");
         app_profile_show_badges = firebaseConf.getBoolean("app_profile_show_badges");
         app_profile_show_products = firebaseConf.getBoolean("app_profile_show_products");
      }
      this.setState(
         {
            app_profile_show_points,
            app_profile_show_levels,
            app_profile_allow_edit_profile,
            app_profile_show_level_rating_bar,
            app_profile_show_badges,
            app_profile_show_products,
            colors_main_color
         }
      )
      if (badges != null && app_profile_show_badges) {
         for (var i = 0; i < badges.length; i++) {
            //if(badges[i].is_active){
            badges2.push(badges[i]);
            //}
         }
         if (badges2.length > 0) {
            this.setState({badges: badges2});
         }
      }
      this.props.dispatch(getUser({}));
      this.props.dispatch(getCountries());
      this.props.dispatch(changeMenu(PROFILE));

      if (process.env.REACT_APP_NAME_APP === "bancoelcomercio")
         this.getBocClientDetail();

   }


   componentWillReceiveProps(nextProps, nextContext) {
      let percent = 0;
      const {user, benefit_program} = nextProps;
      if (user != null) {
         let i;
         if (user.document_number.indexOf("DOC-") !== 0)
            this.setState({document_number: user.document_number});
         if (user.first_name != null && user.first_name !== "") {
            this.setState({first_name: user.first_name.split(" ")[0], first_name_h1: user.first_name.split(" ")[0]});
         } else {
            this.setState({first_name: user.first_name, first_name_h1: user.first_name});
         }
         this.setState({last_name: user.last_name, last_name_h1: user.last_name});
         this.setState({email: user.email});
         this.setState({mobile_number: user.mobile_number});

         let current_level = null;
         let next_level = null;
         let levels = benefit_program.levels;
         for (i = 0; i < levels.length; i++) {
            let level = levels[i];
            if (level.is_active) {
               current_level = level;
               if (i + 1 < levels.length) {
                  next_level = levels[i + 1];
                  let rangePoints = next_level.points - current_level.points;
                  let pointsIntoRange =
                     benefit_program.points - current_level.points;
                  percent = pointsIntoRange * (100 / rangePoints);
                  this.setState({percent: `${percent}%`});
                  this.setState({
                     percentPoints: `Tienes ${benefit_program.points}/${
                        next_level.points
                     } puntos`
                  });
               } else {
                  percent = 100;
                  this.setState({
                     percent: `100%`,
                     percentPoints: `Tienes ${benefit_program.points}/${
                        benefit_program.points
                     } puntos`,
                     isFull: true
                  });
               }
               break;
            }
         }
         if (current_level == null) {
            this.setState({percent: `0%`});
            this.setState({percentPoints: `Tienes 0/0 puntos`});
         } else {
            this.setState({active_level: current_level.title});
         }
      }
   }

   getBocClientDetail = async () => {
      try {

         const responseBoc = await this.props.dispatch(getClientDataBoc());
         let these = this;
         if (responseBoc != null) {

            let {status, data} = responseBoc.value;
            if (status === 200) {
               const user = data;
               console.log(user)
            } else {
               these.redirectToLogin();
            }


         } else {
            this.redirectToLogin();
         }
      } catch (error) {
         this.redirectToLogin();
      }
   };

   //PARA MAPFRE
   renderTextMapfreDollar() {
      const {benefit_program} = this.props;

      if (benefit_program != null && process.env.REACT_APP_NAME_APP === "mapfre") {
         return (
            <div className="text-dollar">
               <p className="title-sub-section text-with-span">
                  Recuerda que tus puntos definen el segmento en el que te
                  encuentras y todos tus beneficios, de acuerdo a tu(s)
                  seguro(s) y permanencia en MAPFRE.
               </p>
               <img src={image_mapfre} alt="" style={{maxWidth: "100%"}}/>
               {benefit_program.extra != null &&
                  benefit_program.extra.joined !== null &&
                  benefit_program.extra.joined !== "" && (
                     <p className="title-sub-section text-with-span">
                        Tenemos <span>{this.state.joined}</span> juntos y te
                        recompensamos con MAPFRE Dólares (M$) como beneficio del
                        club MAPFRE.
                     </p>
                  )}
               {benefit_program.extra != null &&
                  benefit_program.extra.mapfre_dollar != null &&
                  benefit_program.extra.mapfre_dollar.earned > 0 && (
                     <p className="title-sub-section text-with-span">
                        A la fecha tienes disponible&nbsp;
                        <span>
                           {benefit_program.extra != null &&
                              benefit_program.extra.mapfre_dollar != null &&
                              benefit_program.extra.mapfre_dollar.available}
                           &nbsp; M$
                        </span>{" "}
                        para que lo apliques en la compra o renovación de tu
                        póliza. <br/>
                        Donde: 1 M$ equivale a 1 dólar.
                     </p>
                  )}
               {benefit_program.extra != null &&
                  benefit_program.extra.mapfre_dollar != null &&
                  benefit_program.extra.mapfre_dollar.earned === 0 && (
                     <p className="title-sub-section text-with-span">
                        Aún no generas MAPFRE Dólar (M$) porque debes cumplir
                        una anualidad con nosotros (no válido para seguros de
                        VIDA y/o Decesos).
                     </p>
                  )}

               <p className="title-sub-section text-with-span">
                  Para mejorar tus beneficios Club MAPFRE debes estar al día en
                  tus pagos y aumentar tu protección¡
               </p>
            </div>
         );
      } else {
         return <div/>;
      }
   }


   renderTextExperience() {
      const {benefit_program} = this.props;
      if (benefit_program != null) {
         let points = benefit_program.points;
         if (benefit_program.extra != null && benefit_program.points != null) {
            points = benefit_program.points;
         }
         if (process.env.REACT_APP_NAME_APP === "mapfre") {
            return (
               <p className="title-sub-section text-with-span">
                  ¡Te damos la bienvenida al <span>Club MAPFRE¡</span> y te
                  contamos que a la fecha cuentas con {points} puntos.
                  <br/>
                  Empieza a vivir la experiencia de pertenecer al{" "}
                  <span>Club MAPFRE</span> y disfruta de tus beneficios como{" "}
                  <span>Cliente MAPFRE {this.state.active_level}</span>.
               </p>
            );
         } else {
            return (
               <h5>
                  Actualmente te encuentras en el nivel{" "}
                  <span style={{fontWeight: 800}}>
                                          {" "}
                     {this.state.active_level}{" "}
                                       </span>
               </h5>
            )
         }
      }
   }

   //FIN MAPFRE

   renderBadges() {
      return this.state.badges.map((badge, index) => (
         <div key={index} className="product-content">
            <Badge badge={badge}/>
         </div>
      ));
   }

   renderProducts() {
      const {benefit_program} = this.props;

      const products = benefit_program.services;

      if (products != null) {

         return products.map((product, index) => (
            <div key={index} className="product-content">
               <Product product={product}/>
            </div>
         ));
      }
   }

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   renderLocalities() {
      const {localities, loadLocalities} = this.props;
      if (localities != null && localities.length > 0) {
         return localities.map((locality, index) => (
            <a
               key={`locality${index}`}
               href={"/"}
               onClick={e => this.changeCity(e, locality.uid)}
               className="dropdown-item dropdown-type select-type"
            >
               {locality.name}
            </a>
         ));
      } else {
         if (loadLocalities) {
            return <p> Cargando...</p>;
         } else {
            return <p> No se encontraron ciudades</p>;
         }
      }
   }


   handleSubmit = async event => {
      event.preventDefault();

      const {
         document_number,
         first_name,
         last_name,
         mobile_number
      } = this.state;

      this.setState({isLoading: true});
      if (!this.state.isLoading) {
         this.updateUser(document_number,
            first_name,
            last_name,
            mobile_number);
      }
   };

   updateUser = async (
      document_number,
      first_name,
      last_name,
      mobile_number
   ) => {
      const data = {
         document_number,
         first_name,
         last_name,
         mobile_number
      };

      let auxNumber = false
      console.log(mobile_number)
      if (mobile_number.length !== 0) {
         if (mobile_number[0] !== '+') {
            if (mobile_number.length !== 9) {
               this.addNotification("El número telefónico no tiene los carácteres requeridos", "error");
               auxNumber = true
               this.setState({isLoading: false});
               return true
            }
         }else{
            if (mobile_number.length < 10) {
               this.addNotification("El número telefónico no tiene los carácteres requeridos para un numero internacional", "error");
               auxNumber = true
               this.setState({isLoading: false});
               return true
            }
         }
      }
      let logInResponse = null
      if (!auxNumber) {
         data.mobile_number = `${data.mobile_number}`
         localStorage.setItem("data", JSON.stringify(data))
         delete data.document_number
         logInResponse = await this.props.dispatch(
            editUser(data)
         );
      }
      let these = this;
      if (
         logInResponse !== undefined &&
         logInResponse != null
      ) {

         let {status, data} = logInResponse.value;
         if (status === 200 || status === 201) {
            these.setState({isLoading: false});
            setTimeout(function(){
               $('.overload').remove();
            }, 500)
         } else {
            if (status === 400) {
               let errorStr = "";
               if (data.email !== undefined && data.email != null)
                  errorStr = data.email.join();
               if (errorStr !== "") {
                  these.setState({isLoading: false});
                  these.addNotification(errorStr, "error");
               } else {
                  these.setState({isLoading: false});
                  these.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
               }
            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            }
         }

      } else {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }

      //
   };


   render() {
      const {
         loadingUser,
         user,
         benefit_program,
         loadingEditCity
      } = this.props;
      let level = null
      let percent = 0
      let pointsExtra = 0
      let next_level = null;
      let color = null
      if (benefit_program && process.env.REACT_APP_NAME_APP === "bancoelcomercio") {
         if (benefit_program.color)
            color = benefit_program.color.replace("#", "")
         if (benefit_program.levels_system || process.env.REACT_APP_NAME_APP === "bancoelcomercio") {


            for (let i = 0; i < benefit_program.levels.length; i++) {
               if (level) {
                  next_level = benefit_program.levels[i]
                  break;
               }
               level = benefit_program.levels[i]
               if (level.max_points && level.max_points > 0) {
                  percent = benefit_program.points * 100 / level.max_points
               }
               if (level.color)
                  color = level.color.replace("#", "")
               if (level.max_points && level.max_points > 0 && level.max_points < benefit_program.points) {
                  level = null
               }
            }
            if (next_level) {
               pointsExtra = next_level.points - benefit_program.points
            } else {
               pointsExtra = 0
               percent = benefit_program.points * 100 / 99999
            }
         }

      }

      if (loadingUser)
         return (
            <div className="row content-loader">
            </div>
         );
      if (user == null) {
         return (
            <div className="content-no-items">
               <p>Ups! , no se pudo encontrar Datos</p>
            </div>
         );
      } else {
         return (
            <div style={{marginLeft: 30}}>
               <NotificationSystem
                  ref={notificationSystem =>
                     (this.notificationSystemRef = notificationSystem)
                  }
               />
               {
                  process.env.REACT_APP_NAME_APP !== "bancoelcomercio" &&
                  <div className="container detail-profile left-container">
                     <div className="row">
                        <div className="col-lg-12 ">
                           <h3 className="title-section bold ">
                              Hola{" "}
                              <span>
                              {" "}
                                 {this.state.first_name_h1} {this.state.last_name_h1}{" "}
                           </span>
                           </h3>
                        </div>
                     </div>
                  </div>


               }
               <div className="container detail-profile left-container">
                  <div className="row">
                     <div className="col-lg-12">
                        <h5 className="title-section bold " style={{color: "#000 !important", fontSize: "1.75rem"}}>
                           Mi Perfil
                        </h5>
                     </div>
                  </div>
               </div>

               {this.state.app_profile_show_levels && this.state.active_level !== "" && (
                  <div className="container detail-profile left-container">
                     <div className="row">
                        <div className="col-lg-12">

                           <div className="row">
                              <div className="col">
                                 <div className="text-dollar">
                                    {this.renderTextExperience()}
                                 </div>
                              </div>
                           </div>

                           <h5>
                              {this.state.percentPoints} { this.state.isFull ? "has alcanzado el último nivel" : "para el siguiente nivel"}
                           </h5>
                           {this.state.app_profile_show_level_rating_bar &&
                              <div className="progress">
                                 <div
                                    className="progress-bar"
                                    role="progressbar"
                                    style={{
                                       width: this.state.percent,
                                       backgroundColor: this.state.colors_main_color
                                    }}
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                 />
                              </div>}
                           {
                              this.state.app_profile_show_points &&
                              <div style={{marginTop: 15}}>
                                 <Link
                                    to={"profile/points"}
                                    className="btn btn-sm btn-outline-secondary"
                                 >
                                    {" "}
                                    Ver historial de puntos
                                 </Link>
                              </div>
                           }

                        </div>
                     </div>
                  </div>
               )}
               {this.state.app_profile_show_level_rating_bar &&
                  this.state.active_level === "" && (
                     <div className="container detail-profile left-container">
                        <div className="row">
                           <div className="col-lg-12">
                              <div className="row">
                                 <div className="col">
                                    <div className="text-dollar">
                                       <p>
                                          Actualmente aún no tienes un nivel,
                                          realiza consumos y redime puntos para
                                          poder subir de nivel.
                                       </p>
                                    </div>
                                 </div>
                              </div>
                              <h4>{this.state.percentPoints}</h4>
                              <div className="progress">
                                 <div
                                    className="progress-bar"
                                    role="progressbar"
                                    aria-valuenow="0"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                 />
                              </div>
                           </div>
                        </div>
                     </div>
                  )}


               {process.env.REACT_APP_NAME_APP === "mapfre" &&
                  <div className="col-lg-12">
                     <div className="row">
                        <div className="col">
                           {this.renderTextMapfreDollar()}
                        </div>
                     </div>
                  </div>
               }
               {process.env.REACT_APP_NAME_APP !== "bancoelcomercio" && this.state.app_profile_show_products && benefit_program.services != null && benefit_program.services.length > 0 &&
                  <div className="line"/>}
               {process.env.REACT_APP_NAME_APP !== "bancoelcomercio" && this.state.app_profile_show_products && benefit_program.services != null && benefit_program.services.length > 0 && (
                  <div className="main-body d-flex flex-wrap  no-row-margin">
                     <div className="row">
                        <div className="col-lg-12">
                           <p className="title-sub-section"> {TextDefaul.textProducts}</p>
                           <div className="row">{this.renderProducts()}</div>
                        </div>
                     </div>
                  </div>
               )}
               {process.env.REACT_APP_NAME_APP !== "bancoelcomercio" && this.state.app_profile_show_badges && this.state.badges.length > 0 &&
                  <div className="line"/>}
               {process.env.REACT_APP_NAME_APP !== "bancoelcomercio" && this.state.app_profile_show_badges && this.state.badges.length > 0 && (
                  <div className="main-body d-flex flex-wrap  no-row-margin">
                     <div className="row">
                        <div className="col-lg-12">
                           <p className="title-sub-section">Tus insignias</p>
                           <div className="row">{this.renderBadges()}</div>
                        </div>
                     </div>
                  </div>
               )}
               {
                  process.env.REACT_APP_NAME_APP !== "bancoelcomercio" &&
                  <Fragment>
                     <div className="line"/>
                     <div className="container detail-profile left-container">
                        <div className="row">
                           <div className="col-lg-12">
                              <h4 className="title-section bold ">
                                 Información Personal
                              </h4>
                           </div>
                           <div className="col-lg-6">
                              <div className="form-group">
                                 <label htmlFor="inputFirstName">Nombres</label>
                                 <input
                                    value={this.state.first_name}
                                    onChange={event => this.setState({first_name: event.target.value})}
                                    type="text"
                                    className="form-control"
                                    id="inputFirstName"
                                    placeholder=""
                                 />
                              </div>
                              <div className="form-group">
                                 <label htmlFor="inputLastName">Apellidos</label>
                                 <input
                                    value={this.state.last_name}
                                    onChange={event => this.setState({last_name: event.target.value})}
                                    type="text"
                                    className="form-control"
                                    id="inputLastName"
                                    placeholder=""
                                 />
                              </div>
                              <div className="form-group">
                                 <label htmlFor="inputDoc">DNI</label>
                                 <input
                                    value={this.state.document_number}
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    id="inputDoc"
                                    placeholder=""
                                 />
                              </div>
                           </div>
                           <div className="col-lg-6">

                              <div className="form-group">
                                 <label htmlFor="inputEmial">
                                    Correo Electrónico
                                 </label>
                                 <input
                                    value={this.state.email}
                                    type="text"
                                    readOnly={true}
                                    className="form-control"
                                    id="inputEmail"
                                    placeholder=""
                                 />
                              </div>
                              <div className="form-group">
                                 <label htmlFor="inputMobileNumber">Teléfono de contacto</label>
                                 <input
                                    value={this.state.mobile_number}
                                    onChange={event => this.setState({mobile_number: event.target.value})}
                                    type="text"
                                    className="form-control"
                                    id="inputMobileNumber"
                                    placeholder=""
                                 />
                              </div>
                           </div>
                        </div>
                        <div style={{marginBottom: 10, marginTop: 20}}>
                           <button
                              type="submit"
                              className="btn btn-whitelabel btn-lg btn-block"
                              onClick={this.handleSubmit}
                           >
                              {this.state.isLoading && "Cargando..."}
                              {!this.state.isLoading && "Actualizar"}
                           </button>
                        </div>
                     </div>
                     <div className="line"/>
                     <div className="container detail-profile left-container">
                        <div className="row">
                           <div className="col-lg-12">
                              <h4 className="title-section bold ">Ubicación</h4>
                           </div>
                           <div className="col-lg-12">
                              <div className="search-types form-group col-lg-6 dropdown">
                                 <div className="form-group">
                                    <label id="label-city" htmlFor="city">
                                       Actualmente te encuentras en
                                    </label>
                                    <div
                                       className="form-control"
                                       id="city"
                                       data-toggle="dropdown"
                                       aria-haspopup="true"
                                       aria-expanded="false"
                                    >
                                       {loadingEditCity && <span>Cargando...</span>}
                                       {loadingEditCity === false && (
                                          <span>{user.area.name}</span>
                                       )}
                                    </div>
                                    <div
                                       className="dropdown-menu"
                                       style={{
                                          width: "100%",
                                          overflowY: "scroll",
                                          maxHeight: 200
                                       }}
                                       aria-labelledby="city"
                                    >
                                       {this.renderLocalities()}
                                    </div>
                                    <span>
                                 Puedes cambiar tu ubicación y poder encontrar
                                 promociones más cerca de ti.
                              </span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </Fragment>
               }
               {
                  process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&

                  <div className={"program-banner"}>
                     <div className={"banner row"}>
                        {
                           level ?
                              <div className={"card col-11 col-lg-10 col-md-10"}>
                                 <div className={"row"}>
                                    <div className={"mr-auto"}>
                                       <img src={level.image} alt={level.title} style={{width: 60, height: 60}}/>
                                    </div>

                                    <div className={"col level-info"}>
                                       <p className={"mb-0"}>{level.title}: {next_level &&
                                          <span>{benefit_program.points}/{level.max_points} pts</span>} </p>
                                       <p className={"mb-0"}>Siguiente nivel: {next_level &&
                                          <span>{next_level.title}</span>} </p>
                                       {
                                          pointsExtra > 0 ? <p>Te falta <span>{pointsExtra}{" "}pts </span>
                                                para llegar al siguiente nivel</p> :
                                             <p>Sigue acumulando puntos para obtener mejores beneficios</p>
                                       }
                                    </div>
                                 </div>
                                 <div className={"row mt-2"}>
                                    <div className={"bar col-7 mt-0"}>
                                       <div style={color ? {
                                          width: `${percent}%`,
                                          background: `#${color}`
                                       } : {width: `${percent}%`}}/>
                                    </div>
                                    <div className={"col-3 d-flex align-items-center"}>
                                       {
                                          level ?
                                             <p className={"mb-0"}> {benefit_program.points}/{level ? level.max_points : 99999}</p>
                                             : <p className={"mb-0"}>Sin Nivel</p>
                                       }
                                    </div>
                                 </div>
                              </div>
                              :
                              <div className={" col-lg-6 col-md-8"} style={{height: "200px"}}>
                              </div>
                        }
                     </div>
                  </div>
               }
               {
                  process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                  <Fragment>
                     <div className="line"/>
                     <p className={"mt-2"} style={{fontSize: "1.3em"}}>Mira los beneficios que puede obtener en los
                        siguientes niveles</p>
                     <div className={"d-flex"}>
                        {
                           benefit_program.levels.map(level => {
                              return <div className={"mr-4 mt-2"}>
                                 <img src={level.image} alt={level.title} style={{width: 120, height: 120}}/>
                                 <p className={"mt-2 mb-1"}
                                    style={{textAlign: "center", fontWeight: 700}}>{level.title}</p>
                                 <p style={{textAlign: "center"}}>{level.points} a {level.max_points ? level.max_points : 99999}</p>
                              </div>
                           })
                        }
                     </div>
                  </Fragment>
               }

            </div>
         );
      }
   }
}

DetailProfile.defaultProps = {
   user: null,
   loadingUser: false,
   loadingEditCity: false,
   benefit_program: null,
   localities: [],
   loadLocalities: false
};

export default connect(({users, benefitPrograms, countries}) => ({
   benefit_program: benefitPrograms.benefitProgram,
   user: users.user,
   loadingEditCity: users.isFetchingCity,
   loadingUser: users.isFetching,
   loadLocalities: countries.isFetching,
   localities: countries.data
}))(DetailProfile);
