import React, {Component} from "react";
import "./style.scss"

var images = require.context("../../../common/images", true);

class SecondaryPurposesBancoElComercio extends Component {
   render() {
      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      return (
         <div className="container tyc">
            <div className="row">
               <div className="col-lg-12 mt-5 mb-5 d-flex flex-column">

                  <img
                     alt="logo"
                     className="logo"
                     src={logo}
                  />
                  <h4>Autorización para finalidades adicionales</h4>
                  <p>Autorizo en forma libre, previa, expresa, informada e inequívoca al Banco de Comercio S.A., a
                     tratar mis datos personales proporcionados en la presente operación y/o formulario para las
                     siguientes finalidades adicionales:</p>



                  <ol type={"a"}>
                     <li>Ofrecerle productos y/o servicios del Banco, y/o de terceros, vinculados al Banco o no
                        (por ejemplo, cuentas, préstamos, entre otros) lo que podremos hacer también a través
                        de diversos medios de comunicación y/o de terceras personas;</li>
                     <li>Enviarle ofertas comerciales y/o promociones (personalizadas o generales), obsequios,
                        publicidad e información de portafolio de los productos y/o servicios del Banco y/o de
                        terceros vinculados o no, (por ejemplo, con empresas con las que tengamos asociaciones o alianzas comerciales), mediante cualquiera de los canales físicos y/o
                        virtuales del Banco;</li>
                     <li>Evaluar su comportamiento en el sistema bancario y su capacidad de pago para evaluar
                        si califica para algún otro producto o servicio.</li>
                     <li>Enviarle invitaciones y saludos personales.</li>
                     <li>Desarrollar acciones comerciales y realizar estudios de mercado</li>
                     <li>Elaborar perfiles de compra y evaluaciones financieras.</li>
                  </ol>
                  <p>El otorgamiento del consentimiento a las finalidades adicionales es opcional, por tanto, usted
                     puede otorgar su consentimiento o puede negarse a hacerlo. Cuando usted nos brinde su
                     consentimiento, sus datos podrán ser tratados solo para las finalidades señaladas. Del mismo
                     modo, su negativa al consentimiento no afectará la prestación del producto o servicio que el
                     Banco le brinde y sus datos podrán ser usados para cumplir con la preparación, celebración y
                     ejecución de la relación contractual y/o solicitar el cumplimiento de obligaciones adquiridas
                     mediante los contratos, anexos y demás documentación del producto o servicio que haya
                     contratado con nosotros.
                  </p>
                  <p>Asimismo, usted puede ejercer los derechos que le otorga la normativa según lo expuesto en la
                     Política de Tratamiento de Datos Personales.
                  </p>

               </div>
            </div>
         </div>
      )
   }
}


export default SecondaryPurposesBancoElComercio;
