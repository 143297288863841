import React, {Component} from 'react';
import {connect} from 'react-redux';
import SecondaryPurposesLaPositiva from "../../common/components/Custom/SecondaryPurposesLaPositiva";
import SecondaryPurposesBancoElComercio from "../../common/components/Custom/SecondaryPurposesBancoElComercio";

const $ = require('jquery');

class SecondaryPurposes extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
   componentDidMount() {
      $('html').removeClass("notOverflow");
   }
    render() {
        switch (process.env.REACT_APP_NAME_APP) {
           case "lapositiva":
              return <SecondaryPurposesLaPositiva/>;
           case "bancoelcomercio":
              return <SecondaryPurposesBancoElComercio/>;
            default:
                return <p></p>
        }

    }

}

export default connect()(SecondaryPurposes);
