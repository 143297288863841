import React from "react";
import moment from "moment";

const HistoryPoint = props => {

   let point = props.point;
   let hour = "";
   if (point.awarded_at != null) {
      let date = new Date(point.awarded_at);
      hour = moment(date).format("DD [de] MMM, h:mm a");
   }
   let title = point.place!==undefined && point.place != null ? `${point.place.name}` : "Local afiliado";

   return (
      <div className="point col-lg-8">
         <div className="col line"/>
         <div className="row">
            <div className="col">
               <h5 className="local">{title}</h5>
               <p className="description-point" >{point.description} <span> {hour !== "" && `- ${hour}`}</span></p>
            </div>
            <div className="col-md-auto">
               <p className="points" >{point.points} Pts.</p>
            </div>
         </div>

      </div>
   );
};

export default HistoryPoint;
