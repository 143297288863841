import React, {Component} from 'react';
import {connect} from 'react-redux';

class PoliticsCookies extends Component {

   componentDidMount() {
      this.props.setOverlay(false)
   }

   render() {
       return(
          <div className="container tyc">
             <div className="row">
                <div className="col-lg-12 mt-5 mb-5">
                   <h5>Política de cookies</h5>
                   <p>Utilizamos una tecnología denominada “cookies”, con la finalidad de poder prestarte el servicio solicitado y permitir la comunicación entre tu Dispositivo y la red, entre otros, así como para proporcionarte una mejor experiencia en el uso de la misma y obtener más información acerca de tus preferencias para personalizar la navegación. En caso de que quieras recabar más información sobre las cookies que utilizamos puedes enviar un correo electrónico a la siguiente dirección: <a href="mailto:devops@bederr.com"> devops@bederr.com </a></p>

                   <p>Más información acerca de las cookies: <a href="http://www.agpd.es/portalwebAGPD/canaldocumentacion/publicaciones/common/Guias/Guia_Cookies.pdf" target={"_blank"} rel="noopener noreferrer">Guía de AEPD acerca del uso de cookies</a></p>

                   <p>Nosotros utilizamos cookies:</p>

                   <p>De análisis estadístico para cuantificar el número de visitantes y analizar estadísticamente la utilización que hacen los Usuarios . Estas cookies son de terceros, en concreto son proporcionadas por Google Analytics. Para más información, vaya al sitio web de Google Analytics.</p>
                  <ol>
                     <li>
                        <p>De carácter técnico y de personalización: que nos permiten controlar aspectos relacionados con la sesión del Usuario, de tal forma que podemos mejorar la experiencia de navegación dentro de la Web.</p>
                     </li>
                     <li>
                        <p>Puedes permitir, bloquear o eliminar las cookies instaladas en tu Dispositivo mediante la configuración de las opciones de tu navegador de Internet.</p>
                     </li>
                  </ol>
                </div>
             </div>
          </div>
       )
    }

}

export default connect()(PoliticsCookies);
