import firebase from 'firebase';

let firebaseConf = null;

if(process.env.REACT_APP_FIREBASE !== undefined && process.env.REACT_APP_FIREBASE === "1" ){
   let config = {};
   if(process.env.REACT_APP_F_API_KEY !== undefined){
      config['apiKey'] = process.env.REACT_APP_F_API_KEY
   }
   if(process.env.REACT_APP_F_AUTH_DOMAIN !== undefined){
      config['authDomain'] = process.env.REACT_APP_F_AUTH_DOMAIN
   }
   if(process.env.REACT_APP_F_DATABASE_URL !== undefined){
      config['databaseURL'] = process.env.REACT_APP_F_DATABASE_URL
   }
   if(process.env.REACT_APP_F_PROJECT_ID !== undefined){
      config['projectId'] = process.env.REACT_APP_F_PROJECT_ID
   }
   if(process.env.REACT_APP_F_STORAGE_BUCKET !== undefined){
      config['storageBucket'] = process.env.REACT_APP_F_STORAGE_BUCKET
   }
   if(process.env.REACT_APP_F_MESSANGING_SENDER_ID !== undefined){
      config['messagingSenderId'] = process.env.REACT_APP_F_MESSANGING_SENDER_ID
   }
   if(process.env.REACT_APP_F_APP_ID !== undefined){
      config['appId'] = process.env.REACT_APP_F_APP_ID
   }
   if(process.env.REACT_APP_F_MEASUREMENT_ID !== undefined){
      config['measurementId'] = process.env.REACT_APP_F_MEASUREMENT_ID
   }


   firebaseConf = firebase.initializeApp(config);


}


export default firebaseConf;
