import React, {Component} from "react";
import {connect} from "react-redux";
import FAQsGeneral from "../../common/components/Custom/FAQsGeneral";
import FAQsPetroperu from "../../common/components/Custom/FAQsPetroperu";
import FAQsMiBanco from "../../common/components/Custom/FAQsMiBanco";

class FAQS extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    switch (process.env.REACT_APP_NAME_APP) {
      case "mibanco":
        return <FAQsMiBanco />;
      case "petroperu":
        return <FAQsPetroperu />;
      default:
        return <FAQsGeneral />;
    }
  }
}

export default connect()(FAQS);
