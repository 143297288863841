import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
   APP_LOGIN_DIRECT,
   APP_LOGIN_PASSWORD,
   APP_TYPE_HHRR,
   APP_TYPE_WHITELABEL,
   buildConfig,
   LANDING_IMAGE,
   TextDefaul
} from "../../common/utils/generic";
import LoginWhite from "./loginWhite";
import LoginWhiteStep2 from "./loginWhiteStep2";
import LoginWhiteStep3 from "./loginWhiteStep3";
import WelcomePage from "./welcomePage";

import Login from "./login";
import LoginWhitePassword from "./loginWhitePassword";
import Soon from "./soon";
import Page from "../../common/components/Page";
import firebase from "firebase";
import ConfirmationCode from "./ConfirmationCode";
import ChangeMobile from "./ChangeMobile";
import {Link} from "react-router-dom";

var images = require.context("../../common/images", true);

class LayoutLogin extends Component {

   constructor(props) {
      super(props);
      this.state = {
         step: 1,
         showWelcome: false,
         verifyMobile: false,
         changeMobile:false,
         first_name: "",
         document_number: "",
         mobile_number_last_digits: "",
         sendFirstSMS: false
      };
   }

   render() {
      let background = images(
         `./background_${process.env.REACT_APP_NAME_APP}.jpg`
      );
      let backgroundImage = {
         backgroundImage: "url(" + background + ")"
      };

      let remoteConfig = firebase.remoteConfig()

      let soon = buildConfig.app_soon;
      let app_type = process.env.REACT_APP_TYPE;
      let login_type = buildConfig.app_login_type;
      let app_landing = buildConfig.app_landing;
      if(remoteConfig != null){

         soon = remoteConfig.getBoolean('app_soon');
         login_type = remoteConfig.getString('app_login_type');
         app_landing = remoteConfig.getString('app_landing');

      }

      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);

      return (
         <Page
            id="login"
            title={TextDefaul.webTitle}
            description={TextDefaul.webDescription}
         >
            {
               this.state.showWelcome ?
                  <WelcomePage history={this.props.history} first_name={this.state.first_name}/>
                  :
                  <div className="welcome">
                     {
                        this.state.changeMobile ?
                           <div className="login" style={{alignItems: "baseline"}}>
                              {
                                 process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                                 <Link to={"/"}>
                                    <img
                                       alt="logo"
                                       className="logo"
                                       src={logo}
                                    />
                                 </Link>
                              }
                              <ChangeMobile {...this.props} setMobileNumber={number=>this.setState({mobile_number_last_digits: number})}
                                            setVerifyMobile={c=>this.setState({verifyMobile: c})}
                                            setChangeMobile={c=>this.setState({changeMobile: c})}/>
                           </div>
                           :
                           this.state.verifyMobile ?
                              <div className="login" style={{alignItems: "baseline"}}>
                                 {
                                    process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                                    <Link to={"/"}>
                                       <img
                                          alt="logo"
                                          className="logo"
                                          src={logo}
                                       />
                                    </Link>
                                 }
                                 <ConfirmationCode {...this.props}  sendFirstSMS={this.state.sendFirstSMS}
                                                   setSendFirstSMS={sendFirstSMS=>this.setState({sendFirstSMS: sendFirstSMS})}
                                                   mobile_number_last_digits={this.state.mobile_number_last_digits} setWelcome={welcome=>this.setState({showWelcome: welcome})} setVerifyMobile={c=>this.setState({verifyMobile: c})} setChangeMobile={c=>this.setState({changeMobile: c})} />
                              </div>
                              :
                              <div
                                 className={soon === true ? "login soon" : "login "}
                                 style={
                                    soon === true
                                       ? { background: "#00808a", overflow: "hidden" }
                                       : {}
                                 }
                              >
                                 {
                                    process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                                    <Link to={"/"}>
                                       <img
                                          alt="logo"
                                          className="logo"
                                          src={logo}
                                       />
                                    </Link>
                                 }
                                 {  app_type === APP_TYPE_HHRR &&
                                 soon !== true && <Login />
                                 }

                                 {  app_type === APP_TYPE_WHITELABEL &&
                                 login_type === APP_LOGIN_DIRECT && (this.state.step===1) &&
                                 soon !== true && <LoginWhite history = {this.props.history} setStep={step=>this.setState({step: step})}
                                                              setWelcome={welcome=>this.setState({showWelcome: welcome})} setDocumentNumber={d=>this.setState({document_number: d})}/>
                                 }
                                 {
                                    (app_type === APP_TYPE_WHITELABEL && login_type === APP_LOGIN_DIRECT && soon !== true &&
                                       process.env.REACT_APP_NAME_APP === "bancoelcomercio" && (this.state.step===2)) &&
                                    <LoginWhiteStep2 history = {this.props.history} setFirstName={first_name=>this.setState({first_name: first_name})} setVerifyMobile={c=>this.setState({verifyMobile: c})}
                                                     setSendFirstSMS={sendFirstSMS=>this.setState({sendFirstSMS: sendFirstSMS})}
                                                     setMobileNumber={number=>this.setState({mobile_number_last_digits: number})}
                                                     setWelcome={welcome=>this.setState({showWelcome: welcome})} setStep={step=>this.setState({step: step})} document_number={this.state.document_number}/>
                                 }
                                 {
                                    (app_type === APP_TYPE_WHITELABEL && login_type === APP_LOGIN_DIRECT && soon !== true &&
                                       process.env.REACT_APP_NAME_APP === "bancoelcomercio" && (this.state.step===3)) &&
                                    <LoginWhiteStep3 history = {this.props.history} setStep={step=>this.setState({step: step})}
                                                     setWelcome={welcome=>this.setState({showWelcome: welcome})} document_number={this.state.document_number}/>
                                 }
                                 {  app_type === APP_TYPE_WHITELABEL &&
                                 login_type === APP_LOGIN_PASSWORD &&
                                 soon !== true &&<LoginWhitePassword />
                                 }

                                 {soon === true && <Soon />}
                              </div>
                     }
                     {
                        app_landing === LANDING_IMAGE &&
                        <div className="publicity" style={backgroundImage} />
                     }

                  </div>
            }
         </Page>
      );
   }

};

LayoutLogin.propTypes = {
   location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
   }).isRequired,
   dispatch: PropTypes.func.isRequired
};

export default connect()(LayoutLogin);
