import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import Validator from "../../common/utils/validator";
import PropTypes from "prop-types";
import {newPassword} from "./actions";
import firebase from "firebase";
import {LANDING_IMAGE, TextDefaul} from "../../common/utils/generic";
import Page from "../../common/components/Page";
import {Link} from "react-router-dom";

var images = require.context("../../common/images", true);

class NewPassword extends Component {
   constructor(props) {
      super(props);
      this.state = {
         sending: false,
         password: "",
         password2: "",
         uid: "",
         token: "",
         isLoading: false,
         accepted_terms_cond: true,
         securePassword: false,

         visibilityFirst: false,
         visibilitySecond: false,
      };
   }


   componentDidMount() {
      const {match} = this.props;
      const uidUser = match.params.uidUser;
      const token = match.params.token;
      let name_hhrr = process.env.REACT_APP_NAME_APP;
      let api_key = process.env.REACT_APP_APY_KEY;
      window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
      if (uidUser !== undefined && token !== undefined) {
         this.setState({uid: uidUser});
         this.setState({token: token});
      } else {
         this.context.router.history.push(`/`);
      }
      this.validator = new Validator([
         {
            id: "password",
            ref: this.passwordInput,
            rules: ["required"]
         },
         {
            id: "password2",
            ref: this.passwordInput2,
            rules: ["required"]
         }
      ]);
   }


   handlePassword = event =>{
      this.setFormValues({password: event.target.value});
      let mayus =  new RegExp("^(?=.*[A-Z])");
      let numbers =  new RegExp("^(?=.*[0-9])");
      let lower =  new RegExp("^(?=.*[a-z])");
      let len =  new RegExp("^(?=.{8,})");
      if(mayus.test(event.target.value) && numbers.test(event.target.value)
         && lower.test(event.target.value) && len.test(event.target.value)){
         this.setState({securePassword: true})
      }else{
         this.setState({securePassword: false})
      }
   }

   handlePassword2 = event =>
      this.setFormValues({password2: event.target.value});

   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   handleSubmit = async event => {
      event.preventDefault();


      const {password, password2} = this.state;
      const isValid = this.validator.validate({password, password2});
      const password_confirmation = password2;

      if(password!==password_confirmation){
         this.addNotification("Verifique que las claves sean iguales, intentelo nuevamente", "error");
         return;
      }

      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }

      if(this.state.securePassword){
         this.setState({isLoading: true});
      }else{
         this.addNotification(
            "Ups! La contraseña no cumple con la seguridad requerida, intentelo nuevamente",
            "error"
         );
      }

      //this.setState({isLoading: true});
      if (!this.state.isLoading) {
         if(process.env.REACT_APP_NAME_APP!=="bancoelcomercio"){
            try {
               const responsePassword = await this.props.dispatch(
                  newPassword(
                     {password, password_confirmation},
                     this.state.uid,
                     this.state.token
                  )
               );
               let these = this;
               if (
                  responsePassword !== undefined &&
                  responsePassword != null
               ) {

                  let {status} = responsePassword.value;
                  if (status === 200) {
                     this.addNotification(
                        "Se ha reseteado la contraseña correctamente",
                        "success"
                     );
                     this.setState({isLoading: false});
                     setTimeout(
                        function () {
                           these.context.router.history.push(`/`);
                        },
                        2000
                     );
                  } else {
                     this.setState({isLoading: false});
                     this.addNotification(
                        "Error al enviar información, es posible que el link haya caducado",
                        "error"
                     );
                  }

               } else {
                  this.setState({isLoading: false});
                  this.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
               }
            } catch (error) {
               this.setState({isLoading: false});
               this.addNotification(
                  "Existió un error al RECOPILAR LOS DATOS",
                  "error"
               );
            }
         }else {
            if (this.state.securePassword) {
               try {
                  const responsePassword = await this.props.dispatch(
                     newPassword(
                        {password, password_confirmation},
                        this.state.uid,
                        this.state.token
                     )
                  );
                  let these = this;
                  if (
                     responsePassword !== undefined &&
                     responsePassword != null
                  ) {

                     let {status} = responsePassword.value;
                     if (status === 200) {
                        this.addNotification(
                           "Se ha reseteado la contraseña correctamente",
                           "success"
                        );
                        this.setState({isLoading: false});
                        setTimeout(
                           function () {
                              these.context.router.history.push(`/`);
                           },
                           2000
                        );
                     } else {
                        this.setState({isLoading: false});
                        this.addNotification(
                           "Error al enviar información, es posible que el link haya caducado",
                           "error"
                        );
                     }

                  } else {
                     this.setState({isLoading: false});
                     this.addNotification(
                        "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                        "error"
                     );
                  }
               } catch (error) {
                  this.setState({isLoading: false});
                  this.addNotification(
                     "Existió un error al RECOPILAR LOS DATOS",
                     "error"
                  );
               }
            }
         }
      }
   };

   render() {
      let visibilty = images(
         `./visibility.png`
      );
      let noVisibilty = images(
         `./no-visibility.png`
      );

      let remoteConfig = firebase.remoteConfig()
      let app_landing = remoteConfig.getString('app_landing');

      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      let background = images(
         `./background_${process.env.REACT_APP_NAME_APP}.jpg`
      );
      let backgroundImage = {
         backgroundImage: "url(" + background + ")"
      };
      return (
         <Page
            id="login"
            title={TextDefaul.webTitle}
            description={TextDefaul.webDescription}
         >
            <div className="welcome reset-password">
               <div
                  className="login"
                  style={{
                     alignItems: "baseline"
                  }}
               >
                  {
                     process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                     <Link to={"/login"}>
                        <img
                           alt="logo"
                           className="logo"
                           src={logo}
                        />
                     </Link>
                  }
                  <div className={process.env.REACT_APP_NAME_APP === "bancoelcomercio" ? "col login-form mt-0" : "col login-form" }>
                  <NotificationSystem
                     ref={notificationSystem =>
                        (this.notificationSystemRef = notificationSystem)
                     }
                  />
                  <div
                     className="grid-container"
                     style={{
                        background: process.env.REACT_APP_NAME_APP === "rewards" ? "#00808a" : ""
                     }}
                  >
                     <header
                        className="hello"
                        style={{marginBottom: 25, marginTop: 25}}
                     >
                        {
                           process.env.REACT_APP_NAME_APP !== "bancoelcomercio" &&
                           <Fragment>
                              {
                                 process.env.REACT_APP_NAME_APP !== "petroperu" ?
                                    <h2 className="">
                                       App de <br/>
                                       beneficios para
                                       <br/>
                                       colaboradores de
                                    </h2> :
                                    <h2 className="">
                                       App petroperu
                                    </h2>
                              }
                              <Link to={"/login"}>
                                 <img
                                    alt="logo"
                                    className="logo"
                                    src={logo}
                                 />
                              </Link>
                           </Fragment>
                        }
                        {
                           process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                           <p className={"loginExtraText"}>Ingresar nueva contraseña</p>
                        }
                     </header>
                     <form onSubmit={this.handleSubmit}>

                        <label>Ingresar nueva contraseña</label>
                        {
                           process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                           <div className={"legend"}>
                              <p>-Ingresa tu clave (mínimo 8 caracteres)</p>
                              <p>-Al menos una letra mayúscula</p>
                              <p>-Al menos una letra minúscula</p>
                              <p>-Al menos un número</p>
                           </div>
                        }
                        <div style={{position: "relative"}}>
                           <div
                              className="form-group"
                              ref={input => {
                                 if (input) {
                                    this.passwordInput = input.querySelector("input");
                                 }
                              }}
                           >
                              <input
                                 className={"form-control"}
                                 value={this.state.password}
                                 onChange={this.handlePassword}
                                 type={this.state.visibilityFirst ? "text" : "password"}
                                 placeholder="Contraseña"
                                 required=""
                                 floating-validation=""
                              />
                           </div>
                           {
                              process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                              <img
                                 alt="visibilty"
                                 className="visibilty"
                                 style={{cursor:"pointer"}}
                                 src={!this.state.visibilityFirst ? noVisibilty : visibilty }
                                 onClick={e=>this.setState({visibilityFirst: !this.state.visibilityFirst})}
                              />
                           }
                        </div>
                        <label className={"mt-4"}>Repetir contraseña</label>
                        <div style={{position: "relative"}}>
                           <div
                              className="form-group"
                              ref={input => {
                                 if (input) {
                                    this.passwordInput2 = input.querySelector(
                                       "input"
                                    );
                                 }
                              }}
                           >
                              <input
                                 value={this.state.password2}
                                 onChange={this.handlePassword2}
                                 className="form-control"
                                 type={this.state.visibilitySecond ? "text" : "password"}
                                 placeholder="Repetir Contraseña"
                                 required=""
                                 floating-validation=""
                              />
                           </div>
                           {
                              process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                              <img
                                 alt="visibilty"
                                 className="visibilty"
                                 style={{cursor:"pointer"}}
                                 src={!this.state.visibilitySecond ? noVisibilty : visibilty }
                                 onClick={e=>this.setState({visibilitySecond: !this.state.visibilitySecond})}
                              />
                           }
                        </div>
                        <div style={{marginBottom: 40, marginTop: 40}}>
                           <button
                              type="submit"
                              className="btn btn-whitelabel btn-lg btn-block"
                              onClick={this.handleSubmit}
                           >
                              Cambiar contraseña
                           </button>
                        </div>
                     </form>
                  </div>
               </div>
               </div>
               {
                  app_landing === LANDING_IMAGE &&
                  <div className="publicity" style={backgroundImage}/>
               }
            </div>
         </Page>
      );
   }

   static contextTypes = {
      router: PropTypes.object
   };
}

export default connect()(NewPassword);
