import React from "react";

//47112741
const FAQsGeneral = props => (
  <div className="container tyc">
    <div className="row">
      <div className="col-lg-12">
        <h2>Preguntas frecuentes</h2>
        <h4>¿Cuántas veces y qué vigencia tienen las promociones?</h4>
        <p>
          Depende de cada promoción. Revisa siempre los “Términos y condiciones”
          de cada promoción.
        </p>

        <h4>¿Cómo hacer uso de las promociones?</h4>
        <p>
          Primero escoge tu promoción y lee sus Términos y condiciones. Luego,
          acércate al establecimiento, indica que vas por la app de Beneficios{" "}
          {process.env.REACT_APP_NAME_BUSINESS} y muestra tu documento de identidad. El
          establecimiento revisará tus datos y si estás correctamente
          registrado, te brindarán la promoción.
        </p>

        <h4>¿Tienes otra consulta?</h4>
        <p>Por favor, escríbenos a: team@bederr.com</p>
      </div>
    </div>
  </div>
);

export default FAQsGeneral;
