import React from "react";

const Banner = props => {
   let url = "/";
   let image =
      props.banner.web_image != null && props.banner.web_image !== ""
         ? props.banner.web_image
         : props.banner.image;
   let figure = (
      <figure>
         <img src={image} alt="imagen" />
      </figure>
   );
   if (props.type === "banner") {
      figure = (
         <figure>
            <img src={image} alt="imagen_popup" />
            <div className="shadow" />
         </figure>
      );
   }
   let content = figure;
   if (
      props.banner.mobile_menu !== undefined &&
      props.banner.mobile_menu != null &&
      props.banner.mobile_menu !== ""
   ) {
      content = <div onClick={props.onClick}>{figure}</div>;
   } else {
      if (
         props.banner.promotion_uid !== undefined &&
         props.banner.promotion_uid != null &&
         props.banner.promotion_uid !== ""
      ) {
         content = <div onClick={props.onClick}>{figure}</div>;
      } else {
         if (
            props.banner.poll_uid !== undefined &&
            props.banner.poll_uid != null &&
            props.banner.poll_uid !== ""
         ) {
            content = <div onClick={props.onClick}>{figure}</div>;
         } else {
            if (
               props.banner.link !== undefined &&
               props.banner.link != null &&
               props.banner.link !== "/"
            ) {
               content = (
                  <a href={url} target="_blank"  rel="noopener noreferrer">
                     {figure}
                  </a>
               );
            }
         }
      }
   }

   return (
      <div
         className={
            props.type === "banner" ? " col banner top-banner" : "col banner"
         }
      >
         {content}
      </div>
   );
};

export default Banner;
