import React, {Component} from "react";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";

import {APP_TYPE_WHITELABEL} from "../../common/utils/generic";
import "react-dates/initialize";

import {sendSMS, verifySMS} from "../Register/actions";


class ConfirmationCode extends Component {
   constructor(props) {
      super(props);
      this.state = {
         number1: "",
         number2: "",
         number3: "",
         number4: "",
         number5: "",
         number6: "",
         currentCount: 60,
         business: "",
         isLoading: false,

         typing: false,
         typingTimeout: 0,
      };
   }

   timer() {
      this.setState({
         currentCount: this.state.currentCount - 1
      })
      if(this.state.currentCount < 0) {
         this.setState({currentCount: 0});
      }
   }

   componentDidMount() {
      this.setState({business: process.env.REACT_APP_NAME_APP});

      this.intervalId = setInterval(this.timer.bind(this), 1000);
      if(this.props.sendFirstSMS){
         this.sendSMS("")
         this.props.setSendFirstSMS(false)
      }
   }

   componentWillUnmount(){
      clearInterval(this.intervalId);
   }

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   search = (event, position) => {
      const self = this;
      if (self.state.typingTimeout) {
         clearTimeout(self.state.typingTimeout);
      }

      const {
         number1,
         number2,
         number3,
         number4,
         number5,
      } = this.state;

      this.setState({isLoading: true});

      let that = this;
      let aux = event.target.value
      if(position===1){
         //let empty = event.target.value.length===0 ? true : false
         self.setState({
            number1:  event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
               /* if(empty){
                  self.setState({number1: 0})
               }*/
               /*
               that.verifyCode({
                  "number1": aux,
                  number2,number3,number4,number5,number6,
               })
                */
            }, 1000)
         });
      }
      if(position===2){
         //let empty = event.target.value.length===0 ? true : false
         self.setState({
            number2: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
               /* if(empty){
                  self.setState({number2: 0})
               }*/
               /*
               that.verifyCode({
                  "number2": aux,
                  number1,number3,number4,number5,number6,
               })
                */
            }, 1000)
         });
      }
      if(position===3){
         //let empty = event.target.value.length===0 ? true : false
         self.setState({
            number3: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
               /* if(empty){
                  self.setState({number3: 0})
               }*/
               /*
               that.verifyCode({
                  "number3": aux,
                  number1,number2,number4,number5,number6,
               })
                */
            }, 1000)
         });
      }
      if(position===4){
         //let empty = event.target.value.length===0 ? true : false
         self.setState({
            number4: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
               /* if(empty){
                  self.setState({number4: 0})
               }*/
               /*
               that.verifyCode({
                  "number4": aux,
                  number1,number2,number3,number5,number6,
               })
                */
            }, 1000)
         });
      }
      if(position===5){
         //let empty = event.target.value.length===0 ? true : false
         self.setState({
            number5: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
               /* if(empty){
                  self.setState({number5: 0})
               }*/
               /*
               that.verifyCode({
                  "number5": aux,
                  number1,number2,number3,number4,number6,
               })
                */
            }, 1000)
         });
      }
      if(position===6){
         self.setState({
            number6: event.target.value,
            typing: false,
            typingTimeout: setTimeout(function () {
               that.verifyCode({
                  "number6": aux,
                  number1,number2,number3,number4,number5,
               })

            }, 1000)
         });
      }
   }


   sendSMS = async a => {
      console.log(a)
      const sendSMSResponse = await this.props.dispatch(
         sendSMS()
      );

      let these = this;
      if (
         sendSMSResponse !== undefined &&
         sendSMSResponse != null
      ) {
         let {status, data} = sendSMSResponse.value;
         if (status === 200 || status === 201) {
            these.setState({isLoading: false});
            const {session} = data;
            let name = process.env.REACT_APP_NAME_APP;

            localStorage.setItem(`session${name}`, session)
         } else {
            if (status === 400) {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            }
         }
      } else {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };

   verifyCode = async data => {

      try {
         let code = data.number1.toString()+data.number2.toString()+data.number3.toString()+data.number4.toString()+data.number5.toString()+data.number6.toString()

         let name = process.env.REACT_APP_NAME_APP;
         const verifySMSResponse = await this.props.dispatch(
            verifySMS({"code": code, "session": localStorage.getItem(`session${name}`)})
         );

         let these = this;
         if (verifySMSResponse != null) {
            let {status, data} = verifySMSResponse.value;
            if (status === 200) {
               console.log("VERIFICADO", data)

               localStorage.removeItem(`session${name}`)
               this.props.setChangeMobile(false)
               this.props.setVerifyMobile(false)
               this.props.setWelcome(true)
            } else {
               these.setState({isLoading: false});
               if (process.env.REACT_APP_TYPE === APP_TYPE_WHITELABEL)
                  these.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
            }
         } else {
            these.setState({isLoading: false});
            these.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };


   handleSubmit = async event => {
      event.preventDefault();
   };

   redirectChangeMobile = e => {
      e.preventDefault()
      this.props.setChangeMobile(true)
      this.props.setVerifyMobile(false)
   }

   reSendSMS = e => {
      e.preventDefault()
      let name = process.env.REACT_APP_NAME_APP;
      localStorage.removeItem(`session${name}`)

      this.sendSMS("")
      this.setState({
         currentCount: 60
      })
   }

   render() {

      return (
         <div className="col login-form register-form confirmation-code-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container mt-0">

               <form onSubmit={this.handleSubmit} className="form-container" style={{textAlign: "center"}}>
                  <header
                     className="hello"
                     style={{marginBottom: 15, marginTop: 25}}
                  >
                     <p className={"loginExtraText"}>Código de verificación </p>
                     <p className="logo-p" style={{marginTop: 30}}>Ingresa tu clave SMS de seis dígitos que hemos enviado a tu celular
                        <strong>{" "}{this.props.mobile_number_last_digits.substr(0, 3)}{" "}{this.props.mobile_number_last_digits.substr(3, 3)}{" "}{this.props.mobile_number_last_digits.substr(6, 3)}</strong></p>
                  </header>
                  <button onClick={this.redirectChangeMobile} className={"correction-number"}>Corregir número</button>
                  <span className={"count-seconds"}>{this.state.currentCount<60 ? `00:${this.state.currentCount<10 ? `0${this.state.currentCount}` : this.state.currentCount}` : `01:00`}</span>
                  <button onClick={this.reSendSMS} className={this.state.currentCount===0 ? "correction-number" : "resend-code"} style={this.state.currentCount===0 ? {pointerEvents: "inherit"} : {pointerEvents: "none"}}>Reenviar código</button>

                  <div className={"code-sms"}>
                     <div
                        className="form-group"
                     >
                        <input
                           value={this.state.number1}
                           className="form-control"
                           onChange={e => this.search(e,1)}
                           type="text"
                           maxLength="1"
                           placeholder=""
                           required=""
                           floating-validation=""
                        />
                     </div>
                     <div
                        className="form-group"
                     >
                        <input
                           value={this.state.number2}
                           className="form-control"
                           onChange={e => this.search(e,2)}
                           type="text"
                           maxLength="1"
                           placeholder=""
                           required=""
                           floating-validation=""
                        />
                     </div>
                     <div
                        className="form-group"
                     >
                        <input
                           value={this.state.number3}
                           className="form-control"
                           onChange={e => this.search(e,3)}
                           type="text"
                           maxLength="1"
                           placeholder=""
                           required=""
                           floating-validation=""
                        />
                     </div>
                     <div
                        className="form-group"
                     >
                        <input
                           value={this.state.number4}
                           className="form-control"
                           onChange={e => this.search(e,4)}
                           type="text"
                           maxLength="1"
                           placeholder=""
                           required=""
                           floating-validation=""
                        />
                     </div>
                     <div
                        className="form-group"
                     >
                        <input
                           value={this.state.number5}
                           className="form-control"
                           onChange={e => this.search(e,5)}
                           type="text"
                           maxLength="1"
                           placeholder=""
                           required=""
                           floating-validation=""
                        />
                     </div>
                     <div
                        className="form-group"
                     >
                        <input
                           value={this.state.number6}
                           className="form-control"
                           onChange={e => this.search(e,6)}
                           type="text"
                           maxLength="1"
                           placeholder=""
                           required=""
                           floating-validation=""
                        />
                     </div>
                  </div>
               </form>
            </div>
         </div>
      );
   }
}

export default connect()(ConfirmationCode);
