import React, {Component} from "react";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import "react-dates/initialize";

import {editUser} from "../Register/actions";
import Validator from "../../common/utils/validator";
import back from "../../common/images/back.png";


class ChangeMobile extends Component {
   constructor(props) {
      super(props);
      this.state = {
         phone: "",
         isLoading: false,
      };
   }

   componentDidMount() {
      this.setState({business: process.env.REACT_APP_NAME_APP});

      this.validator = new Validator([
         {
            id: "phone",
            ref: this.phoneInput,
            rules: ["required", "telephone"]
         }
      ]);
   }

   handlePhone = event =>{
      if(event.target.value.length <= 9){
         this.setState({phone: event.target.value});
      }
   }


   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }


   updatePhone = async (
      phone
   ) => {
      const data = {
         "mobile_number": phone
      };

      const updateResponse = await this.props.dispatch(
         editUser(data)
      );
      let these = this;
      if (
         updateResponse !== undefined &&
         updateResponse != null
      ) {

         let {status, data} = updateResponse.value;
         if (status === 200 || status === 201) {
            these.setState({isLoading: false});
            const {mobile_number} = data;

            if(process.env.REACT_APP_NAME_APP === "bancoelcomercio"){
               this.props.setMobileNumber(mobile_number)
               this.props.setChangeMobile(false)
               this.props.setVerifyMobile(true)
            }else{
               these.setState({isLoading: false});
               this.props.setMobileNumber(mobile_number)
               setTimeout(() => {
                  (document.location.href = '/');
                  return true;
               }, 1000);
            }

         } else {
            if (status === 400) {
               console.log(data, data.mobile_number[0])
               these.setState({isLoading: false});
               if(data && data.mobile_number[0].length!==0){
                  these.addNotification(
                     data.mobile_number[0],
                     "error"
                  );
               }else{
                  these.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
               }
            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            }
         }

      } else {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };


   handleSubmit = async event => {
      event.preventDefault();

      const {
         phone
      } = this.state;
      const isValid = this.validator.validate({
         phone
      });

      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }

      this.setState({isLoading: true});
      if (!this.state.isLoading) {
         this.updatePhone(phone);
      }
   };

   redirectVerifyMobile = e => {
      e.preventDefault()
      this.props.setChangeMobile(false)
      this.props.setVerifyMobile(true)
   }

   render() {

      return (
         <div className="col login-form register-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container mt-0">

               <form onSubmit={this.handleSubmit} className="form-container" style={{textAlign: "center"}}>
                  <header
                     className="hello"
                     style={{marginBottom: 15, marginTop: 25}}
                  >

                     <p className={"back-title"}>
                        <img src={back} alt={"back"} style={{cursor: "pointer", marginTop: -4}} onClick={this.redirectVerifyMobile}></img>
                        </p>

                     <p className={"loginExtraText"}>Corregir N° de celular</p>
                  </header>
                  <div>
                     <div
                        className="form-group mt-4"
                        ref={input => {
                           if (input) {
                              this.phoneInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           value={this.state.phone}
                           className="form-control"
                           onChange={this.handlePhone}
                           type="text"
                           pattern="[a-zA-Z0-9-]"
                           placeholder="Celular"
                           required=""
                           floating-validation=""
                        />
                     </div>


                     <div style={{marginBottom: 10, marginTop: 10}}>
                        <button
                           type="submit"
                           className={ process.env.REACT_APP_NAME_APP === "bancoelcomercio"  ? (this.state.phone !== "" ? "btn btn-whitelabel btn-lg btn-block"  : "btn btn-whitelabel-disabled btn-lg btn-block ") : "btn btn-whitelabel btn-lg btn-block" }
                           onClick={this.handleSubmit}
                        >
                           {this.state.isLoading ? "Cargando..." : "Aceptar" }
                        </button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      );
   }
}

export default connect()(ChangeMobile);
