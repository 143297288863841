import React from "react";
import LoadingIndicator from "../LoadingIndicator";
import placeholder from "../../images/place.jpg";
import Img from "react-image";
import moment from "moment";


const Place = props => {
   const { place } = props;

   let image =
      place.cover !== undefined && place.cover !== null && place.cover !== ""
         ? place.cover
         : placeholder;

   return (
      <div className="item col-xs-12 col-sm-6 col-md-6 col-lg-4">
         <a
            href={`https://www.google.com/maps/search/?api=1&query=${
               place.location.latitude
            },${place.location.longitude}`}
            target={"_blank"}
            rel="noopener noreferrer"
         >
            <figure>
               <Img className="cover" src={image} loader={<LoadingIndicator />} />
               <div className="shadow-offer" />
               <div className="info">
                  <p className="title">{place.name}</p>
                  <p className="subtitle" style={{ whiteSpace: "inherit" }}>
                     {place.address}
                  </p>
               </div>
            </figure>
         </a>
         <div className="schedule-info">
            {place.timetable !== undefined &&
               place.timetable != null && <h6>Nuestros Horarios</h6>}
            {place.timetable !== undefined &&
               place.timetable != null && (
                  <ul className="list-group list-group-flush">
                     {place.timetable.monday_hours_from !== null &&
                        place.timetable.monday_hours_to !== null && (
                           <li className="list-group-item">
                              <span>Lunes:</span> De{" "}
                              {moment(
                                 place.timetable.monday_hours_from,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}{" "}
                              a{" "}
                              {moment(
                                 place.timetable.monday_hours_to,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}
                           </li>
                        )}
                     {place.timetable.tuesday_hours_from !== null &&
                        place.timetable.tuesday_hours_to !== null && (
                           <li className="list-group-item">
                              <span>Martes:</span> De{" "}
                              {moment(
                                 place.timetable.tuesday_hours_from,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}{" "}
                              a{" "}
                              {moment(
                                 place.timetable.tuesday_hours_to,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}
                           </li>
                        )}
                     {place.timetable.wednesday_hours_from !== null &&
                        place.timetable.wednesday_hours_to !== null && (
                           <li className="list-group-item">
                              <span>Miercoles:</span> De{" "}
                              {moment(
                                 place.timetable.wednesday_hours_from,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}{" "}
                              a{" "}
                              {moment(
                                 place.timetable.wednesday_hours_to,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}
                           </li>
                        )}
                     {place.timetable.thursday_hours_from !== null &&
                        place.timetable.thursday_hours_to !== null && (
                           <li className="list-group-item">
                              <span>Jueves:</span> De{" "}
                              {moment(
                                 place.timetable.thursday_hours_from,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}{" "}
                              a{" "}
                              {moment(
                                 place.timetable.thursday_hours_to,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}
                           </li>
                        )}
                     {place.timetable.friday_hours_from !== null &&
                        place.timetable.friday_hours_to !== null && (
                           <li className="list-group-item">
                              <span>Viernes:</span> De{" "}
                              {moment(
                                 place.timetable.friday_hours_from,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}{" "}
                              a{" "}
                              {moment(
                                 place.timetable.friday_hours_to,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}
                           </li>
                        )}
                     {place.timetable.saturday_hours_from !== null &&
                        place.timetable.saturday_hours_to !== null && (
                           <li className="list-group-item">
                              <span>Sabado:</span> De{" "}
                              {moment(
                                 place.timetable.saturday_hours_from,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}{" "}
                              a{" "}
                              {moment(
                                 place.timetable.saturday_hours_to,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}
                           </li>
                        )}
                     {place.timetable.sunday_hours_from !== null &&
                        place.timetable.sunday_hours_to !== null && (
                           <li className="list-group-item">
                              <span>Domingo:</span> De{" "}
                              {moment(
                                 place.timetable.sunday_hours_from,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}{" "}
                              a{" "}
                              {moment(
                                 place.timetable.sunday_hours_to,
                                 "hh:mm:ss"
                              ).format("hh:mm a")}
                           </li>
                        )}
                     {place.timetable.extra !== undefined &&
                        place.timetable.extra !== null &&
                        place.timetable.extra !== "" && (
                           <li className="list-group-item">
                              <i>{place.timetable.extra}</i>
                           </li>
                        )}
                  </ul>
               )}
            {place.telephone1 !== undefined &&
               place.telephone1 != null &&
               place.telephone1 !== "" &&
               (place.telephone2 !== undefined &&
                  place.telephone2 != null &&
                  place.telephone2 !== "") && <h6>Nuestros Teléfonos</h6>}
            {place.telephone1 !== undefined &&
               place.telephone1 != null &&
               place.telephone1 !== "" &&
               (place.telephone2 !== undefined &&
                  place.telephone2 != null &&
                  place.telephone2 !== "") && (
                  <p>
                     {place.telephone1 !== undefined &&
                        place.telephone1 != null &&
                        place.telephone1 !== "" && (
                           <span>{place.telephone1}</span>
                        )}
                     {place.telephone2 !== undefined &&
                        place.telephone2 != null &&
                        place.telephone2 !== "" && (
                           <span>, {place.telephone2}</span>
                        )}
                  </p>
               )}
         </div>
      </div>
   );
};
export default Place;
