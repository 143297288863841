import React from "react";

const Badge = props => {
  const product = props.badge;
  const image = product.image;
  const image_reverse = product.image_reverse;
  const title = product.title;
  if (product.is_active === false) {
    if (image_reverse != null) {
      return (
        <div className={`product row justify-content-center `}>
          <div className="col-lg-12">
            <div className="image">
              <img src={image_reverse} alt="badge" />
            </div>
            <div className="title">{title}</div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`product row justify-content-center `}>
          <div className="col-lg-12">
            <div
              className="image"
              style={{ minWidth: 100, fontSize: 60, fontWeight: 700 }}
            >
              ?
            </div>
            <div className="title">{title}</div>
          </div>
        </div>
      );
    }
  } else
    return (
      <div className={`product row justify-content-center active`}>
        <div className="col-lg-12">
          <div className="image">
            <img src={image} alt="badge" />
          </div>
          <div className="title">{title}</div>
        </div>
      </div>
    );
};

export default Badge;
