import React, {Component} from "react";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import Validator from "../../common/utils/validator";
import {APP_TYPE_WHITELABEL} from "../../common/utils/generic";
import {recoveryPassword} from "./actions";
import {Link} from "react-router-dom";

var images = require.context("../../common/images", true);

class Recovery extends Component {
   constructor(props) {
      super(props);
      this.state = {
         sending: false,
         email: "",
         isLoading: false,
         success: false
      };
   }


   componentDidMount() {
      document.title = process.env.REACT_APP_NAME_APP;
      if (process.env.REACT_APP_TYPE === APP_TYPE_WHITELABEL) {
         let name_hhrr = process.env.REACT_APP_NAME_APP;
         let api_key = process.env.REACT_APP_APY_KEY;
         window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
      }
      this.validator = new Validator([
         {
            id: "email",
            ref: this.emailInput,
            rules: ["required", "email"]
         }
      ]);
   }

   handleEmailPaste = event =>
      this.setFormValues({email: event.target.value});
   handleEmail = event => this.setFormValues({email: event.target.value});

   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   handleSubmit = async event => {
      event.preventDefault();

      const {email} = this.state;
      const isValid = this.validator.validate({email});

      if (!isValid) {
         this.addNotification("Por favor, verifique los campos requeridos", "error");
         return;
      }

      this.setState({isLoading: true});
      if (!this.state.isLoading) {

         const passwordResponse = await this.props.dispatch(
            recoveryPassword({email})
         );
         let these = this;

         if (
            passwordResponse !== undefined &&
            passwordResponse != null
         ) {

            let {status, data} = passwordResponse.value;

            if (status !== undefined) {
               if (status === 200 || status === 201) {
                  these.setState({email: ""});
                  these.setState({success: true});
                  these.addNotification(
                     "Se ha enviado un mail a su correo con las instrucciones para recuperar su contraseña",
                     "success"
                  );
                  these.setState({isLoading: false});
               } else {
                  if (status === 400) {
                     let errorStr = "";
                     if (
                        data.email !== undefined &&
                        data.email != null
                     )
                        errorStr = data.email.join();
                     if (errorStr !== "") {
                        these.setState({isLoading: false});
                        these.addNotification(errorStr, "error");
                     } else {
                        these.setState({isLoading: false});
                        these.addNotification(
                           "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                           "error"
                        );
                     }
                  } else {
                     these.setState({isLoading: false});
                     these.addNotification(
                        "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                        "error"
                     );
                  }
               }
            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error ",
                  "error"
               );
            }


         } else {
            this.setState({isLoading: false});
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }

      }
   };

   render() {

      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      return (
         <div className="col login-form recovery-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container">
               <header
                  className="hello"
                  style={{marginBottom: 25, marginTop: 25}}
               >
                  {
                     process.env.REACT_APP_NAME_APP !== "bancoelcomercio" &&
                     <img alt="logo" className="logo" src={logo}/>
                  }
                  {
                     process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                     <p className={"loginExtraText"}>Ingresar tu correo electrónico</p>
                  }
               </header>

               <form onSubmit={this.handleSubmit}>
                  {
                     process.env.REACT_APP_NAME_APP !== "bancoelcomercio" &&
                     <label>Ingresar tu correo electrónico</label>
                  }
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.emailInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className="form-control"
                           value={this.state.email}
                           onChange={this.handleEmail}
                           type="email"
                           placeholder="Correo Electrónico"
                           required=""
                           floating-validation=""
                        />
                     </div>
                  </div>

                  <div style={{marginBottom: 40}}>
                     <button
                        type="submit"
                        className={ process.env.REACT_APP_NAME_APP === "bancoelcomercio"  ? (this.state.email !== "" ? "btn btn-whitelabel btn-lg btn-block"  : "btn btn-whitelabel-disabled btn-lg btn-block ") : "btn btn-whitelabel btn-lg btn-block" }
                        onClick={this.handleSubmit}
                     >
                        {this.state.isLoading ? "Cargando..." : "Recuperar Contraseña" }
                     </button>
                  </div>
                  <div className="links link-login">
                     <Link to={"/login"}>

                        Retornar a Login
                     </Link>
                  </div>
               </form>
            </div>
         </div>
      );
   }
}

export default connect()(Recovery);
