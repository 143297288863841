import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Register from "./register";
import RegisterRewards from "./registerReward";
import RegisterBancoElComercio from "./registerBancoElComercio";
import ConfirmationCode from "../Login/ConfirmationCode";

import {
   APP_LOGIN_DIRECT,
   APP_LOGIN_PASSWORD,
   APP_TYPE_WHITELABEL,
   buildConfig,
   LANDING_IMAGE,
   LANDING_WHITE,
   TextDefaul
} from "../../common/utils/generic";
import Page from "../../common/components/Page";
import "./style.scss"
import firebase from "firebase";
import WelcomePage from "../Login/welcomePage";
import ChangeMobile from "../Login/ChangeMobile";
import {Link} from "react-router-dom";

var images = require.context("../../common/images", true);

class LayoutRegister extends Component {

   constructor(props) {
      super(props);
      this.state = {
         step: 1,
         showWelcome: false,
         verifyMobile: false,
         changeMobile:false,
         first_name: "",
         mobile_number_last_digits: "",
         sendFirstSMS: false
      };
   }

   render() {
      let background = images(`./background_${process.env.REACT_APP_NAME_APP}.jpg`);
      let backgroundImage = {
         backgroundImage: "url(" + background + ")"
      };

      let remoteConfig = firebase.remoteConfig()
      let app_type = process.env.REACT_APP_TYPE;
      let login_type = buildConfig.app_login_type;
      let app_landing = buildConfig.app_landing;
      if (remoteConfig != null) {
         login_type = remoteConfig.getString('app_login_type');
         app_landing = remoteConfig.getString('app_landing');
      }

      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);

      return (
         <Page
            id="register"
            title={TextDefaul.webTitle}
            description={TextDefaul.webDescription}
         >
            {
               this.state.showWelcome ?
                  <WelcomePage history={this.props.history} first_name={this.state.first_name}/>
                  :
                  <Fragment>
                     {app_type === APP_TYPE_WHITELABEL &&
                     login_type === APP_LOGIN_DIRECT &&
                     <div className={"welcome"}
                          style={app_landing === LANDING_WHITE ? {height: "auto"} : {}}
                     >
                        {
                           this.state.changeMobile ?
                              <div className="login" style={{alignItems: "baseline"}}>
                                 {
                                    process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                                    <Link to={"/login"}>
                                       <img
                                          alt="logo"
                                          className="logo"
                                          src={logo}
                                       />
                                    </Link>
                                 }
                                 <ChangeMobile {...this.props}
                                               setMobileNumber={number=>this.setState({mobile_number_last_digits: number})}
                                               setVerifyMobile={c=>this.setState({verifyMobile: c})}
                                               setChangeMobile={c=>this.setState({changeMobile: c})}/>
                              </div>
                              :
                              this.state.verifyMobile ?
                                 <div className="login" style={{alignItems: "baseline"}}>
                                    {
                                       process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                                       <Link to={"/login"}>
                                          <img
                                             alt="logo"
                                             className="logo"
                                             src={logo}
                                          />
                                       </Link>
                                    }
                                    <ConfirmationCode {...this.props}
                                                      sendFirstSMS={this.state.sendFirstSMS}
                                                      setSendFirstSMS={sendFirstSMS=>this.setState({sendFirstSMS: sendFirstSMS})}
                                                      mobile_number_last_digits={this.state.mobile_number_last_digits} setWelcome={welcome=>this.setState({showWelcome: welcome})} setVerifyMobile={c=>this.setState({verifyMobile: c})} setChangeMobile={c=>this.setState({changeMobile: c})} />
                                 </div> :
                                 <div className={process.env.REACT_APP_NAME_APP === "bancoelcomercio" ? "login login-padding" : "login"}>

                                    {
                                       process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                                       <Link to={"/login"}>
                                          <img
                                             alt="logo"
                                             className="logo"
                                             src={logo}
                                          />
                                       </Link>
                                    }
                                    {
                                       process.env.REACT_APP_NAME_APP === "bancoelcomercio" ?
                                          <RegisterBancoElComercio {...this.props}
                                                                   setSendFirstSMS={sendFirstSMS=>this.setState({sendFirstSMS: sendFirstSMS})}
                                                                   setVerifyMobile={c=>this.setState({verifyMobile: c})}
                                                                   setFirstName={first_name=>this.setState({first_name: first_name})}
                                                                   setMobileNumber={number=>this.setState({mobile_number_last_digits: number})}
                                                                   setStep={step=>this.setState({step: step})}
                                                                   setWelcome={welcome=>this.setState({showWelcome: welcome})}/>
                                          :
                                          <Register  {...this.props} />
                                    }
                                 </div>

                        }
                        {
                           app_landing === LANDING_IMAGE &&
                           <div className="publicity" style={backgroundImage}/>
                        }
                     </div>
                     }
                     {app_type === APP_TYPE_WHITELABEL &&
                     login_type === APP_LOGIN_PASSWORD &&
                     <div className="welcome"
                          style={app_landing === LANDING_WHITE ? {height: "auto"} : {}}
                     >
                        {
                           this.state.changeMobile ?
                              <div className="login" style={{alignItems: "baseline"}}>
                                 {
                                    process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                                    <Link to={"/login"}>
                                       <img
                                          alt="logo"
                                          className="logo"
                                          src={logo}
                                       />
                                    </Link>
                                 }
                                 <ChangeMobile {...this.props} setMobileNumber={number=>this.setState({mobile_number_last_digits: number})}
                                               setVerifyMobile={c=>this.setState({verifyMobile: c})}
                                               setChangeMobile={c=>this.setState({changeMobile: c})}/>
                              </div>
                              :
                              this.state.verifyMobile ?
                              <div className="login" style={{alignItems: "baseline"}}>
                                 {
                                    process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                                    <Link to={"/login"}>
                                       <img
                                          alt="logo"
                                          className="logo"
                                          src={logo}
                                       />
                                    </Link>
                                 }
                                 <ConfirmationCode {...this.props}
                                                   sendFirstSMS={this.state.sendFirstSMS}
                                                   setSendFirstSMS={sendFirstSMS=>this.setState({sendFirstSMS: sendFirstSMS})}
                                                   mobile_number_last_digits={this.state.mobile_number_last_digits} setWelcome={welcome=>this.setState({showWelcome: welcome})} setVerifyMobile={c=>this.setState({verifyMobile: c})} setChangeMobile={c=>this.setState({changeMobile: c})} />
                              </div> :
                                 <div className="login" style={{alignItems: "baseline"}}>
                                    {
                                       process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                                       <Link to={"/login"}>
                                          <img
                                             alt="logo"
                                             className="logo"
                                             src={logo}
                                          />
                                       </Link>
                                    }
                                    {
                                       process.env.REACT_APP_NAME_APP === "bancoelcomercio" ?
                                          <RegisterBancoElComercio {...this.props} setWelcome={welcome=>this.setState({showWelcome: welcome})}
                                                                   setSendFirstSMS={sendFirstSMS=>this.setState({sendFirstSMS: sendFirstSMS})}
                                                                   setVerifyMobile={c=>this.setState({verifyMobile: c})}
                                                                   setMobileNumber={number=>this.setState({mobile_number_last_digits: number})}
                                                                   setFirstName={first_name=>this.setState({first_name: first_name})}
                                                                   setStep={step=>this.setState({step: step})}/>
                                          :
                                          <RegisterRewards {...this.props} />
                                    }
                                 </div>
                        }
                        {
                           app_landing === LANDING_IMAGE &&
                           <div className="publicity" style={backgroundImage}/>
                        }
                     </div>
                     }
                  </Fragment>

            }
         </Page>
      );
   }
};

LayoutRegister.propTypes = {
   location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
   }).isRequired,
   dispatch: PropTypes.func.isRequired
};

export default connect()(LayoutRegister);
