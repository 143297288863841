import HttpClient from "../../common/utils/httpClient";
import {FECH_MORE_POINTS, FECH_POINTS} from "../../common/constants/actionTypes";

export function getPoints(id_program) {
   return dispatch => HttpClient.get( dispatch, FECH_POINTS, `v5/me/programs/${id_program}/points/`);
}

export function getMorePoints(url) {
   return dispatch => HttpClient.getUrl( dispatch, FECH_MORE_POINTS, url);
}
