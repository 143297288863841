import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {validateEmail} from "./actions";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import {LANDING_IMAGE, TextDefaul} from "../../common/utils/generic";
import Page from "../../common/components/Page";
import firebase from "firebase";
import {Link} from "react-router-dom";

var images = require.context("../../common/images", true);

class ValidateEmail extends Component {
   constructor(props) {
      super(props);
      this.state = {
         sending: false,
         message: "",
         loading: true,
         isLoading: false,
         accepted_terms_cond: true
      };
   }


   componentDidMount() {
      const {match} = this.props;
      const uidApp = match.params.uidApp;
      const uidUser = match.params.uidUser;
      const token = match.params.token;
      let name_hhrr = process.env.REACT_APP_NAME_APP;
      let api_key = process.env.REACT_APP_APY_KEY;
      window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
      if (uidUser !== undefined && token !== undefined) {

         try {
            const validateEmailResponse =  this.props.dispatch(validateEmail(uidApp, uidUser, token))
            let these = this;
            if (validateEmailResponse != null) {

               let {status, data} = validateEmailResponse.value;
               const {detail} = data;
               if (status === 200) {
                  these.setState({message: detail})
               } else {
                  these.setState({message: detail})
               }
            }
         } catch (error) {

         }
      } else {
         this.context.router.history.push(`/`);
      }


   }


   render() {
      let remoteConfig = firebase.remoteConfig()
      let app_landing = remoteConfig.getString('app_landing');

      const {msg, isLoading} = this.props
      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      let background = images(
         `./background_${process.env.REACT_APP_NAME_APP}.jpg`
      );
      let backgroundImage = {
         backgroundImage: "url(" + background + ")"
      };
      return (
         <Page
            id="login"
            title={TextDefaul.webTitle}
            description={TextDefaul.webDescription}
         >
            <div className="welcome">
               <div
                  className="login"
                  style={{
                     alignItems: "baseline"
                  }}
               >
                  {
                     process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                     <Link to={"/login"}>
                        <img
                           alt="logo"
                           className="logo"
                           src={logo}
                        />
                     </Link>
                  }
                  <div className="col login-form">
                     <div
                        className="grid-container"
                        style={{
                           background: process.env.REACT_APP_NAME_APP === "rewards" ? "#00808a" : ""
                        }}
                     >
                        <header
                           className="hello"
                           style={{marginBottom: 25, marginTop: 25}}
                        >
                           {
                              process.env.REACT_APP_NAME_APP !== "bancoelcomercio" &&
                              <Fragment>
                                 <h2 className="">
                                    App de <br/>
                                    beneficios para
                                    <br/>
                                    colaboradores de
                                 </h2>
                                 <Link to={"/login"}>
                                    <img
                                       alt="logo"
                                       className="logo"
                                       src={logo}
                                    />
                                 </Link>
                              </Fragment>
                           }
                           {
                              process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                              <p className={"loginExtraText"}>Validación de correo</p>
                           }
                        </header>
                        <div>
                           {
                              isLoading &&
                              <LoadingIndicator/>
                           }
                           <p>{msg}</p>
                        </div>
                     </div>
                  </div>
               </div>
               {
                  app_landing === LANDING_IMAGE &&
                  <div className="publicity" style={backgroundImage} />
               }
            </div>
         </Page>
      );
   }

   static contextTypes = {
      router: PropTypes.object
   };
}

export default connect(({users}) => ({
   isLoading: users.isLoadingValidateEmail,
   msg: users.messageValidateEmail,

}))(ValidateEmail);

