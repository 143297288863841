import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import thunkMiddleware from 'redux-thunk';
import {applyMiddleware, createStore} from 'redux';
import reducer from "./reducersapp";
import promise from 'redux-promise-middleware';

import 'jquery';
import 'bootstrap/dist/js/bootstrap';
import './common/styles/theme.scss';
import history from "./common/utils/history";
import AuthRoute from "./common/components/AuthRoute/AuthRoute";

import Bederr from "./modules/Bederr/Bederr";
import AuthorizedRoute from "./common/components/AuthorizedRoute/AuthorizedRoute";
import TermsAndConditions from "./modules/Others/TermsAndConditions";
import Politics from "./modules/Others/Politics";
import FAQS from "./modules/Others/FAQS";
import NotFound from "./common/components/NotFound";
import {LayoutLogin} from "./modules/Login";
import {CompleteData} from "./modules/CompleteData";
import {LayoutRegister} from "./modules/Register";
import firebaseConf from "./common/utils/Firebase";
import {LayoutRecovery, NewPassword} from "./modules/RecoveryPassword";
import {buildConfig} from "./common/utils/generic";
import firebase from 'firebase'
import {ValidateEmail} from "./modules/ValidateEmail";
import FacebookStatus from "./modules/FacebookStatus/facebookStatus";
import SecondaryPurposes from "./modules/Others/SecondaryPurposes";
import PoliticsCookies from "./modules/Others/PoliticsCookies";
import Cookies from "./common/components/widgets/Cookies";
import Overlay from "./common/components/widgets/Overlay";
const $ = require('jquery');

const middlewares =
   [];

const store = createStore(
   reducer,
   applyMiddleware(promise, thunkMiddleware, ...middlewares),
);

class App extends Component {

   constructor(props) {
      super(props);
      this.state = {
         showOverlay: false,
         loading: true,
         local_style: "",
         styles_external: "",
      };
   }

   componentDidMount() {
      console.log(`${process.env.REACT_APP_APY_KEY}`)
      if (firebaseConf != null) {
         firebase.analytics()
         let remoteConfig = firebase.remoteConfig()
         remoteConfig.defaultConfig = (buildConfig)
         remoteConfig.activate()
         remoteConfig.fetchAndActivate()
            .then((out) => {
               let local_style = remoteConfig.getString("styles_local")
               //let local_style = "banbif/css/banbifstyle.css"
               let styles_external = remoteConfig.getString("styles_external")
               //let styles_external = ""
               localStorage.setItem("color",remoteConfig.getString("colors_main_color") )
               this.setState({local_style, styles_external})
               this.setState({loading: false})
            })
            .catch((err) => {
               this.setState({loading: true})
            });

      } else {
         this.setState({loading: true})
      }

      let cookies = document.cookie.split(';');
      let foundGa = -1
      let foundGuid = -1
      cookies.map((c, index)=>{
         if( (c.indexOf("_ga") > -1) && localStorage.getItem("cookie_ga")==="true"){
            foundGa = index
         }
         if((c.indexOf("cookie_guid") > -1)  && localStorage.getItem("cookie_guid")==="true"){
            foundGuid = index
         }
         return true
      })

      if((foundGa===-1 && localStorage.getItem("cookie_ga")==="true") || (foundGuid===-1 && localStorage.getItem("cookie_guid")==="true") ){
        this.setState({showOverlay: true})
      }

      if(!Object.keys(localStorage).includes("cookie_ga") && !Object.keys(localStorage).includes("cookie_guid")){
         this.setState({showOverlay: true})
      }

      if(localStorage.getItem("cookie_ga")==="false"){
         $(document).ready(function()
         {
            let aux = "<script async src=\"https://www.googletagmanager.com/gtag/js?id="+process.env.REACT_APP_F_MEASUREMENT_ID+"\"></script>\n" +
               "    <script>\n" +
               "       window['ga-disable-"+process.env.REACT_APP_F_MEASUREMENT_ID+"'] = true;\n" +
               "       window.dataLayer = window.dataLayer || [];\n" +
               "       function gtag(){dataLayer.push(arguments);}\n" +
               "       gtag('js', new Date());\n" +
               "\n" +
               "       gtag('config', '"+process.env.REACT_APP_F_MEASUREMENT_ID+"');\n" +
               "    </script>";
            $("body").append(aux);
         });

         cookies.map( item => {
            if(item.includes('_ga')){
               document.cookie = item+'=aceptada; max-age='+0+";"
            }
            return true;
         })
      }


      $('html').addClass("notOverflow");
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      let cookies = document.cookie.split(';');
      let domain = ".bederr.com"
      if(localStorage.getItem("cookie_ga")==="false"){

         if(process.env.REACT_APP_NAME_APP==="lapositiva"){
            domain = ".lapositiva.com.pe"
         }
         if(process.env.REACT_APP_NAME_APP==="petroperu"){
            domain = ".petroperuterecarga.com"
         }
         cookies.map( item => {
            if(item.includes('_ga')){
               document.cookie = item.split('=')[0]+'=; expires=Thu, 01-Jan-1970 00:00:01 GMT; max-age=0; path=/;domain='+domain+";"
            }
            return true;
         })

      }
   }

   render() {
      if (this.state.loading)
         return <div>Cargando...</div>
      else
         return (
            <Provider store={store}>
               <Router history={history}>
                  {
                     this.state.local_style !== "" &&
                     <link rel="stylesheet" type="text/css" href={`${window.location.href.substring(0, window.location.href.indexOf(window.location.pathname))+"/"}${this.state.local_style}`}/>
                  }
                  {
                     this.state.styles_external !== "" &&
                        <link rel="stylesheet" type="text/css" href={`${window.location.href.substring(0, window.location.href.indexOf(window.location.pathname))+"/"}${this.state.styles_external}`}/>
                  }
                  {
                     this.state.showOverlay &&
                     <Overlay></Overlay>
                  }
                  <Switch>
                     <AuthRoute history={history} exact path="/login" component={LayoutLogin}/>
                     <AuthRoute history={history} exact path="/register" component={LayoutRegister}/>
                     <AuthRoute exact path="/recovery" component={LayoutRecovery}/>
                     <Route exact path="/terms" component={TermsAndConditions}/>
                     <Route exact path="/politics" component={Politics}/>
                     <Route exact path="/secondaryPurposes" component={SecondaryPurposes}/>
                     <Route exact path="/cookies"
                            render={(props) =>
                               <PoliticsCookies
                                  setOverlay={ e => this.setState({showOverlay: e})}
                               />
                            }
                     />
                     <Route exact path="/faqs" component={FAQS}/>
                     <Route
                        exact
                        path="/reset-password/:uidUser/:token"
                        component={NewPassword}
                     />
                     <Route
                        exact
                        path="/verification-email/:uidApp/:uidUser/:token"
                        component={ValidateEmail}
                     />
                     <Route
                        exact
                        path="/:uidBederrApp/facebook/status/:uidUser/:code"
                        component={FacebookStatus}
                     />
                     <AuthorizedRoute
                        history={history}
                        exact
                        path="/complete-data"
                        component={CompleteData}
                     />
                     <AuthorizedRoute history={history} path="/" component={Bederr}/>
                     <Route component={NotFound}/>
                     <Redirect to="login"/>
                  </Switch>
                  {
                     this.state.showOverlay &&
                     <Cookies setOverlay={ e => this.setState({showOverlay: e})}></Cookies>
                  }
               </Router>
            </Provider>
         )
   }
}

export default App;
