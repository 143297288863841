import React from "react";

const NotificationPromo = props => {
  let logo = "";
  const promo = props.promo;
  logo = promo.company_logo ? promo.company_logo : "";
  if(props.business){
     //logo = props.business[0].company.logo
  }
  const title = promo.title;
  const description = promo.message;
  const link = promo.link;
  let logoImage = {
    backgroundImage: "url(" + logo + ")"
  };
   console.log(promo.poll)
  return (
    <div className="notification-detail row">
       {
          (promo.image || promo.poll) &&
            <div className="item notification-item col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mx-auto">
              <figure>
                <img  alt="" className="cover" src={promo.image ? promo.image : ( promo.poll && promo.poll.image) ? promo.poll.image : ""} />
                <div className="shadow-offer">
                  <div className="company-logo" style={logoImage} />
                </div>
                <div className="info">
                  <p className="pin">{title}</p>
                </div>
              </figure>
            </div>
       }
      <div className="item col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mx-auto">
        <p className="description-notification">{description}</p>
        {link != null &&
          link !== "" && (
            <div className="btns col text-center">
              <a className="btn btn-whitelabel mx-auto" href={link}>
                Más Información
              </a>
            </div>
          )}
      </div>
    </div>
  );
};

export default NotificationPromo;
