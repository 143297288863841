import * as types from '../common/constants/actionTypes';

const initialState = {
   benefitPrograms: null,
   benefitProgram: null,
   isFetching: false,
};

export default function reducer(state = initialState, action) {
   switch (action.type) {

      case `${types.BENEFIT_PROGRAM_GET}_PENDING`:
         return {...state, isFetching: true};
      case `${types.BENEFIT_PROGRAM_GET}_REJECTED`:
         return {...state, isFetching: false, error: action.payload};
      case `${types.BENEFIT_PROGRAM_GET}_FULFILLED`:
         if (action.payload.data.results.length > 0) {
            return {
               ...state,
               isFetching: false,
               benefitPrograms: action.payload.data.results,
            };
         }
         break;
      case `SET_BENEFIT_PROGRAM`:
         return {
            ...state,
            benefitProgram: action.benefit,
         };
      default:
         return state;

   }
}
