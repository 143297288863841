import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getDetailOffer, sendLead} from "./actions";
import NotificationSystem from "react-notification-system";
import {HOME} from "../../common/constants/actionTypes";
import {changeMenu} from "../Menu/actions";

class InterestedOffer extends Component {
   static contextTypes = {
      router: PropTypes.object
   };
   state = {
      values: [],
      isLoading: false
   };

   componentDidMount() {
      const {match, benefit_program} = this.props;
      const offer_id = match.params.uidOffer;
      if (benefit_program != null && offer_id !== undefined && offer_id != null) {
         this.props.dispatch(getDetailOffer(benefit_program.uid, offer_id));
      }
      this.props.dispatch(changeMenu(HOME));
   }


   handleChange = (i, event) => {
      let values = [...this.state.values];
      values[i] = event.target.value;
      this.setState({values});
   };

   componentWillReceiveProps(nextProps, nextContext) {
      const {offer} = nextProps;
      if (offer != null) {
         var values = [];
         if (offer.lead_form != null)
            offer.lead_form.form_fields.forEach((lead, index) => {
               values[index] = "";
               this.setState({values});
            });
      }
   }

   handleSubmit = async event => {
      event.preventDefault();
      this.setState({isLoading: true});
      var formObj = {};
      var uid = this.props.offer.uid;
      let benefit_program = this.props.benefit_program;
      if (this.props.offer.lead_form != null) {
         this.props.offer.lead_form.form_fields.forEach((lead, index) => {
            var k = lead.parameter;
            formObj[`${k}`] = this.state.values[index];
         });
         let responseLead = await this.props.dispatch(sendLead({details: formObj}, uid));
         let these = this;
         if (responseLead !== undefined && responseLead != null) {
            these.setState({isLoading: false});
            const status = responseLead.value;
            if (status === 200) {
               if (benefit_program != null)
                  these.props.dispatch(getDetailOffer(benefit_program.uid, uid));
            } else {
               these.addNotification(
                  "Ha sucedido un error, revisar nuevamente el formulario",
                  "error"
               );
            }

         } else {
            this.setState({isLoading: false});
            these.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      }
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   renderUI = () => {
      const {offer} = this.props;
      if (offer.lead_form != null)
         return offer.lead_form.form_fields.map((question, index) => (
            <div key={index} className="form-group">
               <input
                  className="form-control"
                  value={this.state.values[index]}
                  type="text"
                  onChange={this.handleChange.bind(this, index)}
                  placeholder={question.text}
               />
            </div>
         ));
      else return <div/>;
   };

   render() {
      const {loadingOffer, isLoadingInterest, offer} = this.props;
      if (loadingOffer || isLoadingInterest)
         return (
            <div className="row content-loader">
               <NotificationSystem
                  ref={notificationSystem =>
                     (this.notificationSystemRef = notificationSystem)
                  }
               />
               <p>Cargando...</p>
            </div>
         );
      if (offer == null) {
         return (
            <div className="content-no-items">
               <NotificationSystem
                  ref={notificationSystem =>
                     (this.notificationSystemRef = notificationSystem)
                  }
               />
               <p>Ups! , no pudimos encontrar la oferta </p>
            </div>
         );
      } else {
         if (offer.enable_lead && !offer.interested && offer.lead_form != null) {
            var title = "Por favor, llenar los siguientes datos";
            if (offer.lead_form.title != null && offer.lead_form.title.lenght > 0) {
               title = offer.lead_form.title;
            }
            return (
               <div className="container left-container">
                  <div className="row">
                     <NotificationSystem
                        ref={notificationSystem =>
                           (this.notificationSystemRef = notificationSystem)
                        }
                     />
                     <div className="col col-lg-8 col-md-12 dinamic-form">
                        <p>
                           <Link to={`/offers/${offer.uid}`}>
                              {" "}
                              {`<`} Volver al detalle de la oferta
                           </Link>
                        </p>
                        <h4 className="title-section bold ">{offer.title}</h4>
                        <p className="subtitle-left">{title}</p>
                        <form onSubmit={this.handleSubmit}>
                           {this.renderUI()}
                           <div className="content-btn">
                              <button
                                 type="submit"
                                 className="btn btn-whitelabel btn-lg "
                                 onClick={this.handleSubmit}
                              >
                                 {this.state.isLoading && "Cargando..."}
                                 {!this.state.isLoading && "Enviar"}
                              </button>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            );
         } else {
            if (offer.interested) {
               return (
                  <div className="content-no-items">
                     <NotificationSystem
                        ref={notificationSystem =>
                           (this.notificationSystemRef = notificationSystem)
                        }
                     />
                     <p>
                        Felicitaciones, usted ha sido registrado(a) en nuestra base de
                        datos, pronto nos pondremos en contacto con su persona
                     </p>
                     <p>
                        <Link
                           to={`/offers/${offer.uid}`}
                           className="btn btn-whitelabel-auxiliar"
                        >
                           Volver al detalle de la oferta
                        </Link>
                     </p>
                  </div>
               );
            } else {
               return (
                  <div className="content-no-items">
                     <NotificationSystem
                        ref={notificationSystem =>
                           (this.notificationSystemRef = notificationSystem)
                        }
                     />
                     <p>Ups! , esta oferta no tiene dicha opción activa</p>
                     <p>
                        <Link
                           to={`/offers/${offer.uid}`}
                           className="btn btn-whitelabel-auxiliar"
                        >
                           Ver detalle de la oferta
                        </Link>
                     </p>
                  </div>
               );
            }
         }
      }
   }
}

InterestedOffer.defaultProps = {
   offer: null,
   loadingOffer: false,
   benefit_program: null,
   isLoadingInterest: false,
   errorInterested: false,
   successInterested: 0
};

export default connect(({offers, benefitPrograms}) => ({
   benefit_program: benefitPrograms.benefitProgram,
   offer: offers.offer,
   loadingOffer: offers.isFechingOffer,
   isLoadingInterest: offers.isLoadingInterest,
   errorInterested: offers.errorInterested,
   successInterested: offers.successInterested
}))(InterestedOffer);
