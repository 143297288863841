import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {getCountries} from "../../reducersapp/countries";
import arrow_down from "../../common/images/downarrow.png";
import searchImage from "../../common/images/search.png";
import {editCity, getUser} from "../Register/actions";
import {getTags} from "./actions";
import "./menus.scss"
import {getProgramPromotions} from "../ProgramPromotions/actions";
import firebase from "firebase";

var images = require.context("../../common/images", true);


class MainMenu extends Component {
   constructor(props) {
      super(props);
      this.state = {
         searchText: "",
         localityUid: -1,
         localityText: "Seleccionar un distrito",
         name: "",
         active_level: null,

         search: '',
         typing: false,
         typingTimeout: 0,
      };
      this.searchTerm = this.searchTerm.bind(this);
   }

   searchTerm = event => {
      const self = this;

      if (self.state.typingTimeout) {
         clearTimeout(self.state.typingTimeout);
      }
      let that = this;
      self.setState({
         search: event.target.value,
         typing: false,
         typingTimeout: setTimeout(function () {
            that.searchBenefits()
         }, 800)
      });
   };

   searchBenefits = () => {
      if(!this.state.typing){
         let loc = this.state.localityUid !== -1 ? this.state.localityUid : null;
         let searchText = this.state.search;

         let route = "";

         if (loc !== -1 && loc != null) {
            route = "?loc=" + loc;
         }
            if (route === "") {
               route = "?search=" + searchText;
            } else {
               route = route + "&search=" + searchText;
            }
         this.setState({searchText: ""});
         if (route !== "") {
            this.props.history.push({pathname: "/benefits", search: route});
         }

         this.setState({typing:true})
      }
   }

   handleSearch = async event => {
      event.preventDefault();
      const loc = this.state.localityUid !== -1 ? this.state.localityUid : null;
      const searchText = this.state.searchText;
      var route = "";
      if (loc !== -1 && loc != null) {
         route = "?loc=" + loc;
      }
      if (searchText !== "") {
         if (route === "") {
            route = "?search=" + searchText;
         } else {
            route = route + "&search=" + searchText;
         }
      }
      this.setState({searchText: ""});
      if (route !== "") {
         this.props.history.push({pathname: "/benefits", search: route});
      }
   };

   componentDidMount() {
      this.props.dispatch(getUser({}));
      this.props.dispatch(getCountries());
   }


   componentWillReceiveProps(nextProps, nextContext) {
      const {location} = nextProps;
      if (
         nextProps.user !== undefined &&
         nextProps.user != null &&
         nextProps.benefit_program !== undefined &&
         nextProps.benefit_program != null
      ) {
         let name = "";
         if (nextProps.user.first_name && nextProps.user.first_name.length > 0)
            name = this.props.user.first_name ? this.props.user.first_name.split(" ")[0] : "";
         if(this.state.name!==nextContext.name)
            this.setState({name: name});

         let levels = this.props.benefit_program.levels;
         for (let i = 0; i < levels.length; i++) {
            let level = levels[i];
            if (level.is_active) {
                this.setState({active_level: level.name});
            }
         }
         if (this.props.user.area  && this.props.user.area.uid !== nextProps.user.area.uid) {
            this.props.dispatch(getTags(this.props.benefit_program.uid));

            if (location.pathname.includes("category") || location.pathname.includes("offers") ) {
               this.props.history.push({pathname: `/`});
            } else {
               if (location.pathname === "/") {
                  this.props.dispatch(
                     getProgramPromotions(
                        this.props.benefit_program.uid,
                        "",
                        "",
                        "",
                        "",
                        "",
                        "",
                        true
                     )
                  );
               }
            }
         }
      }
   }

   handleSearchInput = event =>
      this.setState({searchText: event.target.value});

   changeCity(e, cityId) {
      e.preventDefault();
      this.props.dispatch(editCity(cityId));
   }

   renderLocalities() {
      const {localities, loadLocalities} = this.props;
      //const {localities, loadLocalities, app} = this.props;
      /*
      let areasAvaliables = []
      if(app.length!==0 && process.env.REACT_APP_NAME_APP==="bancoelcomercio"){
         app[0].countries.map(country =>{
            country.areas.map(area=>{
               areasAvaliables.push(area)
               return true
            })
            return true
         })
      }

       */
      if (localities != null && localities.length > 0) {
         let localitiesAux = localities.sort((a, b) => a.name > b.name ? 1 : -1)
         let localitiesFilter = []
         localitiesAux.map(locality => {
               if(locality.country === "Perú")
                  localitiesFilter.push(locality)
            return true
         })

         return localitiesFilter.map((locality, index) => (
            <div
               key={`locality${index}`}
               onClick={e => this.changeCity(e, locality.uid)}
               className="dropdown-item dropdown-type select-type"
            >
               {locality.name}
            </div>
         ));
      } else {
         if (loadLocalities) {
            return <p> Cargando...</p>;
         } else {
            return <p> No se encontraron ciudades</p>;
         }
      }
   }

   render() {
      let backgroundImage = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);

      const {benefit_program, user, loadingEditCity} = this.props;
      let firebaseConfig = firebase.remoteConfig()
      let use_logo_manual = firebaseConfig.getBoolean("company_config_static_logo")
      let company_config_alternative_logo = firebaseConfig.getBoolean("company_config_alternative_logo")
      let color_menu = (localStorage.getItem("color") && localStorage.getItem("color").length!==0) ? localStorage.getItem("color") : firebaseConfig.getString("colors_menu_color")
      let logoCompany = `${window.localStorage.getItem(
         `logo${process.env.REACT_APP_NAME_APP}`
      )}`;
      if (benefit_program != null) {
         if (
            benefit_program.company_logo_rectangle != null &&
            benefit_program.company_logo_rectangle !== ""
         ) {
            logoCompany = benefit_program.company_logo_rectangle;
         } else {
            if (
               benefit_program.company_logo_rectangle != null &&
               benefit_program.company_logo_rectangle !== ""
            ) {
               logoCompany = benefit_program.company_logo_rectangle;
            }
         }
      }
      let back = {
         background: "#ffffff"
      };
      /*let current_level = null;
      if (user != null) {
         if (user.levels != null && user.levels.length > 0) {
            for (let i = 0; i < user.levels.length; i++) {
               if (user.levels[i].is_active) {
                  current_level = user.levels[i];
                  break;
               }
            }
         }
      }*/
      if(color_menu!==""){
         back = {
            background: `${color_menu}`
         };
      }else{
         if (
            benefit_program != null &&
            benefit_program.color != null &&
            benefit_program.color !== ""
         ) {
            let color = benefit_program.color.replace("#", "");
            back = {
               background: `#${color}`,
               color: "#ffffff"
            };
         }
      }


      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      if(company_config_alternative_logo && process.env.REACT_APP_NAME_APP!=="petroperu"){
         logo = images(`./logo_alterno_${process.env.REACT_APP_NAME_APP}.png`);
      }

      return (
         <nav
            style={back}
            className="navbar navbar-expand-md sticky sticky-top navbar-light bederr-navbar ml-lg-0"
         >
            <button
               className="navbar-toggler"
               type="button"
               data-toggle="collapse"
               data-target="#navbarFilters"
               aria-controls="navbarFilters"
               aria-expanded="false"
               aria-label="Toggle navigation"
            >
               <span className="navbar-toggler-icon"/>
            </button>
            <a
               className={"navbar-brand  col-lg-2 col-xs-5 col-8 col-sm-4 col-md-3 mr-0"}
               href="/"
            >
               {
                  process.env.REACT_APP_NAME_APP === "lapositiva" ?
                     <img src={backgroundImage} alt={"logo"} className="logo-img"/>
                     :
                     process.env.REACT_APP_NAME_APP === "lapositiva" ?
                        <img src={logo} alt={"logo"} className="logo-img"/>
                        :
                     <Fragment>
                        {use_logo_manual && <img src={logo} alt={"logo"} className="logo-img"/>}
                        {use_logo_manual === false && (
                           <img src={logoCompany} alt={"logo"} className="logo-img"/>
                        )}
                     </Fragment>
               }
            </a>

            <div style={back} className="navbar-collapse collapse navbar-boc">
               <form
                  className="mr-auto col-lg-8 form-inline align-self-center search-section"
                  onSubmit={this.handleSearch}
               >
                  <input
                     className="form-control mr-sm-10 col-md-10 input-search"
                     type="search"
                     placeholder="Buscar"
                     value={this.state.search}
                     onKeyPress={event => {
                        if (event.key === 'Enter') {
                           this.searchTerm(event)
                        }
                     }}
                     onChange={e => {
                        this.setState({typing:true})
                        this.searchTerm(e)
                     }}
                  />
                  <div className="input-group-append search-btn">
                     <button
                        onClick={this.handleSearch}
                        className="btn"
                        type="button"
                        id="btn_search"
                     >
                        <img src={searchImage} alt="buscar"/>
                     </button>
                  </div>
               </form>

               <div className="col-lg-2 col-md-3 select-boc">
                  <div className="select-menu form-group dropdown">
                     <div className="menu-dropdown">
                        <div
                           className="form-control"
                           id="city_menu"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           {loadingEditCity && <span>Cargando...</span>}
                           {loadingEditCity === false && (
                              <span>{user != null && user.area.name}</span>
                           )}
                           <img src={arrow_down} alt="" className={"arrow-down"}/>
                        </div>
                        <div
                           className="dropdown-menu "
                           style={{width: "100%", overflowY: "scroll", maxHeight: 200}}
                           aria-labelledby="city_menu"
                        >
                           {this.renderLocalities()}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </nav>
      );
   }
}

MainMenu.defaultProps = {
   loadingEditCity: false,
   localities: [],
   loadLocalities: false,
   app: []
};

export default connect(
   ({users, countries, benefitPrograms, notifications, app}) => ({
      user: users.user,
      benefit_program: benefitPrograms.benefitProgram,
      loadLocalities: countries.isFetching,
      localities: countries.data,
      notifications: notifications.data,
      loadingEditCity: users.isFetchingCity,
      app: app.app
   })
)(MainMenu);
