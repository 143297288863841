import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

class AuthRoute extends React.Component {
   isLogin = () => {
      const NAME = process.env.REACT_APP_NAME_APP;
      const token = localStorage.getItem(`token${NAME}`);
      return token != null;
   }

   render() {
      const {...rest} = this.props;
      const ComponentToRender = this.props.component;
      if (!this.isLogin()) {
         return (
            <ComponentToRender {...rest} />
         );
      }
      return (

         <Redirect to="/"/>
      );
   }
}


export default connect()(AuthRoute);
