import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import {getMorePoints, getPoints} from "./actions";
import HistoryPoint from "../../common/components/widgets/HistoryPoint";
import "./style.scss"


class Points extends Component {
  static contextTypes = {
    router: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      uidProgram: null
    };
  }


  componentDidMount() {
     let benefit_program = this.props.benefit_program;
     if (benefit_program != null) {
        this.setState({ uidProgram: benefit_program.uid });
        this.props.dispatch(getPoints(benefit_program.uid));
     }
  }

  componentWillReceiveProps(nextProps, nextContext) {
    let benefit_program = nextProps.benefit_program;
    if (this.state.uidProgram == null) {
      if (benefit_program != null) {
        this.setState({ uidProgram: benefit_program.uid });
        this.props.dispatch(getPoints(benefit_program.uid));
      }
    }
  }

  renderPoints() {
    const { points } = this.props;
    if (points != null) {
      if (points.length === 0) {
        return (
          <div className="content-no-items">
            <p>No encontramos registros de Puntos</p>
          </div>
        );
      } else {
        return points.map((point, index) => (
          <HistoryPoint key={index} point={point} />
        ));
      }
    }
  }

  loadMore = () => {
    const { next, moreLoader } = this.props;
    if (!moreLoader && next != null) {
      this.props.dispatch(getMorePoints(next));
    }
  };

  render() {
    const { loader, next, points } = this.props;
    let more = next != null;
    if (loader || points == null) {
      return (
        <div className="row content-loader">
          <p>Cargando...</p>
        </div>
      );
    }
    var loaderMore = (
      <div
        key={0}
        className="col-lg-12 content-loader"
        style={{ display: "inline-flex" }}
      >
        <p>Cargando...</p>
      </div>
    );
    return (
      <div>
        <div className="container left-container">
          <h3 className="title-section">Historial de puntos acumulados</h3>
        </div>
        <div
          className="container left-container"
          style={{ position: "relative" }}
        >
          <InfiniteScroll
            className="notification-section row"
            pageStart={0}
            loadMore={this.loadMore.bind(this)}
            hasMore={more}
            loader={loaderMore}
          >
            {this.renderPoints()}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

Points.defaultProps = {
  addNotification: () => {},
  points: [],
  moreLoader: false,
  loader: false,
  benefit_program: null
};

export default connect(({ points, benefitPrograms }) => ({
  benefit_program: benefitPrograms.benefitProgram,
  points: points.data,
  loader: points.isFetching,
  moreLoader: points.isFetchingMore,
  next: points.next
}))(Points);
