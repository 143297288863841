import Cookies from "js-cookie";

export const AUTHENTICATE = "auth/AUTHENTICATE";
export const SET_CURRENT_USER = "auth/SET_CURRENT_USER";
export const SET_CURRENT_TOKEN = "auth/SET_CURRENT_TOKEN";

const initialState = {
  isAuthenticated: undefined,
  currentUser: {},
  token: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        isAuthenticated: action.authenticated
      };

    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.user
      };
    case SET_CURRENT_TOKEN:
      return {
        ...state,
        token: action.token
      };
    default:
      return state;
  }
};

export const setToken = token => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_CURRENT_TOKEN,
      token
    });
    Cookies.set("token", token);
    dispatch({
      type: AUTHENTICATE,
      authenticated: true
    });
    resolve(token);
  });

export const setCurrentToken = token => dispatch =>
  new Promise(resolve => {
    Cookies.set("token", token);
    dispatch({
      type: AUTHENTICATE,
      authenticated: true
    });
    resolve(token);
  });

export const setCurrentUser = user => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: SET_CURRENT_USER,
      user
    });

    Cookies.set("mywebsite", user);

    dispatch({
      type: AUTHENTICATE,
      authenticated: true
    });

    resolve(user);
  });

export const establishCurrentUser = () => dispatch =>
  new Promise(resolve => {
    let userFromCookie = Cookies.getJSON("token");
    if (userFromCookie) {
      dispatch(setToken(userFromCookie));
      resolve(userFromCookie);
    } else {
      resolve({});
    }
  });

export const loginUser = (email, password) => dispatch =>
  new Promise((resolve, reject) => {
    const user = {
      email,
      password,
      name: "Awesome User"
    };
    dispatch(setCurrentUser(user));
    resolve(user);
  });

export const logoutUser = () => dispatch =>
  new Promise(resolve => {
    dispatch({
      type: AUTHENTICATE,
      authenticated: false
    });
    dispatch({
      type: SET_CURRENT_TOKEN,
      token: null
    });
    Cookies.remove("token");
    resolve({});
  });
