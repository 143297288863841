import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import {APP_TYPE_WHITELABEL} from "../../common/utils/generic";
import Validator from "../../common/utils/validator";
import {logIn, verifyBirthdateBancoElComercio} from "./actions";
import {setToken} from "../../reducersapp/auth";
import "./style.scss"

var images = require.context("../../common/images", true);

class LoginWhiteStep3 extends Component {
   constructor(props) {
      super(props);
      this.state = {
         birthdate: "",
         business: "",
         isLoading: false,
         type: "NATIONAL_ID"
      };
   }

   componentDidMount() {
      this.setState({business: process.env.REACT_APP_NAME_APP});
      this.validator = new Validator([
         {
            id: "birthdate",
            ref: this.birthdateInput,
            rules: ["required"]
         }
      ]);
         document.getElementById('birthdate').type= 'text';
         document.getElementById('birthdate').addEventListener('focus',function(){
            document.getElementById('birthdate').type= 'date';
         });
   }


   handleBirthdate = event => this.setFormValues({birthdate: event.target.value});

   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   searchBirthdate = async (birthdate) => {
         try {
            const responseVerify = await this.props.dispatch(
               verifyBirthdateBancoElComercio(this.props.document_number, birthdate)
            );
            let these = this;
            if (responseVerify != null) {
               let {status, data} = responseVerify.value;
               const {names} = data;
               if (status === 200) {
                  if(process.env.REACT_APP_NAME_APP === "bancoelcomercio"){
                     localStorage.setItem("name", names)
                     this.props.setStep(1)
                     localStorage.setItem("validate", "true")
                     this.redirectToLogin(this.props.document_number);
                  }
               } else {
                  these.setState({isLoading: false});

                  these.addNotification(
                     "Ups! No se encontró la fecha de emisión, intentelo nuevamente",
                     "error"
                  );
               }
            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! No se encontró la fecha de emisión, intentelo nuevamente",
                  "error"
               );
            }
         } catch (error) {
            this.setState({isLoading: false});
            if (process.env.REACT_APP_TYPE !== APP_TYPE_WHITELABEL)
               this.addNotification("Compañia no encontrada", "error");
            this.addNotification(
               "Ups! No se encontró la fecha de emisión, intentelo nuevamente",
               "error"
            );
         }
   };

   redirectToLogin(dni) {
      this.props.history.push(
         `/register?dni=${dni}&document_type=${this.state.type}`
      );
   }

   loginUser = async dni => {
      try {
         let document_type = this.state.type;
         const logInResponse = await this.props.dispatch(
            logIn({dni, document_type})
         );
         let these = this;
         if (
            logInResponse !== undefined &&
            logInResponse != null
         ) {

                  let {status, data} = logInResponse.value;
                  if (status === 200) {
                     const {token} = data;
                     let name = process.env.REACT_APP_NAME_APP;
                     these.props.setWelcome(true)
                     setTimeout(() => {
                        window.localStorage.setItem(`token${name}2`, token);
                        (document.location.href = '/');
                        if( window.localStorage.getItem(`token${name}`)!==null || window.localStorage.getItem(`token${name}`)!=="null"){
                           window.localStorage.setItem(`token${name}`, token);
                           window.localStorage.removeItem(`token${name}2`);
                           these.props.dispatch(setToken(token));
                        }
                        return true;
                     }, 7000);
                  } else {
                     these.setState({isLoading: false});
                     if (status === 400) {
                        these.redirectToLogin(dni);
                     } else {
                        these.addNotification(
                           "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                           "error"
                        );
                     }
                  }

         } else {
            this.setState({isLoading: false});
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };

   selectDocumentType = (e, name, type_id) => {
      e.preventDefault();
      this.setState({type_text: name, type: type_id});
   };

   handleSubmit = async event => {
      event.preventDefault();

      const {birthdate, business} = this.state;
      const isValid = this.validator.validate({birthdate, business});

      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }

      this.setState({isLoading: true});
      if (!this.state.isLoading) {
         console.log(birthdate)
         this.searchBirthdate(birthdate, business);
      }
   };

   render() {
      let calendarIcon = images(
         `./calendar.svg`
      );
      let backgroundImage = {
         backgroundImage: "url(" + calendarIcon + ")"
      };
      return (
         <div className="col login-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container">
               <header
                  className="hello"
                  style={{marginBottom: 15, marginTop: 25}}
               >
                  <p className={"loginExtraText"}>Queremos validar que eres tú</p>
               </header>
               <form onSubmit={this.handleSubmit}>
                  <div>
                     <div
                        className="form-group"
                        style={{position: "relative"}}
                        ref={input => {
                           if (input) {
                              this.birthdateInput = input.querySelector(
                                 "input"
                              );
                           }
                        }}
                     >
                        <input
                           value={this.state.birthdate}
                           onChange={this.handleBirthdate}
                           type="text"
                           id={"birthdate"}
                           placeholder="Fecha de emisión del DNI"
                           required=""
                           className="form-control calendar-icon"
                           style={backgroundImage}
                        />
                     </div>
                  </div>

                  <div style={{marginBottom: 10, marginTop: 10}}>
                     <button
                        type="submit"
                        className={ this.state.birthdate !== "" ? "btn btn-whitelabel btn-lg btn-block" : "btn btn-whitelabel-disabled btn-lg btn-block "}
                        onClick={this.handleSubmit}
                     >
                        {this.state.isLoading && "Cargando..."}
                        {!this.state.isLoading && "Continuar"}
                     </button>
                  </div>
               </form>
            </div>
         </div>
      );
   }

   static contextTypes = {
      router: PropTypes.object
   };
}

export default connect()(LoginWhiteStep3);
