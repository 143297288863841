import React, {Component} from "react";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import Validator from "../../common/utils/validator";
import {APP_TYPE_WHITELABEL, TextDefaul} from "../../common/utils/generic";
import {getCompany, logIn, verifyUser} from "./actions";
import {setToken} from "../../reducersapp/auth";
import './style.scss';


var images = require.context("../../common/images", true);

class Login extends Component {
   constructor(props) {
      super(props);
      this.state = {
         dni: "",
         business: "",
         type: "NATIONAL_ID",
         type_text: "Documento de Identidad",
         isLoading: false
      };
   }

   componentDidMount() {

      if (process.env.ty) {
         let name_hhrr = process.env.REACT_APP_NAME_APP;
         let api_key = process.env.REACT_APP_APY_KEY;
         window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
      }

      this.validator = new Validator([
         {
            id: "dni",
            ref: this.dniInput,
            rules: ["required", "dni"]
         }
      ]);
      this.validator = new Validator([
         {
            id: "business",
            ref: this.businessInput,
            rules: ["required", "business"]
         }
      ]);
   }

   handleDniPaste = event => this.setFormValues({ dni: event.target.value });
   handleDni = event => this.setFormValues({ dni: event.target.value });

   handleBusinessPaste = event =>
      this.setFormValues({ business: event.target.value });
   handleBusiness = event =>
      this.setFormValues({ business: event.target.value });

   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   searchBusiness = async (dni, subdomain) => {
      try {
         const companyResponse = await this.props.dispatch(
            getCompany(subdomain)
         );

         let these = this;
         if (companyResponse !== undefined && companyResponse != null) {

                  let { status, data } = companyResponse.value;
                  if (status === 200) {
                     const { api_key, logo, name } = data;
                     let name_hhrr = process.env.REACT_APP_NAME_APP;
                     window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
                     window.localStorage.setItem(`logo${name_hhrr}`, logo);
                     window.localStorage.setItem(`name${name_hhrr}`, name);
                     these.searchUser(dni);
                  } else {
                     these.setState({ isLoading: false });
                     if (process.env.REACT_APP_TYPE!==APP_TYPE_WHITELABEL)
                        these.addNotification(
                           "Compañia no encontrada",
                           "error"
                        );
                  }

         } else {
            these.setState({ isLoading: false });
            these.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({ isLoading: false });
         if (process.env.REACT_APP_TYPE!==APP_TYPE_WHITELABEL)
            this.addNotification("Compañia no encontrada", "error");
      }
   };

   loginUser = async dni => {
      try {
         let document_type = this.state.type;
         const logInResponse = await this.props.dispatch(
            logIn({ dni, document_type })
         );
         let these = this;
         if (
            logInResponse !== undefined &&
            logInResponse != null
         ) {

                  let { status, data } = logInResponse.value;
                  if (status === 200) {
                     const { token } = data;
                     let name = process.env.REACT_APP_NAME_APP;
                     window.localStorage.setItem(`token${name}`, token);
                     these.props.dispatch(setToken(token));
                     setTimeout(() => {
                        (document.location.href = '/');
                        return true;
                     }, 1000);
                  } else {
                     these.setState({ isLoading: false });
                     if (status === 400) {
                        these.props.history.push(
                           `/register?dni=${dni}&document_type=${
                              these.state.type
                           }`
                        );
                     } else {
                        these.addNotification(
                           "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                           "error"
                        );
                     }
                  }
         } else {
            this.setState({ isLoading: false });
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({ isLoading: false });
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };

   selectDocumentType = (e, name, type_id) => {
      e.preventDefault();
      this.setState({ type_text: name, type: type_id });
   };

   searchUser = async dni => {
      try {
         let document_type = this.state.type;
         const responseVerify = await this.props.dispatch(
            verifyUser({dni, document_type })
         );
         let these = this;
         if (responseVerify != null) {


                  const { status } = responseVerify.value;
                  if (status === 200) {
                     these.loginUser(dni);
                  } else {
                     these.setState({ isLoading: false });
                     these.addNotification(
                        "No se ha podido loguear en este momento",
                        "error"
                     );
                  }

         } else {
            this.setState({ isLoading: false });
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({ isLoading: false });
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };

   handleSubmit = async event => {
      event.preventDefault();

      const { dni, business } = this.state;
      const isValid = this.validator.validate({ dni, business });

      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }

      this.setState({ isLoading: true });
      if (!this.state.isLoading) {
         this.searchBusiness(dni, business);
      }
   };

   render() {
      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);


      return (
         <div className="col login-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container">
               <header
                  className="hello"
                  style={{ marginBottom: 25, marginTop: 25 }}
               >
                  <h2 className="text-app">{TextDefaul.textApp}</h2>
                  <img alt="logo" className="logo" src={logo} />
               </header>
               <form onSubmit={this.handleSubmit}>
                  <div>
                     <div
                        className="input-group form-group "
                        ref={input => {
                           if (input) {
                              this.businessInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           value={this.state.business}
                           onChange={this.handleBusiness}
                           type="text"
                           placeholder="Empresa"
                           required=""
                        />
                        <div className="input-group-append">
                           <span className="input-group-text" id="basic-addon2">
                              .bederr.com
                           </span>
                        </div>
                     </div>
                  </div>
                  <label>Seleccione el tipo de documento</label>
                  <div className="search-types form-group col dropdown">
                     <div
                        className="form-control"
                        id="dropdownDocumentType"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <span>{this.state.type_text}</span>
                     </div>
                     <div
                        className="dropdown-menu"
                        style={{
                           width: "100%",
                           overflowY: "scroll",
                           maxHeight: 200
                        }}
                        aria-labelledby="dropdownDocumentType"
                     >
                        <a
                           href="/"
                           className="dropdown-item dropdown-type select-type"
                           onClick={e =>
                              this.selectDocumentType(
                                 e,
                                 "Documento de Identidad",
                                 "NATIONAL_ID"
                              )
                           }
                        >
                           Documento de Identidad
                        </a>
                        <a
                           href="/"
                           className="dropdown-item dropdown-type select-type"
                           onClick={e =>
                              this.selectDocumentType(
                                 e,
                                 "Pasaporte",
                                 "PASSPORT"
                              )
                           }
                        >
                           Pasaporte
                        </a>
                        <a
                           href="/"
                           className="dropdown-item dropdown-type select-type"
                           onClick={e =>
                              this.selectDocumentType(
                                 e,
                                 "Carnet de Extranjería",
                                 "FOREIGNER_ID"
                              )
                           }
                        >
                           Carnet de Extranjería
                        </a>
                        <a
                           href="/"
                           className="dropdown-item dropdown-type select-type"
                           onClick={e =>
                              this.selectDocumentType(e, "Ruc", "TAX_ID")
                           }
                        >
                           Ruc
                        </a>
                        <a
                           href="/"
                           className="dropdown-item dropdown-type select-type"
                           onClick={e =>
                              this.selectDocumentType(e, "Otro", "OTHER")
                           }
                        >
                           Otro
                        </a>
                     </div>
                  </div>
                  <label>Número de documento.</label>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.dniInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.dni}
                           onChange={this.handleDni}
                           type="number"
                           placeholder="Documento de identidad"
                           required=""
                        />
                     </div>
                  </div>

                  <div style={{ marginBottom: 10, marginTop: 20 }}>
                     <button
                        type="submit"
                        className="btn btn-whitelabel btn-lg btn-block"
                        onClick={this.handleSubmit}
                     >
                        {this.state.isLoading && "Cargando..."}
                        {!this.state.isLoading && "Ingresar"}
                     </button>
                  </div>
               </form>
            </div>
         </div>
      );
   }
}

export default connect()(Login);
