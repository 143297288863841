import React, {Component, Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import check from "../../common/images/checkbox_check.png";
import no_check from "../../common/images/checkbox.png";
import {normalize, schema} from "normalizr";


import "./style.scss"
import {getProgramPromotions} from "../ProgramPromotions/actions";
import CheckIcon from "../Register/icons/CheckIcon";

const proccessNewFields = value => {
   return {...value, select: false};
};
const filterSchema = new schema.Entity(
   "data",
   {},
   {processStrategy: proccessNewFields, idAttribute: "uid"}
);

class Filters extends Component {
   static contextTypes = {
      router: PropTypes.object
   };

   state = {
      filters: [],
      categories: null,
      localities: [],
      services: [],
      categoriesSelect: [],
      localitiesSelect: [],
      servicesSelect: []
   };


   componentDidMount() {
      if (this.props.selectTag != null) {
         const selectTag = this.props.selectTag;
         this.setState({
            categories: normalize(selectTag.categories, [filterSchema]).entities.data
         });
         this.setState({
            localities: normalize(selectTag.localities, [filterSchema]).entities.data
         });
         this.setState({
            services: normalize(selectTag.services, [filterSchema]).entities.data
         });
      }
   }


   selectFilter = (e, type, uid) => {
      e.preventDefault();
      const {benefit_program, selectTag} = this.props;
      let {
         categories,
         localities,
         services,
         categoriesSelect,
         localitiesSelect,
         servicesSelect
      } = this.state;
      switch (type) {
         case "category":
            categories[uid].select = !categories[uid].select;
            if (categories[uid].select) {
               categoriesSelect.push(uid);
            } else {
               let index = categoriesSelect.indexOf(uid);
               if (index > -1) {
                  categoriesSelect.splice(index, 1);
               }
            }
            this.setState({categories: categories});
            break;
         case "localities":
            localities[uid].select = !localities[uid].select;
            this.setState({localities: localities});
            if (localities[uid].select) {
               localitiesSelect.push(uid);
            } else {
               let index = localitiesSelect.indexOf(uid);
               if (index > -1) {
                  localitiesSelect.splice(index, 1);
               }
            }
            break;
         case "services":
            services[uid].select = !services[uid].select;
            this.setState({services: services});
            if (services[uid].select) {
               servicesSelect.push(uid);
            } else {
               let index = servicesSelect.indexOf(uid);
               if (index > -1) {
                  servicesSelect.splice(index, 1);
               }
            }
            break;

         default:
            break;
      }
      let str_localities = "";
      let str_services = "";
      let str_categories = "";
      categoriesSelect.forEach(function (element, i) {
         if (str_categories.length > 0) str_categories += "," + element;
         else str_categories = element;
      });
      servicesSelect.forEach(function (element, i) {
         if (str_services.length > 0) str_services += "," + element;
         else str_services = element;
      });
      localitiesSelect.forEach(function (element, i) {
         if (str_localities.length > 0) str_localities += "," + element;
         else str_localities = element;
      });

      this.props.dispatch(
         getProgramPromotions(
            benefit_program.uid,
            selectTag.uid,
            str_localities,
            null,
            str_categories,
            str_services,
            null,
            true
         )
      );
   };

   renderCategories = () => {
      const {selectTag} = this.props;
      if (selectTag == null) return null;
      if (selectTag.categories.length === 0) return "No hay Filtros";


      return selectTag.categories.map(item => (
         <li
            key={item.uid}
            onClick={e => this.selectFilter(e, "category", item.uid)}
            className={
               ( this.state.categories!= null && this.state.categories[item.uid].select !== undefined
                  && this.state.categories[item.uid].select === true) ? "category_select d-flex align-items-center pb-1" : " d-flex align-items-center pb-1"
            }
         >
            {
               process.env.REACT_APP_NAME_APP==="bancoelcomercio" ?
                  <Fragment>
                     {this.state.categories != null && this.state.categories[item.uid].select !== undefined
                     && this.state.categories[item.uid].select === true && (
                        <Fragment>
                           <label style={{transform: "scale(2)",  marginRight: 15}}>
                              <input
                                 type="checkbox"
                                 checked={true}
                                 className="red"
                                 style={{marginTop: 10}}
                              />
                              <span style={{marginLeft: 7}}>
                                 <CheckIcon/>
                              </span>
                           </label>
                        </Fragment>
                     )}
                     { (this.state.categories == null || this.state.categories[item.uid].select === undefined
                        || this.state.categories[item.uid].select === false ) && (
                        <Fragment>
                           <label style={{transform: "scale(2)",  marginRight: 10}}>
                              <input
                                 type="checkbox"
                                 checked={false}
                                 className="red"
                                 style={{marginTop: 10}}
                              />
                              <span style={{marginLeft: 7}}>
                                 <CheckIcon/>
                              </span>
                           </label>
                        </Fragment>
                     )}
                  </Fragment>
                  :
                  <Fragment>
                     {this.state.categories != null && this.state.categories[item.uid].select !== undefined
                     && this.state.categories[item.uid].select === true && (
                        <img alt="check" src={check}/>
                     )}
                     { (this.state.categories == null || this.state.categories[item.uid].select === undefined
                        || this.state.categories[item.uid].select === false ) && (
                        <img alt="check" src={no_check}/>
                     )}
                  </Fragment>
            }

            {item.name}
         </li>
      ));
   };

   render() {
      const {selectTag} = this.props;
      if (selectTag == null) return null;
      if (selectTag.categories.length === 0) return null;
      return <ul>{this.renderCategories()}</ul>;
   }
}

Filters.defaultProps = {
   addNotification: () => {
   },
   selectTag: null
};

export default connect(({tags, benefitPrograms}) => ({
   selectTag: tags.selectTag,
   benefit_program: benefitPrograms.benefitProgram
}))(Filters);
