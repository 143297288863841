import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import {TextDefaul} from "../../common/utils/generic";

var images = require.context("../../common/images", true);

class Soon extends Component {
   constructor(props) {
      super(props);
      this.state = {};
   }

   componentDidMount() {

      this.setState({ business: process.env.REACT_APP_NAME_APP });
   }



   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   render() {

      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      return (
         <div className="col login-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container">
               <header
                  className="hello"
                  style={{ marginBottom: 25, marginTop: 25 }}
               >
                  <h1 className="text-app">{TextDefaul.textApp} </h1>
                  <img alt="logo" className="logo" src={logo} />
                  <p className="soon-text">Pronto...</p>
               </header>
            </div>
         </div>
      );
   }

   static contextTypes = {
      router: PropTypes.object
   };
}

export default connect()(Soon);
