import * as types from '../common/constants/actionTypes';

const initialState = {
    data: [],
    isFetching: false,
    isFetchingMore: false,
    error: null,
    count: 0,
    next: null,
    previous: null,
    poll: null,
    isFetchingPoll: false,
    isLoadingSend: false,
    errorSendPoll: false,
    successSendPoll: 0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case `${types.POLLS_FETCH}_PENDING`:
            return { ...state, isFetching: true };
        case `${types.POLLS_FETCH}_REJECTED`:
            return { ...state, isFetching: false, error: action.payload };
        case `${types.POLLS_FETCH}_FULFILLED`:
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.results,
                next: action.payload.data.next,
                count: action.payload.data.count
            };
        case `${types.POLL_DETAIL}_PENDING`:
            return { ...state, isFetchingPoll: true, poll: null };
        case `${types.POLL_DETAIL}_REJECTED`:
            return { ...state, isFetchingPoll: false, error: action.payload, poll: null };
        case `${types.POLL_DETAIL}_FULFILLED`:
            return {
                ...state,
                isFetchingPoll: false,
                poll: action.payload.data
            };
        case `${types.SEND_POLL}_PENDING`:
            return { ...state, isLoadingSend: true, errorSendPoll: false };
        case `${types.SEND_POLL}_REJECTED`:
            return { ...state, isLoadingSend: false, errorSendPoll: true, error: action.payload };
        case `${types.SEND_POLL}_FULFILLED`:
            return {
                ...state,
                isLoadingSend: false,
                errorSendPoll: false,
                successSendPoll :200
            };
        default:
            return state;

    }
}
