import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import ProgramPromotions from "./ProgramPromotions";
import {HOME} from "../../common/constants/actionTypes";
import {changeMenu} from "../Menu/actions";
import Banners from "../Banners/Banners";
import "./styles.scss"

class ContainerProgramPromotion extends Component {
   static contextTypes = {
      router: PropTypes.object
   };

   componentDidMount() {
      this.props.dispatch(changeMenu(HOME));
   }

   render() {
      return (
         <div className="main-body d-flex flex-wrap justify-content-end">
            <div className="col">
               <Banners {...this.props} />
               {/*{Whitelabel.with_level && <DetailLevel {...this.props} />}*/}

               <ProgramPromotions {...this.props} />
            </div>
         </div>
      );
   }
}

export default connect()(ContainerProgramPromotion);
