import React from "react";

const Product = props => {
  const product = props.product;
  const image = product.image;
  const image_reverse = product.image_reverse;
  const title = product.title;
  const link = product.link;
  let styleColor = {};



    if (product.is_active)
      return (
        <div className={`product row justify-content-center `}>
          <div className="col-lg-12">
            <div className="image">
              <img src={image_reverse} alt="product" />
            </div>
            <div className="title">{title}</div>
          </div>
        </div>
      );
    else
      return (
        <a
          target="_blank"
          href={link}
          rel="noopener noreferrer"
          className={`product row justify-content-center active`}
        >
          <div className="col-lg-12">
            <div className="image">
              <img style={styleColor} src={image} alt="product" />
            </div>
            <div className="title">{title}</div>
          </div>
        </a>
      );

};

export default Product;
