import HttpClient from "../../common/utils/httpClient";
import {PLACES_FETCH} from "../../common/constants/actionTypes";


export function getCompanyPlaces(companyId) {
   return dispatch =>
      HttpClient.get(
         dispatch,
         PLACES_FETCH,
         `v5/company/${companyId}/places/`
      );
}