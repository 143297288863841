import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {cleanPayment, getDetailProgramPromotion, getRelatedProgramPromotion, transferPromotion} from "./actions";
import Promotion from "../../common/components/widgets/Promotion";
import DetailProgramPromotion from "./DetailProgramPromotion";
import {changeMenu} from "../Menu/actions";
import {HOME} from "../../common/constants/actionTypes";
import "./styles.scss"
import NotificationSystem from "react-notification-system";
import {APP_TYPE_WHITELABEL} from "../../common/utils/generic";

const $ = require('jquery');

class ContainerDetailProgramPromotion extends Component {
   static contextTypes = {
      router: PropTypes.object
   };
   state = {
      uidProgramPromotion: "",
      is_transferable: false,
      email: "",
      isLoading: false,
      isValid: true
   };

   componentDidMount() {
      const {benefit_program, match} = this.props;
      localStorage.setItem("programUid", benefit_program.uid)

      const uidProgramPromotion = match.params.uidProgramPromotion;
      if (uidProgramPromotion !== undefined) {
         this.setState({uidProgramPromotion});
         this.props.dispatch(
            getRelatedProgramPromotion(benefit_program.uid, uidProgramPromotion)
         );
      } else {
         setTimeout(() => (document.location.href = `/`), 1000);
      }

      this.props.dispatch(changeMenu(HOME));
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      const {benefit_program} = prevProps;
      const {match, errorPayment, successPayment, program_promotion} = this.props;
      const uidProgramPromotion = match.params.uidProgramPromotion;
      if (uidProgramPromotion !== this.state.uidProgramPromotion) {
         this.setState({uidProgramPromotion});

         this.props.dispatch(
            getRelatedProgramPromotion(benefit_program.uid, uidProgramPromotion)
         );
      }
      if (successPayment != null) {
         if (this.notificationSystemRef !== undefined && this.notificationSystemRef !== null) {
            this.notificationSystemRef.addNotification({
               message: successPayment.user_message,
               level: "success"
            });
            this.props.dispatch(
               getDetailProgramPromotion(benefit_program.uid, uidProgramPromotion)
            )
         }
      }
      if (errorPayment != null) {
         if (this.notificationSystemRef !== undefined && this.notificationSystemRef !== null) {
            this.notificationSystemRef.addNotification({
               message: errorPayment.user_message,
               level: "error"
            });
            this.props.dispatch(
               cleanPayment()
            )
         }
      }
      if(program_promotion){
         if(program_promotion.is_transferable!==this.state.is_transferable){
            this.setState({is_transferable: program_promotion.is_transferable})
         }
      }

      const {
         successTransfer,
         errorTransfer
      } = this.props
      if(successTransfer!==null){
         this.notificationSystemRef.addNotification({
            message: "Se ha enviado el correo correctamente",
            level: "success"
         });
      }
      if(errorTransfer!==null && errorTransfer["non_field_errors"]){
         this.notificationSystemRef.addNotification({
            message: errorTransfer.non_field_errors[0],
            level: "error"
         });
      }
   }

   renderRelatedPromotions() {
      const {relatedPromotions} = this.props;
      let relatedPromotionsRender = [];
      for (let i = 0; i < relatedPromotions.length; i++) {
         if (relatedPromotions[i].uid !== this.state.uidProgramPromotion) {
            relatedPromotionsRender.push(relatedPromotions[i]);
            if (relatedPromotionsRender.length > 6) {
               break;
            }
         }
      }
      if (relatedPromotionsRender.length === 0) {
         return <p>No hay Ofertas Relacionadas</p>;
      } else {
         return relatedPromotionsRender.map((promotion, index) => (
            <Promotion
               key={`related${index}${promotion.uid}`}
               promotion={promotion}
            />
         ));
      }
   }


   handleEmail = event => this.setFormValues({email: event.target.value});


   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      if(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(propertiesToUpdate.email)){
         $("#email").removeClass("field-error is-invalid");
         this.setState({isValid: true})
      }else{
         $("#email").addClass("field-error is-invalid");
         this.setState({isValid: false})
      }
   };

   transferredPromotion = event => {

      event.preventDefault()

      const {
         email
      } = this.state;


      const isValid = this.state.isValid

      if (email.length===0) {
         this.addNotification("Por favor coloque un correo válido", "error");
         return;
      }

      if (!isValid) {
         this.addNotification("Por favor coloque un correo válido", "error");
         return;
      }

      this.setState({isLoading: true});
      if (!this.state.isLoading) {
         const {benefit_program} = this.props;
         let payload = {email: email, type: "TRANSFERRED_OUT"}

         try {
            const transferPromotionResponse = this.props.dispatch(
               transferPromotion(payload, benefit_program.uid, this.state.uidProgramPromotion)
            );

            let these = this;
            if (transferPromotionResponse != null) {
               let {status} = transferPromotionResponse.value;
               if (status === 200) {
                  these.addNotification(
                     "Se ha enviado el correo correctamente",
                     "error"
                  );
                  this.setState({isLoading: false});
               } else {
                  these.setState({isLoading: false});
                  if (process.env.REACT_APP_TYPE === APP_TYPE_WHITELABEL)
                     these.addNotification(
                        "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                        "error"
                     );
               }

            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            }
         } catch (error) {
            this.setState({isLoading: false});
         }
      }

   };


   render() {
      const {relatedPromotions, loadingRelatedPromotions, loadingPayment, isLoadingTransfer} = this.props;
      if (this.state.uidProgramPromotion === undefined || this.state.uidProgramPromotion === "") {
         return (
            <div className="content-no-items">
               <p>Espere un momento... </p>
            </div>
         );
      }
      return (
         <div>
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="container left-container">
               <DetailProgramPromotion
                  promotion_id={this.state.uidProgramPromotion}
               />
            </div>
            {
               this.state.is_transferable &&
               <div className="col-lg-12 ">
                  <div className="line"/>
                  <div className="main-body d-flex flex-wrap  no-row-margin">
                     <div className="col-lg-12 mt-2 mb-2">
                        <div className="d-flex benefit align-items-center flex-column flex-md-row">
                           <div className={"col-12 col-md-5 mr-4"}>
                              <p className="title">Compartir beneficios</p>
                              <p style={{fontSize: 15,color:"#212529"}}>Ingrese el correo electrónico de la persona a la que quiere transferir el beneficio</p>
                           </div>
                           <div className={"d-flex col-12 col-md-6 flex-column flex-md-row pt-3 pt-md-0 align-items-center"}>
                              <div className={"div-input-benefit"}>
                                 <input
                                    className={" mt-4 mt-md-0 input-benefit"}
                                    value={this.state.email}
                                    onChange={this.handleEmail}
                                    type="email"
                                    id={"email"}
                                    placeholder="Correo electrónico"
                                    required=""
                                    floating-validation=""
                                 />
                                 {
                                    !this.state.isValid &&
                                    <span id="email-error" className={"field-error-message"} style={{display:"block"}}>Ingresar un email correcto</span>
                                 }
                              </div>

                              <button className={this.state.is_transferable ? " mx-auto btn btn-lg btn-benefit mt-4  mt-md-0 " : "mx-auto btn btn-lg btn-benefit-disabled mt-4 mt-md-0"}
                                 onClick={this.transferredPromotion}>
                                 {isLoadingTransfer ? "Transfiriendo..." : "Compartir beneficio" }
                              </button>
                           </div>
                           </div>
                     </div>
                  </div>
               </div>
            }

            {relatedPromotions != null
            && loadingPayment === false &&
            relatedPromotions.length > 0 && (
               <div className="col-lg-12">
                  <div className="line"/>
                  <div className="main-body d-flex flex-wrap  no-row-margin">
                     <div className="col-lg-12 offers detail-offer">
                        <div className="justify-content-md-center description">
                           <p className="title">Productos Relacionados</p>
                        </div>
                        <div className="row">{this.renderRelatedPromotions()}</div>
                     </div>
                  </div>
               </div>
            )}
            {
               loadingRelatedPromotions &&
               loadingPayment === false &&
               (
                  <div className="row content-loader">
                     <p/>
                  </div>
               )}
         </div>
      );
   }
}

ContainerDetailProgramPromotion.defaultProps = {
   relatedPromotions: null,
   loadingRelatedPromotions: false,
   loadingPayment: false,
   benefit_program: null,
   errorPayment: null,
   successPayment: null,
   program_promotion: null,
};

export default connect(({programPromotions, benefitPrograms}) => ({
   benefit_program: benefitPrograms.benefitProgram,
   relatedPromotions: programPromotions.dataRelated,
   loadingRelatedPromotions: programPromotions.isLoadingRelated,
   errorPayment: programPromotions.errorPayment,
   loadingPayment: programPromotions.isLoadingPayment,
   successPayment: programPromotions.successPayment,
   program_promotion: programPromotions.program_promotion,
   errorTransfer: programPromotions.errorTransfer,
   successTransfer: programPromotions.successTransfer,
   isLoadingTransfer: programPromotions.isLoadingTransfer,

}))(ContainerDetailProgramPromotion);
