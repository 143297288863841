import React, {Component} from "react";
import {connect} from "react-redux";

import NotificationSystem from "react-notification-system";
import Validator from "../../common/utils/validator";
import {TextDefaul} from "../../common/utils/generic";
import Page from "../../common/components/Page";

import moment from "moment";
import {editUser, getUser} from "../Register/actions";

var images = require.context("../../common/images", true);

class CompleteData extends Component {
   constructor(props) {
      super(props);
      this.state = {
         document_number: "",
         document_type: "NATIONAL_ID",
         type_text: "Documento de Identidad",
         first_name: "",
         last_name: "",
         birthdate: "",
         gender: "",
         date: null,
         error: false,
         focused: null,
         isLoading: false,
         isEditing: false
      };
   }




   componentWillReceiveProps(nextProps, nextContext) {
      const { user } = nextProps;
      if (user != null && user.is_complete) {
         this.props.history.push("/");
      }
   }

   handleOption = (e, gender) => {
      this.setState({ gender });
   };

   componentDidMount() {
      document.title = process.env.REACT_APP_NAME_APP;
      this.validator = new Validator([
         {
            id: "document_number",
            ref: this.dniInput,
            rules: ["required"]
         },
         {
            id: "first_name",
            ref: this.firstNameInput,
            rules: ["required"]
         },
         {
            id: "last_name",
            ref: this.lastNameInput,
            rules: ["required"]
         },
         {
            id: "birthdate",
            ref: this.birthdateInput,
            rules: ["birthday"]
         }
      ]);
      this.props.dispatch(getUser({}));
   }

   handleDni = event =>
      this.setFormValues({ document_number: event.target.value });
   handleFirstName = event =>
      this.setFormValues({ first_name: event.target.value });
   handleLastName = event =>
      this.setFormValues({ last_name: event.target.value });
   handleBirthdate = event => {
      let band = event.target.value;
      band = band.replace(" ", "");
      if (band.length === 2) {
         if (this.state.birthdate.length === 3) {
            band = this.state.birthdate.slice(0, 1);
         } else {
            band = `${band}/`;
         }
      }

      if (band.length === 5) {
         if (this.state.birthdate.length === 6) {
            band = this.state.birthdate.slice(0, 4);
         } else {
            band = `${band}/`;
         }
      }

      if (band.length <= 10) this.setFormValues({ birthdate: band });
   };

   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.setState({ isEditing: true });
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({ message: msg, level });
   }

   handleSubmit = async event => {
      event.preventDefault();

      let {
         birthdate,
         gender,
         first_name,
         last_name,
         document_number,
         document_type
      } = this.state;
      let { isUserLoad, user } = this.props;

      let evaluate = {};

      if (!(user.first_name !== null && user.first_name !== "")) {
         evaluate.first_name = first_name;
      }
      if (!(user.last_name !== null && user.last_name !== "")) {
         evaluate.last_name = last_name;
      }
      if (
         !(user.birthdate !== null && user.birthdate !== "") &&
         birthdate !== ""
      ) {
         evaluate.birthdate = birthdate;
      }
      if (
         !(
            user.document_number != null &&
            user.document_number.indexOf("DOC-") !== 0
         )
      ) {
         evaluate.document_number = document_number;
         evaluate.document_type = document_type;
      }
      const isValid = this.validator.validate(evaluate);
      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }
      if (!(user.gender !== null && user.gender !== "") && gender !== "") {
         evaluate.gender = gender;
      }
      if (!isUserLoad) {
         this.setState({ isLoading: true });
         if (
            !(user.birthdate !== null && user.birthdate !== "") &&
            birthdate !== ""
         ) {
            let date = moment(birthdate, "DD/MM/YYYY").toDate();
            evaluate.birthdate = moment(date).format("YYYY-MM-DD");
         }
         this.setState({ isEditing: false });
         this.props.dispatch(editUser(evaluate));
      }
   };

   selectDocumentType = (e, name, type_id) => {
      e.preventDefault();
      this.setState({ type_text: name, type: type_id });
   };

   renderError = errorField => {
      let { isEditing } = this.state;
      if (
         isEditing === false &&
         errorField !== undefined &&
         errorField !== null
      )
         return (
            <span className="field-error-message" style={{ display: "block" }}>
               {errorField}
            </span>
         );
   };

   render() {

      let background = images(
         `./background_${process.env.REACT_APP_NAME_APP}.jpg`
      );
      let backgroundImage = {
         backgroundImage: "url(" + background + ")"
      };
      let { isUserLoad, user, error } = this.props;

      return (
         <Page
            id="completeData"
            title={TextDefaul.webTitle}
            description={TextDefaul.webDescription}
         >
            <div className="welcome">
               <div className="login register-rewards">
                  <div className="col login-form">
                     <NotificationSystem
                        ref={notificationSystem =>
                           (this.notificationSystemRef = notificationSystem)
                        }
                     />
                     <div className="grid-container">
                        <header
                           className="hello"
                           style={{ marginBottom: 25, marginTop: 25 }}
                        >
                           <h4 className="text-app">
                              Completar Información Personal
                           </h4>
                        </header>
                        <div
                           className={
                              isUserLoad === false
                                 ? "hidden"
                                 : "col-lg-12 content-loader"
                           }
                        >
                           <p>Cargando...</p>
                        </div>

                        <form
                           onSubmit={this.handleSubmit}
                           className={isUserLoad === true ? "hidden" : ""}
                        >
                           <label>
                              Para que podamos brindarte un mejor servicio,
                              queremos saber algo más de ti.
                           </label>{" "}
                           <label
                              className={
                                 user != null &&
                                 user.document_number !== null &&
                                 user.document_number !== "" &&
                                 user.document_number.indexOf("DOC-") !== 0
                                    ? "hidden"
                                    : ""
                              }
                           >
                              Tipo de documento(*)
                           </label>
                           <div
                              className={
                                 user != null &&
                                 user.document_number !== null &&
                                 user.document_number !== "" &&
                                 user.document_number.indexOf("DOC-") !== 0
                                    ? "hidden"
                                    : "search-types form-group col dropdown"
                              }
                           >
                              <div
                                 className="form-control"
                                 id="dropdownDocumentType"
                                 data-toggle="dropdown"
                                 aria-haspopup="true"
                                 aria-expanded="false"
                              >
                                 <span>{this.state.type_text}</span>
                              </div>
                              <div
                                 className="dropdown-menu"
                                 style={{
                                    width: "100%",
                                    overflowY: "scroll",
                                    maxHeight: 200
                                 }}
                                 aria-labelledby="dropdownDocumentType"
                              >
                                 <a
                                    href="/"
                                    className="dropdown-item dropdown-type select-type"
                                    onClick={e =>
                                       this.selectDocumentType(
                                          e,
                                          "Documento de Identidad",
                                          "NATIONAL_ID"
                                       )
                                    }
                                 >
                                    Documento de Identidad
                                 </a>
                                 <a
                                    href="/"
                                    className="dropdown-item dropdown-type select-type"
                                    onClick={e =>
                                       this.selectDocumentType(
                                          e,
                                          "Pasaporte",
                                          "PASSPORT"
                                       )
                                    }
                                 >
                                    Pasaporte
                                 </a>
                                 <a
                                    href="/"
                                    className="dropdown-item dropdown-type select-type"
                                    onClick={e =>
                                       this.selectDocumentType(
                                          e,
                                          "Carnet de Extranjería",
                                          "FOREIGNER_ID"
                                       )
                                    }
                                 >
                                    Carnet de Extranjería
                                 </a>
                                 <a
                                    href="/"
                                    className="dropdown-item dropdown-type select-type"
                                    onClick={e =>
                                       this.selectDocumentType(
                                          e,
                                          "Ruc",
                                          "TAX_ID"
                                       )
                                    }
                                 >
                                    Ruc
                                 </a>
                                 <a
                                    href="/"
                                    className="dropdown-item dropdown-type select-type"
                                    onClick={e =>
                                       this.selectDocumentType(
                                          e,
                                          "Otro",
                                          "OTHER"
                                       )
                                    }
                                 >
                                    Otro
                                 </a>
                              </div>
                           </div>
                           <label
                              className={
                                 user != null &&
                                 user.document_number !== null &&
                                 user.document_number !== "" &&
                                 user.document_number.indexOf("DOC-") !== 0
                                    ? "hidden"
                                    : ""
                              }
                           >
                              Número de documento(*)
                           </label>
                           <div
                              className={
                                 user != null &&
                                 user.document_number !== null &&
                                 user.document_number !== "" &&
                                 user.document_number.indexOf("DOC-") !== 0
                                    ? "hidden"
                                    : ""
                              }
                           >
                              <div
                                 className="form-group"
                                 ref={input => {
                                    if (input) {
                                       this.dniInput = input.querySelector(
                                          "input"
                                       );
                                    }
                                 }}
                              >
                                 <input
                                    value={this.state.document_number}
                                    className="form-control"
                                    onChange={this.handleDni}
                                    type="text"
                                    pattern="[a-zA-Z0-9-]"
                                    placeholder="Documento de identidad"
                                    required=""
                                    floating-validation=""
                                 />
                              </div>
                              {error != null &&
                                 this.renderError(error.document_number)}
                           </div>
                           <label
                              className={
                                 user != null &&
                                 user.first_name !== null &&
                                 user.first_name !== ""
                                    ? "hidden"
                                    : ""
                              }
                           >
                              Nombres
                           </label>
                           <div
                              className={
                                 user != null &&
                                 user.first_name !== null &&
                                 user.first_name !== ""
                                    ? "hidden"
                                    : ""
                              }
                           >
                              <div
                                 className="form-group"
                                 ref={input => {
                                    if (input) {
                                       this.firstNameInput = input.querySelector(
                                          "input"
                                       );
                                    }
                                 }}
                              >
                                 <input
                                    value={this.state.first_name}
                                    onChange={this.handleFirstName}
                                    type="text"
                                    pattern="[a-zA-Z]"
                                    placeholder="Nombres"
                                    required=""
                                    className={"form-control"}
                                    floating-validation=""
                                 />
                              </div>
                              {error != null &&
                                 this.renderError(error.first_name)}
                           </div>
                           <label
                              className={
                                 user != null &&
                                 user.last_name !== null &&
                                 user.last_name !== ""
                                    ? "hidden"
                                    : ""
                              }
                           >
                              Apellidos
                           </label>
                           <div
                              className={
                                 user != null &&
                                 user.last_name !== null &&
                                 user.last_name !== ""
                                    ? "hidden"
                                    : ""
                              }
                           >
                              <div
                                 className="form-group"
                                 ref={input => {
                                    if (input) {
                                       this.lastNameInput = input.querySelector(
                                          "input"
                                       );
                                    }
                                 }}
                              >
                                 <input
                                    value={this.state.last_name}
                                    onChange={this.handleLastName}
                                    type="text"
                                    pattern="[a-zA-Z]"
                                    placeholder="Apellidos"
                                    required=""
                                    className="form-control"
                                    floating-validation=""
                                 />
                              </div>
                              {error != null &&
                                 this.renderError(error.last_name)}
                           </div>
                           <label
                              className={
                                 user != null &&
                                 user.birthdate !== null &&
                                 user.birthdate !== ""
                                    ? "hidden"
                                    : ""
                              }
                           >
                              Fecha de Nacimiento (DD/MM/YYYY).
                           </label>
                           <div
                              className={
                                 user != null &&
                                 user.birthdate !== null &&
                                 user.birthdate !== ""
                                    ? "hidden"
                                    : ""
                              }
                           >
                              <div
                                 className="form-group"
                                 ref={input => {
                                    if (input) {
                                       this.birthdateInput = input.querySelector(
                                          "input"
                                       );
                                    }
                                 }}
                              >
                                 <input
                                    value={this.state.birthdate}
                                    onChange={this.handleBirthdate}
                                    type="text"
                                    placeholder="DD/MM/YYYY"
                                    required=""
                                    className="form-control"
                                 />
                              </div>
                              {error != null &&
                                 this.renderError(error.birthdate)}
                           </div>
                           <label
                              className={
                                 user != null &&
                                 user.gender !== null &&
                                 user.gender !== ""
                                    ? "hidden"
                                    : ""
                              }
                           >
                              Sexo
                           </label>
                           <div
                              className={
                                 user != null &&
                                 user.gender !== null &&
                                 user.gender !== ""
                                    ? "hidden"
                                    : ""
                              }
                           >
                              <div className="form-check">
                                 <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    value="f"
                                    onChange={e => this.handleOption(e, "f")}
                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="f"
                                 >
                                    Femenino
                                 </label>
                              </div>
                              <div className="form-check">
                                 <input
                                    className="form-check-input"
                                    type="radio"
                                    name="gender"
                                    value="m"
                                    onChange={e => this.handleOption(e, "m")}
                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="m"
                                 >
                                    Masculino
                                 </label>
                              </div>
                              {error != null && this.renderError(error.gender)}
                           </div>
                           <div style={{ marginBottom: 10, marginTop: 20 }}>
                              <button
                                 type="submit"
                                 className="btn btn-whitelabel btn-lg btn-block"
                                 onClick={this.handleSubmit}
                              >
                                 {isUserLoad && "Cargando..."}
                                 {!isUserLoad && "Completar Datos"}
                              </button>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
               <div className="publicity" style={backgroundImage} />
            </div>
         </Page>
      );
   }
}

CompleteData.defaultProps = {
   user: null,
   isUserLoad: false,
   error: null
};

export default connect(({ users }) => ({
   isUserLoad: users.isFetching,
   user: users.user,
   error: users.error
}))(CompleteData);
