import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import InfiniteScroll from "react-infinite-scroller";

import {getBannersByCategory, getMoreProgramPromotions, getProgramPromotions} from "./actions";
import Promotion from "../../common/components/widgets/Promotion";
import {cleanBanners} from "../Menu/actions";
import firebase from "firebase"
import LoadingIndicator from "../../common/components/LoadingIndicator";

class ProgramPromotions extends Component {
   static contextTypes = {
      router: PropTypes.object
   };

   state = {
      perPage: 10,
      currentPage: 1,
      searchText: "",
      tagSelectUid: null,
      idCategory: "",
      show_all_section: false
   };

   componentDidMount() {
      const {selectTag, match, tags, benefit_program} = this.props;
      const idCategory = match.params.uid;
      const path = match.path;
      let firebaseConf = firebase.remoteConfig()
      let all_categories = this.state.show_all_section
      if(firebaseConf){
         all_categories = firebaseConf.getBoolean("app_menu_show_all_sections");
         this.setState({show_all_section: all_categories})
      }
      if (path === "/benefits") {
      } else {
         if (
            idCategory === undefined ||
            idCategory == null ||
            idCategory === "all"
         ) {

            if (all_categories === false) {
               if (selectTag !== null) {
                  this.props.history.push({pathname: `/category/${selectTag.uid}`});
               } else {
                  if (tags.length > 0) {
                     this.props.history.push({pathname: `/category/${tags[0].uid}`});
                  }
               }
            } else {

               this.props.dispatch(
                  getProgramPromotions(
                     benefit_program.uid,
                     null,
                     null,
                     null,
                     null,
                     null,
                     null,
                     true
                  )
               );
               this.props.dispatch(cleanBanners());
            }
         } else {
            this.props.dispatch(
               getProgramPromotions(
                  benefit_program.uid,
                  idCategory,
                  null,
                  null,
                  null,
                  null,
                  null,
                  true
               )
            );
            this.props.dispatch(
               getBannersByCategory(benefit_program.uid, idCategory)
            );
            this.setState({idCategory: idCategory});
         }
      }
   }



   componentDidUpdate(prevProps, prevState, snapshot) {
      const {selectTag, match, tags, benefit_program} = this.props;
      const idCategory = match.params.uid;
      const path = match.path;

      if (path === "/benefits") {
      } else {
         if (
            idCategory === undefined ||
            idCategory == null ||
            idCategory === "all"
         ) {
            if (this.state.show_all_section === false) {
               if (selectTag !== null) {
                  this.props.history.push({pathname: `/category/${selectTag.uid}`});
               } else {
                  if (tags.length > 0) {
                     this.props.history.push({pathname: `/category/${tags[0].uid}`});
                  }
               }
            } else {
               if (this.state.idCategory !== "") {
                  this.props.dispatch(
                     getProgramPromotions(
                        benefit_program.uid,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        true
                     )
                  );
                  this.setState({idCategory: ""});
               }
            }
         } else {
            if (idCategory !== this.state.idCategory) {
               this.props.dispatch(
                  getProgramPromotions(
                     benefit_program.uid,
                     idCategory,
                     null,
                     null,
                     null,
                     null,
                     null,
                     true
                  )
               );
               this.props.dispatch(
                  getBannersByCategory(benefit_program.uid, idCategory)
               );

               this.setState({idCategory: idCategory});
            }
         }
      }
   }

   renderEntities() {
      const {data} = this.props;
      let array = data ? data : []
      let hash = {};
      array = array.filter(function(current) {
         let exists = !hash[current.uid];
         hash[current.uid] = true;
         return exists;
      });

      if (array == null) {
         return <div className="content-no-items"/>;
      }
      if (array.length === 0) {
         return (
            <div className="content-no-items">
               <p>Ups! , no pudimos encontrar beneficios</p>
            </div>
         );
      } else {
         return array.map((programPromotion, index) => (
            <Promotion key={index} promotion={programPromotion}/>
         ));
      }
   }

   loadMore = () => {
      const {next, moreLoader} = this.props;
      if (!moreLoader && next != null) {
         this.props.dispatch(getMoreProgramPromotions(next));
      }
   };

   render() {
      const {loader, next} = this.props;
      let more = next != null;
      if (loader) {
         return (
            <div className="row content-loader">
               <div className={"col"}>
                  <div className={"col-12"}>
                     <LoadingIndicator/>
                  </div>
                  <div className={"col-12"}>
                     <p>Cargando</p>
                  </div>
               </div>
            </div>

         );
      }
      var loaderMore = (
         <div
            key={0}
            className="col-lg-12 content-loader"
            style={{display: "inline-flex"}}
         >
            <LoadingIndicator/>
         </div>
      );
      return (
         <div className={"mt-3"} style={{position: "relative"}}>
            <InfiniteScroll
               className="entities-section row"
               pageStart={0}
               loadMore={this.loadMore.bind(this)}
               hasMore={more}
               loader={loaderMore}
            >
               {this.renderEntities()}
            </InfiniteScroll>
         </div>
      );
   }
}

ProgramPromotions.defaultProps = {
   addNotification: () => {
   },
   from_search: false,
   selectTag: null,
   data: null,
   moreLoader: false,
   loader: false
};

export default connect(({programPromotions, tags, benefitPrograms}) => ({
   selectTag: tags.selectTag,
   tags: tags.data,
   data: programPromotions.data,
   count: programPromotions.count,
   loader: programPromotions.isFetching,
   benefit_program: benefitPrograms.benefitProgram,
   moreLoader: programPromotions.isFetchingMore,
   next: programPromotions.next
}))(ProgramPromotions);
