import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getPoll, sendPoll} from "./actions";
import NotificationSystem from "react-notification-system";
import {POLLS} from "../../common/constants/actionTypes";
import {changeMenu} from "../Menu/actions";
import CheckIcon from "../Register/icons/CheckIcon";

class Poll extends Component {
   static contextTypes = {
      router: PropTypes.object
   };
   state = {
      answer: []
   };

   componentDidMount() {
      const {match} = this.props;
      const uidPoll = match.params.uidPoll;
      const {benefit_program} = this.props;
      if (uidPoll !== undefined && uidPoll != null) {
         this.props.dispatch(getPoll(benefit_program.uid, uidPoll));
      }
      this.props.dispatch(changeMenu(POLLS));
   }


   componentWillReceiveProps(nextProps, nextContext) {
      const {poll} = nextProps;
      if (poll != null && !poll.is_answered) {
         let answer = [];
         poll.questions.forEach((question, index) => {
            answer[index] = {question: question.uid, marked: []};
         });
         this.setState({answer});
      }
   }

   handleCheckbox = (e, index) => {
      let answer = [...this.state.answer];
      let question = answer[index];
      if (e.target.checked) {
         question.marked.push(e.target.value);
      } else {
         question.marked.forEach((value, i) => {
            if (value === e.target.value) {
               question.marked.splice(i, 1);
            }
         });
      }
      answer[index] = question;
      this.setState({answer});
   };

   handleOption = (e, index) => {
      let answer = [...this.state.answer];
      let question = answer[index];
      if (question.marked.length > 0) {
         question.marked[0] = e.target.value;
      } else {
         question.marked.push(e.target.value);
      }
      answer[index] = question;
      this.setState({answer});
   };

   handleSubmit = async event => {
      event.preventDefault();
      let validate = true;
      let answer = [...this.state.answer];
      answer.forEach(question => {
         if (question.marked.length === 0) {
            validate = false;
         }
      });
      if (validate) {
         let uid = this.props.poll.uid;
         let program = this.props.benefit_program.uid;
         const responsePoll = await this.props.dispatch(
            sendPoll(program, uid, {answer: answer})
         );
         console.log(answer)
         let these = this;
         if (responsePoll !== undefined && responsePoll != null) {

            these.setState({isLoading: false});
            let {status} = responsePoll.value;
            if (status === 200 || status === 201) {
               these.props.dispatch(getPoll(program, uid));
               this.props.history.push({pathname: `/polls/`});
            } else {
               let level = "error";
               these.notificationSystemRef.addNotification({
                  message:
                     "Ha sucedido un error, revisar nuevamente el formulario",
                  level
               });
            }
         } else {
            this.setState({isLoading: false});
            let level = "error";
            this.notificationSystemRef.addNotification({
               message: "Ha sucedido un error, revisar nuevamente el formulario",
               level
            });
         }
      } else {
         let level = "error";
         this.notificationSystemRef.addNotification({
            message: "Debe contestar todas las preguntas",
            level
         });
      }
   };

   renderCheck = (index, question) => {
      return question.options.map(option => (
         <div key={option.uid} className="form-check">

            <label style={{transform: "scale(1.5)",  marginRight: 15}}>
               <input
                  className="form-check-input"
                  type="checkbox"
                  value={option.uid}
                  id={option.uid}
                  onChange={e => this.handleCheckbox(e, index)}
               />
               <span style={{marginLeft: 7}}>
                        <CheckIcon/>
               </span>
            </label>
            <label className="form-check-label" htmlFor={option.uid}>
               {option.text}
            </label>
         </div>
      ));
   };

   renderOptions = (index, question) => {
      return question.options.map(option => (
         <div key={option.uid} className="form-check ml-4">
            <input
               className="form-check-input"
               style={{transform: "scale(1.2)",  marginRight:25}}
               type="radio"
               name={question.uid}
               id={option.uid}
               value={option.uid}
               onChange={e => this.handleOption(e, index)}
            />
            <label className="form-check-label ml-3" htmlFor={option.uid}>
               {option.text}
            </label>
         </div>
      ));
   };

   renderUI = () => {
      const {poll} = this.props;
      return poll.questions.map((question, index) => (
         <div key={index} className="form-group">
            <label htmlFor="" className="option">
               {question.text}
            </label>
            {question.is_multiple_options === true
               ? this.renderCheck(index, question)
               : this.renderOptions(index, question)}
         </div>
      ));
   };

   render() {
      const {loadingPoll, isLoadingSend, poll} = this.props;
      if (loadingPoll || isLoadingSend)
         return (
            <div className="row content-loader">
               <p>Cargando...</p>
            </div>
         );
      if (poll == null) {
         return (
            <div className="content-no-items">
               <p>Ups! , no pudimos encontrar la encuesta </p>
            </div>
         );
      } else {
         if (!poll.is_answered) {
            return (
               <div className="container left-container">
                  <div className="row">
                     <NotificationSystem
                        ref={notificationSystem =>
                           (this.notificationSystemRef = notificationSystem)
                        }
                     />
                     <div className="col col-lg-8 col-md-8 dinamic-form">
                        <p>
                           <Link to={`/polls`}>
                              {" "}
                              {`<`} Volver a Listado de Encuestas
                           </Link>
                        </p>
                        <h4 className="title-section bold ">{poll.title}</h4>
                        <p className="subtitle-left">{poll.message}</p>
                        <form onSubmit={this.handleSubmit}>
                           {this.renderUI()}
                           <div className="content-btn">
                              <button type="submit" className="btn btn-whitelabel btn-lg">
                                 Enviar
                              </button>
                           </div>
                        </form>
                     </div>
                  </div>
               </div>
            );
         } else {
            if (poll.is_answered) {
               return (
                  <div className="content-no-items">
                     <p>
                        Gracias, usted ha ha respondido satisfactoriamente esta encuesta
                     </p>
                     <p>
                        <Link to={`/polls`} className="btn btn-whitelabel-auxiliar">
                           Volver a encuestas
                        </Link>
                     </p>
                  </div>
               );
            } else {
               return (
                  <div className="content-no-items">
                     <p>Ups! , esta encuesta no tiene datos</p>
                     <p>
                        <Link to={`/polls`} className="btn btn-whitelabel-auxiliar">
                           Volver a encuestas
                        </Link>
                     </p>
                  </div>
               );
            }
         }
      }
   }
}

Poll.defaultProps = {
   poll: null,
   loadingPoll: false,
   benefit_program: null,
   isLoadingSend: false,
   errorSend: false,
   successSend: 0
};

export default connect(({polls, benefitPrograms}) => ({
   benefit_program: benefitPrograms.benefitProgram,
   poll: polls.poll,
   loadingPoll: polls.isFetchingPoll,
   isLoadingSend: polls.isLoadingSend,
   errorSend: polls.errorSendPoll,
   successSend: polls.successSendPoll
}))(Poll);
