import * as types from "../common/constants/actionTypes";


const initialState = {
    data: null,
    isFetching: false,
    isFetchingMore: false,
    error: null,
    count: 0,
    next: null,
    previous: null,
    entity: null,
    isFetchingEntity: false
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case `${types.FECH_ENTITIES}_PENDING`:
            return {...state, isFetching: true, data: [], next: null};
        case `${types.FECH_ENTITIES}_REJECTED`:
            return {...state, isFetching: false, error: action.payload};
        case `${types.FECH_ENTITIES}_FULFILLED`:
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.results,
                next: action.payload.data.next,
                count: action.payload.data.count
            };
        case `${types.DETAIL_ENTITY}_PENDING`:
            return {...state, isFetchingEntity: true, entity: null};
        case `${types.DETAIL_ENTITY}_REJECTED`:
            return {...state, isFetchingEntity: false};
        case `${types.DETAIL_ENTITY}_FULFILLED`:
            if(action.payload&&action.payload.data){
                return {
                    ...state,
                    isFetchingEntity: false,
                    entity: action.payload.data
                };
            }else{
                return {
                    ...state,
                    isFetchingEntity: false,
                    entity: null
                };
            }

        case `${types.FECH_MORE_ENTITIES}_PENDING`:
            return {...state, isFetchingMore: true};
        case `${types.FECH_MORE_ENTITIES}_REJECTED`:
            return {...state, isFetchingMore: false, error: action.payload};
        case `${types.FECH_MORE_ENTITIES}_FULFILLED`:
            let data = state.data
            for (let i = 0; i < action.payload.data.results.length; i++) {
                data.push(action.payload.data.results[i])
            }
            return {
                ...state,
                isFetchingMore: false,
                data: data,
                next: action.payload.data.next,
                count: action.payload.data.count
            };

        case `${types.CLEAN_ENTITIES}`:
            return {
                ...state,
                data: null,
                isFetching: false,
                isFetchingMore: false,
                error: null,
                count: 0,
                next: null,
                previous: null,
                entity: null,
                isFetchingEntity: false
            };
        default:
            return state;

    }
}
