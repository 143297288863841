import React, {Component} from 'react';
import {connect} from 'react-redux';
import TermsConditionsGeneral from "../../common/components/Custom/TermsConditionsGeneral";
import TermsConditionsMapfre from "../../common/components/Custom/TermsConditionsMapfre";
import TermsConditionsPetroperu from "../../common/components/Custom/TermsConditionsPetroperu";
import TermsConditionsMiBanco from "../../common/components/Custom/TermsConditionsMiBanco";
import {getApp, getAppByApiKey} from "../Bederr/actions";
const $ = require('jquery');

class TermsAndConditions extends Component {
    constructor(props) {
        super(props);
        this.state = {
           terms_and_conditions: ""
        };
    }
    componentDidMount() {
       $('html').removeClass("notOverflow");
       if(process.env.REACT_APP_NAME_APP==="bancoelcomercio" || process.env.REACT_APP_NAME_APP==="banbif"){
          this.getUid(process.env.REACT_APP_APY_KEY)
       }
    }

    getUid = async uid => {
       const appFieldsResponse = await this.props.dispatch(
          getAppByApiKey(uid)
       );
       if (
          appFieldsResponse !== undefined &&
          appFieldsResponse != null
       ) {

          let {status, data} = appFieldsResponse.value;
          if (status === 200 || status === 201) {
             this.getTerms(data[0].uid)
          } else {
             if (status === 400) {
                this.setState({terms_and_conditions: ""})
             }
          }

       }
    }

   getTerms = async uid => {
      const appFieldsResponse = await this.props.dispatch(
         getApp(uid)
      );
      if (
         appFieldsResponse !== undefined &&
         appFieldsResponse != null
      ) {
         let {status, data} = appFieldsResponse.value;
         if (status === 200 || status === 201) {
            const {site} = data;
            if(site && site.terms_and_conditions){
               if(site.terms_and_conditions !== this.state.terms_and_conditions){
                  this.setState({terms_and_conditions: site.terms_and_conditions})
                  $("#terms-box").html(`<div class="col-lg-12 mt-4 mb-4">${site.terms_and_conditions}</div>`)
               }
            }
         } else {
            if (status === 400) {
               this.setState({terms_and_conditions: ""})
            }
         }

      }
   }

   render() {
        switch (process.env.REACT_APP_NAME_APP) {
            case "mapfre":
              return <TermsConditionsMapfre/>;
            case "petroperu":
                return <TermsConditionsPetroperu/>;
            case "mibanco":
                return <TermsConditionsMiBanco/>;
           case "bancoelcomercio":
              return (
                 <div className="container tyc">
                    <div className="row" id={"terms-box"}>
                    </div>
                 </div>
              );
           case "banbif":
              return (
                 <div className="container tyc">
                    <div className="row" id={"terms-box"}>
                    </div>
                 </div>
              );
            default:
                return <TermsConditionsGeneral/>
        }

    }
}

export default connect()(TermsAndConditions);
