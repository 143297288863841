import HttpClient from "../../common/utils/httpClient";
import {CLEAN_RAFFLE, DETAIL_RAFFLE, SEND_RAFFLE, SHOW_MODAL_RAFFLE} from "../../common/constants/actionTypes";


export function getDetailRaffle(programID, offerId, raffleId) {
   return dispatch =>
      HttpClient.get(
         dispatch,
         DETAIL_RAFFLE,
         `v5/me/programs/${programID}/promotions/${offerId}/raffles/${raffleId}/`
      );
}

export function sendCodeRaffle(data, programID, offerId, raffleId) {
   return dispatch =>
      HttpClient.post(
         data,
         dispatch,
         SEND_RAFFLE,
         `v5/me/programs/${programID}/promotions/${offerId}/raffles/${raffleId}/register/`
      );
}

export function cleanRaffles() {
   return {
      type: CLEAN_RAFFLE
   };
}
export function showModal(showModal, image) {
   return {
      type: SHOW_MODAL_RAFFLE,
      showModal,
      image
   };
}