import {CHANGE_ROOT} from './constants';
import HttpClient from "../../common/utils/httpClient";
import {BENEFIT_PROGRAM_V5, BENEFIT_PROGRAMS, SET_BENEFIT_PROGRAM, GET_APP_FIELDS} from "../../common/constants/actionTypes";

export const GET_APP_URL = 'v7/apps/{uid_app}';
export const GET_APP_BY_API_KEY_URL = 'v7/apps/?api_key={api_key}';


export function changeRootMenu(menu) {
  return {
    menu,
    type: CHANGE_ROOT,
  };
}

export function getBenefitProgramV5(uid) {
   return dispatch => HttpClient.get(dispatch, BENEFIT_PROGRAM_V5, `v5/me/programs/${uid}/`);
}

export function getBenefitProgramsV5() {
   return dispatch => HttpClient.get(dispatch, BENEFIT_PROGRAMS, `v5/me/programs/`);
}

export function setProgram(benefit) {
   return {
      type: SET_BENEFIT_PROGRAM,
      benefit,
   };
}

export function getApp(uid) {
   return (dispatch) => HttpClient.get(
      dispatch,
      GET_APP_FIELDS,
      `${GET_APP_URL.replace("{uid_app}", uid)}`
   );
}

export function getAppByApiKey(uid) {
   return (dispatch) => HttpClient.get(
      dispatch,
      GET_APP_FIELDS,
      `${GET_APP_BY_API_KEY_URL.replace("{api_key}", uid)}`
   );
}