import React, {Component} from "react";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";

import {APP_TYPE_WHITELABEL} from "../../common/utils/generic";
import Validator from "../../common/utils/validator";
import "react-dates/initialize";

import {Link} from "react-router-dom";
import {setToken} from "../../reducersapp/auth";
import {getCompany} from "../Login/actions";
import {
   createUserDataBoc,
   getClientDataBoc,
   getUserDataBoc,
   registerEmailUserDataBoc,
   registerUserRewards
} from "./actions";
import CheckIcon from "./icons/CheckIcon";
import {getApp} from "../../reducersapp/app";

var images = require.context("../../common/images", true);

class RegisterRewards extends Component {
   constructor(props) {
      super(props);
      this.state = {
         business: "",

         phone: "",
         email: "",
         password: "",
         confirmPassword:"",
         securePassword: false,


         focused: null,
         accepted_terms_cond: false,
         data_processing_accepted : false,
         accepted_politics_privacity: false,
         isLoading: false,
         captcha_token: null,

         visibilityFirst: false,
         visibilitySecond: false,

         name: ""
      };
   }

   componentDidMount() {
      this.setState({business: process.env.REACT_APP_NAME_APP});
      this.validator = new Validator([
         {
            id: "phone",
            ref: this.phoneInput,
            rules: ["required", "telephone"]
         },
         {
            id: "email",
            ref: this.emailInput,
            rules: ["required", "email"]
         },
         {
            id: "password",
            ref: this.passwordInput,
            rules: ["required"]
         },
         {
            id: "confirmPassword",
            ref: this.confirmPasswordInput,
            rules: ["required"]
         }
      ]);

      const verifyCallback = (response) => {
         this.setState({captcha_token: response});
      };

      const expiredCallback = () => {
         this.setState({captcha_token: null});
      };

      const onloadCallback = _ => {
         window.grecaptcha.ready(() => {
            window.grecaptcha.render('recaptcha', {
               'sitekey': process.env.REACT_APP_RECAPTCHA_SITE_KEY,
               'callback': verifyCallback,
               'expired-callback': expiredCallback
            });
         })
      };

      if (process.env.REACT_APP_NAME_APP === "fidelio") {
         const script = document.createElement("script");
         script.src = "https://www.google.com/recaptcha/api.js?render=explicit&hl=es";
         script.addEventListener("load", onloadCallback);
         document.body.appendChild(script);
      }

      if(localStorage.getItem("name")){
         this.setState({name: localStorage.getItem("name")})
         localStorage.removeItem("name")
      }
   }

   handlePhonePaste = event =>
   this.setFormValues({phone: event.target.value});
   handlePhone = event =>{
      if(event.target.value.length <= 9){
         this.setFormValues({phone: event.target.value});
      }
   }
   handlePassword = event =>{
      this.setFormValues({password: event.target.value});
      let mayus =  new RegExp("^(?=.*[A-Z])");
      let numbers =  new RegExp("^(?=.*[0-9])");
      let lower =  new RegExp("^(?=.*[a-z])");
      let len =  new RegExp("^(?=.{8,})");
      if(mayus.test(event.target.value) && numbers.test(event.target.value)
         && lower.test(event.target.value) && len.test(event.target.value)){
         this.setState({securePassword: true})
      }else{
         this.setState({securePassword: false})
      }
   }
   handleConfirmPassword = event =>
      this.setFormValues({confirmPassword: event.target.value});
   handleEmail = event => this.setFormValues({email: event.target.value});


   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }


   searchBussiness = async subdomain => {
      if(process.env.REACT_APP_NAME_APP==="bancoelcomercio"){

         try {
            const companyResponse = await this.props.dispatch(
               //getCompany(subdomain)
               getApp(process.env.REACT_APP_APY_KEY)
            );

            let these = this;
            if (companyResponse != null) {

               let {status, data} = companyResponse.value;
               if (status === 200) {
                  const {logo, name, color} = data;
                  let name_hhrr = process.env.REACT_APP_NAME_APP;
                  let api_key = process.env.REACT_APP_APY_KEY;
                  window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
                  window.localStorage.setItem(`logo${name_hhrr}`, logo);
                  window.localStorage.setItem(`color${name_hhrr}`, color);
                  window.localStorage.setItem(`name${name_hhrr}`, name);
                  const {
                     phone,
                     email,
                     password,
                     captcha_token,
                     data_processing_accepted
                  } = these.state;

                  these.registerUser(
                     phone,
                     email,
                     password,
                     captcha_token,
                     data_processing_accepted
                  );
               } else {
                  these.setState({isLoading: false});
                  if (process.env.REACT_APP_TYPE === APP_TYPE_WHITELABEL)
                     these.addNotification(
                        "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                        "error"
                     );
               }

            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            }
         } catch (error) {
            this.setState({isLoading: false});
            //this.addNotification('Compañia no encontrada', 'error');
         }
      }else{
         try {
            const companyResponse = await this.props.dispatch(
               getCompany(subdomain)
            );

            let these = this;
            if (companyResponse != null) {

               let {status, data} = companyResponse.value;
               if (status === 200) {
                  const {logo, name, color} = data;
                  let name_hhrr = process.env.REACT_APP_NAME_APP;
                  let api_key = process.env.REACT_APP_APY_KEY;
                  window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
                  window.localStorage.setItem(`logo${name_hhrr}`, logo);
                  window.localStorage.setItem(`color${name_hhrr}`, color);
                  window.localStorage.setItem(`name${name_hhrr}`, name);
                  const {
                     phone,
                     email,
                     password,
                     captcha_token,
                     data_processing_accepted
                  } = these.state;

                  these.registerUser(
                     phone,
                     email,
                     password,
                     captcha_token,
                     data_processing_accepted
                  );
               } else {
                  these.setState({isLoading: false});
                  if (process.env.REACT_APP_TYPE === APP_TYPE_WHITELABEL)
                     these.addNotification(
                        "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                        "error"
                     );
               }

            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            }
         } catch (error) {
            this.setState({isLoading: false});
            //this.addNotification('Compañia no encontrada', 'error');
         }
      }
   };

   registerUser = async (
   phone,
   email,
   password,
   captcha_token,
   data_processing_accepted
   ) => {
   const data = {
      "mobile_number": phone,
      email,
      password,
      captcha_token,
      data_processing_accepted
   };
      let these = this;
      let document_number = ""

      if(process.env.REACT_APP_NAME_APP==="bancoelcomercio"){
         let value = this.props.history.location.search
         let array = value.substring(1,value.length).split("&")
         array.map((p, index)=>{
            if(index===0){
               document_number = p.split("=")[1]
            }
            return true
         })
         data.document_number = document_number
         data.document_type = "NATIONAL_ID"
         data.first_name = this.state.name
         this.props.setFirstName(this.state.name);
      }

      console.log(data)
      const logInResponse = await this.props.dispatch(
         registerUserRewards(data)
      );

      if (
         logInResponse !== undefined &&
         logInResponse != null
      ) {

         let {status, data} = logInResponse.value;
         if (status === 200 || status === 201) {
            const {token} = data;
            let name = process.env.REACT_APP_NAME_APP;
            if(process.env.REACT_APP_NAME_APP === "bancoelcomercio"){
               window.localStorage.setItem(`token${name}2`, token);
               these.props.dispatch(setToken(token));

               const responseUser = await this.props.dispatch(getUserDataBoc());
               const responseCreateUser = await this.props.dispatch(createUserDataBoc());
               const responseRegisterEmailUser = await this.props.dispatch(registerEmailUserDataBoc());

               const responseClient = await this.props.dispatch(getClientDataBoc());
               let {statusCreate} = responseCreateUser.value;
               console.log(statusCreate)
               if (statusCreate === 200 || statusCreate === 201) {
                  const responseClient = await this.props.dispatch(getClientDataBoc());
                  if(responseClient!== undefined &&
                     responseClient != null
                  ){

                  }
               }
               if (
                  responseUser !== undefined &&
                  responseUser != null
               ) {
                  let {data:{verified_mobile_number, mobile_number}} = responseUser.value;
                  these.setState({isLoading: false});
                  if(!verified_mobile_number){
                     these.props.setSendFirstSMS(true)
                     these.props.setMobileNumber(mobile_number)
                     these.props.setVerifyMobile(true)
                  }else{
                     these.props.setWelcome(true)
                  }
               }
               if(responseCreateUser!== undefined &&
                  responseCreateUser != null &&
                  responseRegisterEmailUser!== undefined &&
                  responseRegisterEmailUser != null &&
                  responseClient!== undefined &&
                  responseClient != null
               ){

               }

            }else{
               these.setState({isLoading: false});
               window.localStorage.setItem(`token${name}`, token);
               these.props.dispatch(setToken(token));
               setTimeout(() => {
                  (document.location.href = '/');
                  return true;
               }, 1000);
            }
         } else {
            if (status === 400) {
               let errorStr = "";
               if (data.email !== undefined && data.email != null)
                  errorStr = data.email.join();
               if (errorStr !== "") {
                  these.setState({isLoading: false});
                  these.addNotification(errorStr, "error");
               } else {
                  these.setState({isLoading: false});
                  these.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
               }

               if (data.mobile_number !== undefined && data.mobile_number != null)
                  errorStr = data.mobile_number.join();
               if (errorStr !== "") {
                  these.setState({isLoading: false});
                  these.addNotification(errorStr, "error");
               } else {
                  these.setState({isLoading: false});
                  these.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
               }
            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            }
         }

      } else {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }

   };


   handleSubmit = async event => {
      event.preventDefault();

      const {
         phone,
         business,
         email,
         password,
         confirmPassword,
         captcha_token
      } = this.state;
      const isValid = this.validator.validate({
         phone,
         business,
         email,
         password,
      });

      if (process.env.REACT_APP_NAME_APP === "fidelio" && captcha_token == null) {
         this.addNotification("Haz click en No soy un robot", "error");
         return;
      }

      if(password!==confirmPassword){
         this.addNotification("Verifique que las claves sean iguales, intentelo nuevamente", "error");
         return;
      }

      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }


      if (!this.state.accepted_terms_cond) {
         this.addNotification("Debe aceptar los terminos y condiciones", "error");
         return;
      }

      if (!this.state.accepted_politics_privacity) {
         this.addNotification("Debe aceptar las políticas de tratamiendo de datos personales", "error");
         return;
      }


      if(this.state.securePassword){
         this.setState({isLoading: true});
      }else{
         this.addNotification(
            "Ups! La contraseña no cumple con la seguridad requerida, intentelo nuevamente",
            "error"
         );
      }

      if (!this.state.isLoading) {
         if(process.env.REACT_APP_NAME_APP!=="bancoelcomercio"){
            this.searchBussiness(business);
         }else{
            if(this.state.securePassword){
               let name_hhrr = process.env.REACT_APP_NAME_APP;
               let api_key = process.env.REACT_APP_APY_KEY;
               window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
               this.registerUser(
                  this.state.phone,
                  this.state.email,
                  this.state.password,
                  this.state.captcha_token,
                  this.state.data_processing_accepted );
            }
         }
      }
   };

   handleInputChange = event => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      this.setState({[name]: value});
   };

   completeData() {
      const {
         phone,
         email,
         password,
         confirmPassword,
         accepted_terms_cond,
         accepted_politics_privacity,
      } = this.state;
      if(phone.length===0){
         return false
      }
      if(email.length===0){
         return false
      }
      if(password.length===0){
         return false
      }
      if(confirmPassword.length===0){
         return false
      }
      if (!accepted_terms_cond) {
         return false
      }
      if (!accepted_politics_privacity) {
         return false
      }

      return true
   }

   render() {
      let visibilty = images(
         `./visibility.png`
      );
      let noVisibilty = images(
         `./no-visibility.png`
      );

      return (
         <div className="col login-form register-form mt-0">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container mt-0">
               {
                  process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                  <h2 className="main-title" id={"main-title"}>
                     Registrar</h2>
               }
               <form onSubmit={this.handleSubmit} className="form-container">
                  <p style={{fontWeight: 300}}>Hola {this.state.name}, por favor ingresa y confirma tus datos.</p>

                  <label>Datos del titular:</label>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.phoneInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           value={this.state.phone}
                           className="form-control"
                           onChange={this.handlePhone}
                           type="text"
                           pattern="[a-zA-Z0-9-]"
                           placeholder="Celular"
                           required=""
                           floating-validation=""
                        />
                     </div>
                  </div>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.emailInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.email}
                           onChange={this.handleEmail}
                           type="email"
                           placeholder="Correo electrónico"
                           required=""
                           floating-validation=""
                        />
                     </div>
                  </div>

                  <label className={"mt-4"}>Crear clave nueva:</label>
                  <div className={"legend"}>
                     <p>-Ingresa tu clave (mínimo 8 caracteres)</p>
                     <p>-Al menos una letra mayúscula</p>
                     <p>-Al menos una letra minúscula</p>
                     <p>-Al menos un número</p>
                  </div>
                  <div  style={{position: "relative"}}>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.passwordInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.password}
                           onChange={this.handlePassword}
                           type={this.state.visibilityFirst ? "text" : "password"}
                           placeholder="Nueva clave"
                           required=""
                           floating-validation=""
                        />
                     </div>
                     {
                        process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                        <img
                           alt="visibilty"
                           className="visibilty"
                           style={{cursor:"pointer"}}
                           src={!this.state.visibilityFirst ? noVisibilty : visibilty }
                           onClick={e=>this.setState({visibilityFirst: !this.state.visibilityFirst})}
                        />
                     }
                  </div>
                  <div style={{position: "relative"}}>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.confirmPasswordInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.confirmPassword}
                           onChange={this.handleConfirmPassword}
                           type={this.state.visibilitySecond ? "text" : "password"}
                           placeholder="Confirmar clave"
                           required=""
                           floating-validation=""
                        />
                     </div>
                     {
                        process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                        <img
                           alt="visibilty"
                           className="visibilty"
                           style={{cursor:"pointer"}}
                           src={!this.state.visibilitySecond ? noVisibilty : visibilty }
                           onClick={e=>this.setState({visibilitySecond: !this.state.visibilitySecond})}
                        />
                     }
                  </div>

                  <div className={"d-flex align-items-start checkbox mt-4"}>
                     <label style={{transform: "scale(2)", marginRight: 15}}>
                        <input
                           name="accepted_terms_cond"
                           type="checkbox"
                           onChange={this.handleInputChange}
                           checked={this.state.accepted_terms_cond}
                           className="red"
                           style={{marginTop: 10}}
                        />
                        <span style={{marginLeft: 7}}>
                               {
                                  this.state.accepted_terms_cond &&
                                  <CheckIcon/>
                               }
                           </span>
                     </label>
                     <div style={{marginTop: 4}}>
                        Aceptar los
                        <Link to={`/terms`} className={"link-terms ml-1"} target="_blank">
                           <span>términos y condiciones.</span>
                        </Link>
                        <br/>
                     </div>
                  </div>
                  <div className={"d-flex align-items-start checkbox mt-4"}>

                     <label style={{transform: "scale(2)",  marginRight: 15}}>
                        <input
                           name="accepted_politics_privacity"
                           type="checkbox"
                           onChange={this.handleInputChange}
                           checked={this.state.accepted_politics_privacity}
                           className="red"
                           style={{marginTop: 10}}
                        />
                        <span style={{marginLeft: 7}}>
                           {
                              this.state.accepted_politics_privacity &&
                              <CheckIcon/>
                           }
                           </span>
                     </label>
                     <div style={{marginTop: 4}}>
                        He leído y acepto la
                        <Link to={`/politics`} className={"link-terms ml-1"} target="_blank">
                           <span>Política de tratamiento de datos personales</span>
                        </Link>
                        <br/>
                     </div>
                  </div>
                  {
                     process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                     <div className={"d-flex align-items-start checkbox"}>
                        <label style={{transform: "scale(2)",  marginRight: 15}}>
                           <input
                              name="data_processing_accepted "
                              type="checkbox"
                              onChange={this.handleInputChange}
                              checked={this.state.data_processing_accepted }
                              className="red"
                              style={{marginTop: 10}}
                           />
                           <span style={{marginLeft: 7}}>
                              {
                                 this.state.data_processing_accepted  &&
                                 <CheckIcon/>
                              }
                           </span>
                        </label>
                        <div style={{marginTop: 4}}>
                           Acepto el
                           <Link to={`/secondaryPurposes`} className={"link-terms ml-1"} target="_blank">
                              <span>Tratamiento para finalidades adicionales</span>
                           </Link>
                           <br/>
                        </div>
                     </div>
                  }

                  {process.env.REACT_APP_NAME_APP === "fidelio" ? <div id="recaptcha"/> : null}

                  <div style={{marginBottom: 10, marginTop: 10}}>
                     <button
                        type="submit"
                        className={ process.env.REACT_APP_NAME_APP === "bancoelcomercio"  ? (this.completeData() ? "btn btn-whitelabel btn-lg btn-block"  : "btn btn-whitelabel-disabled btn-lg btn-block ") : "btn btn-whitelabel btn-lg btn-block" }
                        onClick={this.handleSubmit}
                     >
                        {this.state.isLoading ? "Cargando..." : "Confirmar datos" }
                     </button>
                  </div>
                  <div className="links link-login">
                     <Link to={"/login"}>

                        <span>Retornar a Login</span>
                     </Link>
                  </div>
               </form>
            </div>
         </div>
      );
   }
}

export default connect()(RegisterRewards);
