import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import {APP_TYPE_WHITELABEL, TextDefaul} from "../../common/utils/generic";
import arrow_down from "../../common/images/down_arrow.png";
import Validator from "../../common/utils/validator";
import {getCompany, login, verifyUser, verifyUserBancoElComercio} from "./actions";
import {setToken} from "../../reducersapp/auth";
import {getApp} from "../../reducersapp/app";

var images = require.context("../../common/images", true);

class LoginWhite extends Component {
   constructor(props) {
      super(props);
      this.state = {
         dni: "",
         business: "",
         type: "NATIONAL_ID",
         type_text: "Documento de Identidad",
         isLoading: false
      };
   }

   componentDidMount() {
      if(process.env.REACT_APP_NAME_APP==="bancoelcomercio"){
         this.setState({business: "bancodecomercio"});
      }else{
         this.setState({business: process.env.REACT_APP_NAME_APP});
      }
      this.validator = new Validator([
         {
            id: "dni",
            ref: this.dniInput,
            rules: ["required"]
         }
      ]);
      if (process.env.REACT_APP_TYPE === APP_TYPE_WHITELABEL) {
         let name_hhrr = process.env.REACT_APP_NAME_APP;
         let api_key = process.env.REACT_APP_APY_KEY;
         window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
      }

   }


   handleDni = event => {
      let value  = event.target.value
      let regex = new RegExp("^[- a-zA-Z ]+$");
      if (!regex.test(value.charAt(value.length-1))) {
         if(process.env.REACT_APP_NAME_APP==="bancoelcomercio"){
            if(event.target.value.length <= 8){
               this.setFormValues({dni: value})
            }
         }else{
            this.setFormValues({dni: value})
         }
      }
   };

   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      if(this.notificationSystemRef){
         this.notificationSystemRef.addNotification({
            message: msg,
            level
         });
      }
   }

   searchBusiness = async (dni, subdomain) => {
      let localstorageValid = true;
      try {
         window.localStorage.setItem("bederr", "bederr");
         if (window.localStorage.getItem("bederr") != null) {
            window.localStorage.removeItem("bederr");
         } else {
            localstorageValid = false;
            this.addNotification(
               "Usted tiene deshabilitada la función de guardar datos en su navegador, por favor habilitarla para este sitio",
               "error"
            );
         }
      } catch (error) {
         localstorageValid = false;
         this.addNotification(
            "Usted tiene deshabilitada la función de guardar datos en su navegador, por favor habilitarla para este sitio",
            "error"
         );
      }
      if (localstorageValid) {
         if(process.env.REACT_APP_NAME_APP==="bancoelcomercio"){
            try {
               const companyResponse = await this.props.dispatch(
                  //getCompany(subdomain)
                  getApp(process.env.REACT_APP_APY_KEY)
               );
               let these = this;
               if (companyResponse != null) {

                  console.log("---",companyResponse)
                  let {status, data} = companyResponse.value;
                  if (status === 200) {
                     const {logo, name} = data;
                     let api_key = process.env.REACT_APP_APY_KEY;
                     let name_hhrr = process.env.REACT_APP_NAME_APP;
                     window.localStorage.setItem(
                        `apikey${name_hhrr}`,
                        api_key
                     );
                     window.localStorage.setItem(`logo${name_hhrr}`, logo);
                     window.localStorage.setItem(`name${name_hhrr}`, name);
                     if(process.env.REACT_APP_NAME_APP==="bancoelcomercio"){
                        these.searchUserBancoElComercio(dni)
                     }else{
                        these.searchUser(dni);
                     }
                  } else {
                     these.setState({isLoading: false});
                  }

               } else {
                  these.setState({isLoading: false});
                  these.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
               }
            } catch (error) {
               this.setState({isLoading: false});
               if (process.env.REACT_APP_TYPE !== APP_TYPE_WHITELABEL)
                  this.addNotification("Compañia no encontrada", "error");
            }
         }else{
            try {
               const companyResponse = await this.props.dispatch(
                  getCompany(subdomain)
               );
               let these = this;
               if (companyResponse != null) {

                  console.log("---",companyResponse)
                  let {status, data} = companyResponse.value;
                  if (status === 200) {
                     const {logo, name} = data;
                     let api_key = process.env.REACT_APP_APY_KEY;
                     let name_hhrr = process.env.REACT_APP_NAME_APP;
                     window.localStorage.setItem(
                        `apikey${name_hhrr}`,
                        api_key
                     );
                     window.localStorage.setItem(`logo${name_hhrr}`, logo);
                     window.localStorage.setItem(`name${name_hhrr}`, name);
                     if(process.env.REACT_APP_NAME_APP==="bancoelcomercio"){
                        these.searchUserBancoElComercio(dni)
                     }else{
                        these.searchUser(dni);
                     }
                  } else {
                     these.setState({isLoading: false});
                  }

               } else {
                  these.setState({isLoading: false});
                  these.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
               }
            } catch (error) {
               this.setState({isLoading: false});
               if (process.env.REACT_APP_TYPE !== APP_TYPE_WHITELABEL)
                  this.addNotification("Compañia no encontrada", "error");
            }
         }
      }
   };

   loginUser = async dni => {
      try {
         let document_type = this.state.type;
         const logInResponse = await this.props.dispatch(
            login({"document_number": dni, document_type})
         );
         let these = this;
         if (
            logInResponse !== undefined &&
            logInResponse != null
         ) {

                  let {status, data} = logInResponse.value;
                  if (status === 200) {
                     const {token} = data;
                     let name = process.env.REACT_APP_NAME_APP;
                     if(process.env.REACT_APP_NAME_APP === "bancoelcomercio"){
                        these.props.setWelcome(true)
                        setTimeout(() => {
                         window.localStorage.setItem(`token${name}2`, token);
                        (document.location.href = '/');
                           if( window.localStorage.getItem(`token${name}`)!==null || window.localStorage.getItem(`token${name}`)!=="null"){
                              window.localStorage.setItem(`token${name}`, token);
                              window.localStorage.removeItem(`token${name}2`);
                              these.props.dispatch(setToken(token));
                           }
                           return true;
                        }, 7000);
                     }else{
                        window.localStorage.setItem(`token${name}`, token);
                        these.props.dispatch(setToken(token));
                        setTimeout(() => {
                           (document.location.href = '/');
                           return true;
                        }, 1000);
                     }
                  } else {
                     these.setState({isLoading: false});
                     /*
                     if (status === 400) {
                        these.redirectToLogin(dni);
                     } else {
                        these.addNotification(
                           "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                           "error"
                        );
                     }
                      */
                     these.addNotification(
                        "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                        "error"
                     );
                  }

         } else {
            this.setState({isLoading: false});
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };

   redirectToLogin(dni) {
      this.props.history.push(
         `/register?dni=${dni}&document_type=${this.state.type}`
      );
   }

   selectDocumentType = (e, name, type_id) => {
      e.preventDefault();
      this.setState({type_text: name, type: type_id});
   };

   searchUser = async dni => {
      try {
         let document_type = this.state.type;
         let responseVerify = await this.props.dispatch(
            verifyUser({"document_number": dni, document_type})
         );
         let these = this;
         if (responseVerify != null) {
            const { status, data }= responseVerify.value;
            if(status === 200){
               if(data.is_registered){
                  these.loginUser(dni);
               } else if (data.can_register) {
                  these.redirectToLogin(dni);
               } else {
                  this.addNotification(
                     "No se ha podido ingresar con las credenciales proveidas",
                     "error"
                  );
                  responseVerify = null
               }
            }
         } else {
            this.setState({isLoading: false});
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };

   searchUserBancoElComercio = async dni => {
      let api_key = process.env.REACT_APP_APY_KEY;
      let name_hhrr = process.env.REACT_APP_NAME_APP;
      window.localStorage.setItem(
         `apikey${name_hhrr}`,
         api_key
      );

      try {
         let document_type = this.state.type;
         const responseVerify = await this.props.dispatch(
            verifyUserBancoElComercio({ document_number: dni, document_type})
         );
         let these = this;
         if (responseVerify != null) {
                  const { status, data }= responseVerify.value;
                  const {can_register, is_registered} = data;
                  if (status === 200) {
                     console.log(data)
                     if(process.env.REACT_APP_NAME_APP === "bancoelcomercio" && !can_register && is_registered){
                        this.props.setStep(2)
                        this.props.history.push(
                           `/login`
                        );
                     }
                     if(process.env.REACT_APP_NAME_APP === "bancoelcomercio" && can_register && is_registered){
                        this.props.setStep(2)
                        this.props.history.push(
                           `/login`
                        );
                     }
                     if(process.env.REACT_APP_NAME_APP === "bancoelcomercio" && can_register && !is_registered){
                        this.props.setStep(3)
                        this.props.history.push(
                           `/login`
                        );
                     }
                     if(process.env.REACT_APP_NAME_APP === "bancoelcomercio" && !can_register && !is_registered){
                        these.setState({isLoading: false});
                        these.addNotification(
                           "Cliente NO puede ser afiliado",
                           "error"
                        );
                     }
                  } else {
                     these.setState({isLoading: false});
                     these.addNotification(
                        "No se ha podido loguear en este momento",
                        "error"
                     );
                  }

         } else {
            this.setState({isLoading: false});
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };

   handleSubmit = async event => {
      event.preventDefault();

      const {dni, business} = this.state;
      const isValid = this.validator.validate({dni, business});

      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }

      this.setState({isLoading: true});
      if (!this.state.isLoading) {
         this.searchBusiness(dni, business);
         if(process.env.REACT_APP_NAME_APP==="bancoelcomercio"){
            this.props.setDocumentNumber(dni);
         }
      }


   };

   render() {
      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      return (
         <div className="col login-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container">
               <header
                  className="hello"
                  style={{marginBottom: 25, marginTop: 25}}
               >
                  <h1 className="text-app">{TextDefaul.textApp} </h1>
                  {
                     process.env.REACT_APP_NAME_APP !== "bancoelcomercio" &&
                     <img
                        alt="logo"
                        className="logo"
                        src={logo}
                     />
                  }
                  {TextDefaul.loginExtraText !== "" && (
                     <p className={"loginExtraText"}>{TextDefaul.loginExtraText}</p>
                  )}
                  {
                    (process.env.REACT_APP_NAME_APP==="lapositiva" || process.env.REACT_APP_NAME_APP==="banbif") &&
                     <p className="logo-p">Ingresa tus datos</p>
                  }
               </header>
               <form onSubmit={this.handleSubmit}>
                  <div className="search-types form-group col dropdown">
                     <div
                        className="form-control"
                        id="dropdownDocumentType"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <span>{this.state.type_text}</span>
                        <img src={arrow_down} alt="" className={"arrow-down"}/>
                        {/*<FontAwesomeIcon className="chevron" icon="chevron-down"/>*/}
                     </div>
                     <div
                        className="dropdown-menu"
                        style={{
                           width: "100%",
                           overflowY: "scroll",
                           maxHeight: 200
                        }}
                        aria-labelledby="dropdownDocumentType"
                     >
                        <a
                           className="dropdown-item dropdown-type select-type"
                           href="/"
                           onClick={e =>
                              this.selectDocumentType(
                                 e,
                                 "Documento de Identidad",
                                 "NATIONAL_ID"
                              )
                           }
                        >
                           Documento de Identidad
                        </a>
                        <a
                           className="dropdown-item dropdown-type select-type"
                           href="/"
                           onClick={e =>
                              this.selectDocumentType(
                                 e,
                                 "Pasaporte",
                                 "PASSPORT"
                              )
                           }
                        >
                           Pasaporte
                        </a>
                        <a
                           className="dropdown-item dropdown-type select-type"
                           href="/"
                           onClick={e =>
                              this.selectDocumentType(
                                 e,
                                 "Carnet de Extranjería",
                                 "FOREIGNER_ID"
                              )
                           }
                        >
                           Carnet de Extranjería
                        </a>
                        <a
                           className="dropdown-item dropdown-type select-type"
                           href="/"
                           onClick={e =>
                              this.selectDocumentType(e, "Ruc", "TAX_ID")
                           }
                        >
                           Ruc
                        </a>
                        <a
                           className="dropdown-item dropdown-type select-type"
                           href="/"
                           onClick={e =>
                              this.selectDocumentType(e, "Otro", "OTHER")
                           }
                        >
                           Otro
                        </a>
                     </div>
                  </div>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.dniInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control form-dni"}
                           value={this.state.dni}
                           onChange={this.handleDni}
                           type="text"
                           pattern="[a-zA-Z0-9-]"
                           placeholder="Número de documento"
                           required=""
                        />
                     </div>
                  </div>

                  <div style={{marginBottom: 10, marginTop: 10}}>
                     <button
                        type="submit"
                        className={ process.env.REACT_APP_NAME_APP === "bancoelcomercio"  ? (this.state.dni !== "" ? "btn btn-whitelabel btn-lg btn-block"  : "btn btn-whitelabel-disabled btn-lg btn-block ") : "btn btn-whitelabel btn-lg btn-block" }
                        onClick={this.handleSubmit}
                     >
                        {this.state.isLoading ? "Cargando..." :
                           process.env.REACT_APP_NAME_APP === "bancoelcomercio" ? "Continuar" :"Ingresar" }
                     </button>
                  </div>

               </form>
            </div>
         </div>
      );
   }

   static contextTypes = {
      router: PropTypes.object
   };
}

export default connect()(LoginWhite);
