import React from "react";

const SVG = ({className = ""}) => (
   <svg
      height="512"
      viewBox="0 0 512 512"
      className={`svg-icon ${className || ""}`}
      width="512"
      xmlns="http://www.w3.org/2000/svg">
      <path d="m256 0c-49.626 0-90 40.374-90 90v32h-45c-7.308 0-13.6 5.378-14.796 12.534-.302 1.812-30.204 183.297-30.204 362.466 0 8.284 6.716 15 15 15h330c8.284 0 15-6.716 15-15 0-179.169-29.902-360.654-30.204-362.466-1.196-7.155-7.477-12.534-14.796-12.534h-45v-32c0-49.626-40.374-90-90-90zm-60 90c0-33.084 26.916-60 60-60s60 26.916 60 60v32h-120zm182.167 62c6.418 42.304 26.433 185.222 27.762 330h-299.858c1.33-144.71 21.345-287.684 27.763-330h32.166v45c0 8.284 6.716 15 15 15s15-6.716 15-15v-45h120v45c0 8.284 6.716 15 15 15s15-6.716 15-15v-45z"/>
      <path d="m331 272h-150c-8.284 0-15 6.716-15 15v90c0 8.284 6.716 15 15 15h150c8.284 0 15-6.716 15-15v-90c0-8.284-6.716-15-15-15zm-15 90h-120v-60h120z"/>
   </svg>
);

export default SVG;

