import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Banner from "../../common/components/widgets/Banner";
import Slider from "react-slick";

class Banners extends Component {
   static contextTypes = {
      router: PropTypes.object
   };

   renderBanners() {
      const { banners, benefit_program } = this.props;

      return banners.map((banner, index) => (
         <Banner
            type="banner"
            key={banner.uid}
            banner={banner}
            bp={benefit_program.uid}
            onClick={e =>
               this.showBannerPromotion(
                  e,
                  banner.promotion_uid,
                  banner.poll_uid,
                  banner.mobile_menu
               )
            }
         />
      ));
   }
   showBannerPromotion = (e, uidPromotion, uidPoll, menu) => {
      e.preventDefault();
      if (menu !== undefined && menu != null && menu !== "") {
         switch (menu) {
            case "NOTIFICATIONS":
               this.props.history.push({ pathname: "/notifications" });
               break;
            case "POLLS":
               this.props.history.push({ pathname: "/polls" });
               break;
            case "PROFILE":
               this.props.history.push({ pathname: "/profile" });
               break;
            default:
               break;
         }
      } else {
         if (
            uidPromotion !== undefined &&
            uidPromotion != null &&
            uidPromotion !== ""
         ) {
            this.props.history.push({
               pathname: "/offers/" + uidPromotion + ""
            });
         } else {
            if (uidPoll !== undefined && uidPoll != null && uidPoll !== "") {
               this.props.history.push({ pathname: "/polls/" + uidPoll + "" });
            }
         }
      }
   };
   render() {
      const { banners, banners_loading } = this.props;

      if (banners.length > 0 && !banners_loading) {
         let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplaySpeed: 5000,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1
         };
         return <Slider {...settings}>{this.renderBanners()}</Slider>;
      } else {
         return <div />;
      }
   }
}

Banners.defaultProps = {
   addNotification: () => {},
   benefit_program: null,
   banners_loading: false,
   banners: []
};

export default connect(({ banners, benefitPrograms }) => ({
   benefit_program: benefitPrograms.benefitProgram,
   banners: banners.data,
   banners_loading: banners.isFetching
}))(Banners);
