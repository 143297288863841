import * as types from "../common/constants/actionTypes";
import HttpClient from "../common/utils/httpClient";


const initialState = {
  data: [],
  isFetching: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${types.COUNTRIES_FETCH}_PENDING`:
      return { ...state, isFetching: true };
    case `${types.COUNTRIES_FETCH}_REJECTED`:
      return { ...state, isFetching: false, error: action.payload };
    case `${types.COUNTRIES_FETCH}_FULFILLED`:
      let newData = [];

       if (action.payload.data.length > 0) {
          action.payload.data.forEach(element => {
             newData.push({ uid: element.uid, name: element.name, country: element.country.name});
          });
       }

      return Object.assign({}, state, {
        isFetching: false,
        data: newData
      });
    default:
      return state;
  }
}

export const getCountries = () => dispatch =>
  HttpClient.get(dispatch, types.COUNTRIES_FETCH, "v7/areas/");
