import React, {Component} from "react";
import PropTypes from "prop-types";

export default class FacebookLogin extends Component {

   componentDidMount() {
      document.addEventListener("FBObjectReady", this.initializeFacebookLogin);
   }

   componentWillUnmount() {
      document.removeEventListener(
         "FBObjectReady",
         this.initializeFacebookLogin
      );
   }

   /**
    * Init FB object and check Facebook Login status
    */
   initializeFacebookLogin = () => {
      this.FB = window.FB;
      this.checkLoginStatus();
   };

   /**
    * Check login status
    */
   checkLoginStatus = () => {
      this.FB.getLoginStatus(this.facebookLoginHandler);
   };

   /**
    * Check login status and call login api is user is not logged in
    */
   facebookLogin = () => {
      if (!window.FB) return;
      if (!this.FB) {
         this.FB = window.FB;
         if (this.FB) {
            this.checkLoginStatus();
         }
      }

      this.FB.getLoginStatus(response => {
         if (response.status === "connected") {
            this.facebookLoginHandler(response);
         } else {
            this.FB.login(this.facebookLoginHandler, {
               scope: "email,public_profile"
            });
         }
      });
   };

   /**
    * Handle login response
    */
   facebookLoginHandler = response => {
      if (response.status === "connected") {
         this.props.onLogin(true, response);
      } else {
         this.props.onLogin(false);
      }
   };

   render() {
      let { children } = this.props;
      return <div onClick={this.facebookLogin}>{children}</div>;
   }
}
FacebookLogin.propTypes = {
   onLogin: PropTypes.func.isRequired
};
