import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import OfferDetail from "../../common/components/widgets/OfferDetail";
import {getDetailNotification} from "./actions";

import NotificationPromo from "../../common/components/widgets/NotificationPromo";
import NotificationVideo from "../../common/components/widgets/NotificationVideo";

import {NOTIFICATIONS} from "../../common/constants/actionTypes";
import {changeMenu} from "../Menu/actions";

class DetailNotification extends Component {
  static contextTypes = {
    router: PropTypes.object
  };



  componentDidMount() {
     const { match } = this.props;
     const uidNotification = match.params.uidNotification;
     if (uidNotification !== undefined && uidNotification !== null) {
        this.props.dispatch(getDetailNotification(uidNotification));
     }
     this.props.dispatch(changeMenu(NOTIFICATIONS));
  }

   componentWillReceiveProps(nextProps, nextContext) {
    if (
      nextProps.match.params.uidNotification !==
      this.props.match.params.uidNotification
    ) {
      this.props.dispatch(
        getDetailNotification(nextProps.match.params.uidNotification)
      );
    }
  }

  render() {
    const { loadingNotification, notification, business } = this.props;
    if (loadingNotification)
      return (
        <div className="row content-loader">
          <p>Cargando</p>
        </div>
      );
    if (notification === null) {
      return (
        <div className="container">
          <div className="content-no-items">
            <p>Ups! , no pudimos encontrar la notificación </p>
          </div>
        </div>
      );
    } else {
      if (
        notification.corporate_offer != null &&
        (notification.type === "BOOSTED_OFFER" ||
          notification.type === "NEAR_OFFER")
      ) {
        return (
          <div className="container left-container">
            <OfferDetail offer={notification.corporate_offer} />
          </div>
        );
      } else if (
        notification.promo != null &&
        (notification.type === "NEW_PROMO" ||
          notification.type === "NEW_LINK" ||
          notification.type === "NEW_LEAD")
      ) {
        return (
          <div className="container left-container">
            <NotificationPromo promo={notification.promo}/>
          </div>
        );
      } else if (
        notification.video != null &&
        notification.type === "NEW_VIDEO"
      ) {
        return (
          <div className="container">
            <NotificationVideo video={notification.video} />
          </div>
        );
      } else if (
         notification !== null
      ) {
         return (
            <div className="container left-container">
               <NotificationPromo promo={notification.object} business={business}/>
            </div>
         );
      }
      else {
        return (
          <div className="container left-container">
            <div className="content-no-items">
              <p>Ups! , no pudimos encontrar la notificación </p>
            </div>
          </div>
        );
      }
    }
  }
}

DetailNotification.defaultProps = {
  notification: null,
  loadingNotification: false,
   business: null
};

export default connect(({ offers, notifications, app }) => ({
  notification: notifications.notification,
  loadingNotification: notifications.isFechingNotification,
   business: app.app
}))(DetailNotification);
