import React, {Component} from "react";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";

import {APP_TYPE_WHITELABEL} from "../../common/utils/generic";
import Validator from "../../common/utils/validator";
import "react-dates/initialize";

import {Link} from "react-router-dom";
import moment from "moment";
import {setToken} from "../../reducersapp/auth";
import {getCompany} from "../Login/actions";
import {registerUserRewards} from "./actions";
import arrow_down from "../../common/images/downarrow.png";
import CheckIcon from "./icons/CheckIcon";
import countriesPhone from "../../common/components/JSON/countriesPhone.json";
import Select from "react-select";


//47112741
class RegisterRewards extends Component {
   constructor(props) {
      super(props);
      this.state = {
         document_number: "",
         business: "",
         first_name: "",
         last_name: "",
         email: "",
         mobile_number: "",
         code: "+51",
         birthdate: "",
         gender: "",
         password: "",
         date: null,
         focused: null,
         accepted_terms_cond: false,
         data_processing_accepted : false,
         document_type: "NATIONAL_ID",
         type_text: "Documento de Identidad",
         isLoading: false,
         captcha_token: null
      };
   }

   componentDidMount() {
      this.setState({business: process.env.REACT_APP_NAME_APP});
      this.validator = new Validator([
         {
            id: "document_number",
            ref: this.dniInput,
            rules: ["required", "document"]
         },
         {
            id: "first_name",
            ref: this.firstNameInput,
            rules: ["required"]
         },
         {
            id: "last_name",
            ref: this.lastNameInput,
            rules: ["required"]
         },
         {
            id: "email",
            ref: this.emailInput,
            rules: ["required", "email"]
         },
         {
            id: "mobileNumber",
            ref: this.mobileNumberInput,
            rules: ["telephone"]
         },
         {
            id: "password",
            ref: this.passwordInput,
            rules: ["required"]
         },
         {
            id: "birthdate",
            ref: this.birthdateInput,
            rules: ["birthday"]
         }
      ]);

      const verifyCallback = (response) => {
         this.setState({captcha_token: response});
      };

      const expiredCallback = () => {
         this.setState({captcha_token: null});
      };

      const onloadCallback = _ => {
         window.grecaptcha.ready(() => {
            window.grecaptcha.render('recaptcha', {
               'sitekey': process.env.REACT_APP_RECAPTCHA_SITE_KEY,
               'callback': verifyCallback,
               'expired-callback': expiredCallback
            });
         })
      };

      if (process.env.REACT_APP_NAME_APP === "fidelio") {
         const script = document.createElement("script");
         script.src = "https://www.google.com/recaptcha/api.js?render=explicit&hl=es";
         script.addEventListener("load", onloadCallback);
         document.body.appendChild(script);
      }
   }

   handleOption = (e, gender) => {
      this.setState({gender});
   };


   handleDniPaste = event =>
      this.setFormValues({document_number: event.target.value});
   handleDni = event =>
      this.setFormValues({document_number: event.target.value});
   handlePassword = event =>
      this.setFormValues({password: event.target.value});
   handleFirstName = event =>
      this.setFormValues({first_name: event.target.value});
   handleLastName = event =>
      this.setFormValues({last_name: event.target.value});
   handleEmail = event => this.setFormValues({email: event.target.value});
   handleMobileNumber = event => this.setFormValues({mobile_number: event.target.value});

   handleDateChange(date) {
      this.setState({date});
   }

   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   searchBussiness = async subdomain => {
      try {
         const companyResponse = await this.props.dispatch(
            getCompany(subdomain)
         );

         let these = this;
         if (companyResponse != null) {

            let {status, data} = companyResponse.value;
            if (status === 200) {
               const {logo, name, color} = data;
               let name_hhrr = process.env.REACT_APP_NAME_APP;
               let api_key = process.env.REACT_APP_APY_KEY;
               window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
               window.localStorage.setItem(`logo${name_hhrr}`, logo);
               window.localStorage.setItem(`color${name_hhrr}`, color);
               window.localStorage.setItem(`name${name_hhrr}`, name);
               const {
                  document_number,
                  document_type,
                  gender,
                  first_name,
                  last_name,
                  email,
                  mobile_number,
                  code,
                  password,
                  birthdate,
                  captcha_token,
                  data_processing_accepted
               } = these.state;
               //const birthdate = moment(date).format("YYYY-MM-DD");
               these.registerUser(
                  document_type,
                  document_number,
                  first_name,
                  last_name,
                  email,
                  mobile_number,
                  code,
                  password,
                  gender,
                  birthdate,
                  captcha_token,
                  data_processing_accepted
               );
            } else {
               these.setState({isLoading: false});
               if (process.env.REACT_APP_TYPE === APP_TYPE_WHITELABEL)
                  these.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
            }

         } else {
            these.setState({isLoading: false});
            these.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({isLoading: false});
         //this.addNotification('Compañia no encontrada', 'error');
      }
   };

   registerUser = async (
      document_type,
      document_number,
      first_name,
      last_name,
      email,
      mobile_number,
      code,
      password,
      gender,
      birthdate,
      captcha_token,
      data_processing_accepted
   ) => {
      const data = {
         document_number,
         document_type,
         first_name,
         last_name,
         gender,
         email,
         mobile_number,
         code,
         password,
         captcha_token,
         data_processing_accepted
      };

      if (birthdate !== "") {
         let date = moment(birthdate, "DD/MM/YYYY").toDate();
         data.birthdate = moment(date).format("YYYY-MM-DD");
      }
      let auxNumber = false
      console.log(mobile_number)
      if(mobile_number.length!==0){
         if(mobile_number[0] !== '+'){
            if(mobile_number.length!==9){
               this.addNotification("El número telefónico no tiene los carácteres requeridos", "error");
               auxNumber = true
               this.setState({isLoading: false});
               return true
            }
         }
      }else{
      }
      let logInResponse = null
      if(!auxNumber){
         data.mobile_number = `${data.code}${data.mobile_number}`
         delete data.code
         localStorage.setItem("data", JSON.stringify(data))

          logInResponse = await this.props.dispatch(
            registerUserRewards(data)
         );
      }
      let these = this;
      if (
         logInResponse !== undefined &&
         logInResponse != null
      ) {

         let {status, data} = logInResponse.value;
         if (status === 200 || status === 201) {
            these.setState({isLoading: false});
            const {token} = data;
            let name = process.env.REACT_APP_NAME_APP;
            window.localStorage.setItem(`token${name}`, token);
            these.props.dispatch(setToken(token));
            setTimeout(() => {
               (document.location.href = '/');
               return true;
            }, 1000);
         } else {
            if (status === 400) {
               let errorStr = "";
               if (data.email !== undefined && data.email != null)
                  errorStr = data.email.join();
               if (errorStr !== "") {
                  these.setState({isLoading: false});
                  these.addNotification(errorStr, "error");
               } else {
                  these.setState({isLoading: false});
                  these.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
               }
            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            }
         }

      } else {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }

      //
   };

   handleBirthdate = event => {
      let band = event.target.value;
      band = band.replace(" ", "");
      if (band.length === 2) {
         if (this.state.birthdate.length === 3) {
            band = this.state.birthdate.slice(0, 1);
         } else {
            band = `${band}/`;
         }
      }

      if (band.length === 5) {
         if (this.state.birthdate.length === 6) {
            band = this.state.birthdate.slice(0, 4);
         } else {
            band = `${band}/`;
         }
      }

      if (band.length <= 10) this.setFormValues({birthdate: band});
   };

   selectDocumentType = (e, name, type_id) => {
      e.preventDefault();
      this.setState({type_text: name, type: type_id});
   };

   handleSubmit = async event => {
      event.preventDefault();

      const {
         birthdate,
         gender,
         document_number,
         business,
         first_name,
         last_name,
         email,
         mobile_number,
         password,
         captcha_token
      } = this.state;
      const isValid = this.validator.validate({
         document_number,
         business,
         first_name,
         last_name,
         email,
         mobile_number,
         password,
         birthdate
      });

      if (process.env.REACT_APP_NAME_APP === "fidelio" && captcha_token == null) {
         this.addNotification("Haz click en No soy un robot", "error");
         return;
      }

      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }

      if (gender === "") {
         this.addNotification("Elija un sexo", "error");
         return;
      }

      if (!this.state.accepted_terms_cond) {
         this.addNotification("Debe aceptar los terminos y condiciones", "error");
         return;
      }
      if (process.env.REACT_APP_NAME_APP === "bancoelcomercio" && !this.state.data_processing_accepted ) {
         this.addNotification("Debe aceptar los terminos y condiciones", "error");
         return;
      }


      this.setState({isLoading: true});
      if (!this.state.isLoading) {
         this.searchBussiness(business);
      }
   };

   handleInputChange = event => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      this.setState({[name]: value});
   };


   render() {
      let listCountriesPhone = countriesPhone.countries
      let options = []
      let defaultValue = null
      listCountriesPhone.map(c=>
      {
         options.push({ value: `${c.dial_code}`, label: `${c.dial_code} ${c.name_es}` })
         if(c.dial_code===`+51`){
            defaultValue = { value: `${c.dial_code}`, label: `${c.dial_code} ${c.name_es}` }
         }
         return true
      })

      return (
         <div className="col login-form register-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container mt-3">

               <h3 className={"mb-4"}>Regístrate</h3>
               <form onSubmit={this.handleSubmit} className="form-container">
                  <label>Tipo de documento(*)</label>
                  <div className="search-types form-group col dropdown">
                     <div
                        className="form-control"
                        id="dropdownDocumentType"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                     >
                        <span>{this.state.type_text}</span>
                        <img src={arrow_down} alt="" className={"arrow-down"}/>
                     </div>
                     <div
                        className="dropdown-menu"
                        style={{
                           width: "100%",
                           overflowY: "auto",
                           maxHeight: 200
                        }}
                        aria-labelledby="dropdownDocumentType"
                     >
                        <a
                           href={"/"}
                           className="dropdown-item dropdown-type select-type"
                           onClick={e =>
                              this.selectDocumentType(
                                 e,
                                 "Documento de Identidad",
                                 "NATIONAL_ID"
                              )
                           }
                        >
                           Documento de Identidad
                        </a>
                        <a
                           href={"/"}
                           className="dropdown-item dropdown-type select-type"
                           onClick={e =>
                              this.selectDocumentType(
                                 e,
                                 "Pasaporte",
                                 "PASSPORT"
                              )
                           }
                        >
                           Pasaporte
                        </a>
                        <a
                           href={"/"}
                           className="dropdown-item dropdown-type select-type"
                           onClick={e =>
                              this.selectDocumentType(
                                 e,
                                 "Carnet de Extranjería",
                                 "FOREIGNER_ID"
                              )
                           }
                        >
                           Carnet de Extranjería
                        </a>
                        <a
                           href={"/"}
                           className="dropdown-item dropdown-type select-type"
                           onClick={e =>
                              this.selectDocumentType(e, "Ruc", "TAX_ID")
                           }
                        >
                           Ruc
                        </a>
                        <a
                           href={"/"}
                           className="dropdown-item dropdown-type select-type"
                           onClick={e =>
                              this.selectDocumentType(e, "Otro", "OTHER")
                           }
                        >
                           Otro
                        </a>
                     </div>
                  </div>
                  <label>Número de documento(*)</label>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.dniInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           value={this.state.document_number}
                           className="form-control"
                           onChange={this.handleDni}
                           type="text"
                           pattern="[a-zA-Z0-9-]"
                           placeholder="Documento de identidad"
                           required=""
                           floating-validation=""
                        />
                     </div>
                  </div>
                  <label>Nombres(*)</label>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.firstNameInput = input.querySelector(
                                 "input"
                              );
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.first_name}
                           onChange={this.handleFirstName}
                           type="text"
                           pattern="[a-zA-Z]"
                           placeholder="Nombres"
                           required=""
                           floating-validation=""
                        />
                     </div>
                  </div>
                  <label>Apellidos(*)</label>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.lastNameInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.last_name}
                           onChange={this.handleLastName}
                           type="text"
                           pattern="[a-zA-Z]"
                           placeholder="Apellidos"
                           required=""
                           floating-validation=""
                        />
                     </div>
                  </div>
                  <label>Correo electrónico(*)</label>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.emailInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.email}
                           onChange={this.handleEmail}
                           type="email"
                           placeholder="Correo Electrónico"
                           required=""
                           floating-validation=""
                        />
                     </div>
                  </div>
                  <label>Contraseña(*)</label>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.passwordInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.password}
                           onChange={this.handlePassword}
                           type="password"
                           placeholder="Contraseña"
                           required=""
                           floating-validation=""
                        />
                     </div>
                  </div>
                  <label>Número de teléfono(*)</label>
                  <div className={"form-group mb-3"}>
                     <Select options={options}
                             onChange={e => {
                                this.setState({
                                   code: e.value
                                });
                             }}
                             defaultValue={defaultValue}
                     />
                  </div>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.mobileNumberInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.mobile_number}
                           onChange={this.handleMobileNumber}
                           type="text"
                           placeholder="Número de teléfono"
                           required=""
                           floating-validation=""
                        />
                     </div>
                  </div>
                  <label>Fecha de nacimiento (DD/MM/YYYY).</label>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.birthdateInput = input.querySelector(
                                 "input"
                              );
                           }
                        }}
                     >
                        <input
                           value={this.state.birthdate}
                           onChange={this.handleBirthdate}
                           type="text"
                           placeholder="DD/MM/YYYY"
                           required=""
                           className="form-control"
                        />
                     </div>
                  </div>
                  <label>Sexo (*)</label>
                  <div>
                     <div className="form-check">
                        <input
                           className="form-check-input"
                           type="radio"
                           name="gender"
                           value="f"
                           onChange={e => this.handleOption(e, "f")}
                        />
                        <label className="form-check-label" htmlFor="f">
                           Femenino
                        </label>
                     </div>
                     <div className="form-check">
                        <input
                           className="form-check-input"
                           type="radio"
                           name="gender"
                           value="m"
                           onChange={e => this.handleOption(e, "m")}
                        />
                        <label className="form-check-label" htmlFor="m">
                           Masculino
                        </label>
                     </div>
                  </div>
                  <p>
                     <br/>
                     <span>(*) Campos Obligatorios</span>
                  </p>

                  <div className={"d-flex align-items-start checkbox mt-4"}>
                     <label style={{transform: "scale(2)", marginRight: 15}}  className={"checkbox-label"}>
                        <input
                           name="accepted_terms_cond"
                           type="checkbox"
                           onChange={this.handleInputChange}
                           checked={this.state.accepted_terms_cond}
                           className="red"
                           style={{marginTop: 10}}
                        />
                        <span style={{marginLeft: 7}}>
                               {
                                  this.state.accepted_terms_cond &&
                                  <CheckIcon/>
                               }
                           </span>
                     </label>
                     <div style={{marginTop: 4}}>
                        Aceptar los
                        <Link to={`/terms`} className={"link-terms ml-1"} target="_blank">
                           <span>términos y condiciones.</span>
                        </Link>
                        <br/>
                     </div>
                  </div>


                  {process.env.REACT_APP_NAME_APP === "fidelio" ? <div id="recaptcha"/> : null}

                  <div style={{marginBottom: 10, marginTop: 20}}>
                     <button
                        type="submit"
                        className="btn btn-whitelabel btn-lg btn-block"
                        onClick={this.handleSubmit}
                     >
                        {this.state.isLoading && "Cargando..."}
                        {!this.state.isLoading && "Registrar"}
                     </button>
                  </div>
                  <div className="links link-login">
                     <Link to={"/login"}>

                        <span>Retornar a Login</span>
                     </Link>
                  </div>
               </form>
            </div>
         </div>
      );
   }
}

export default connect()(RegisterRewards);