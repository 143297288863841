/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import HttpClient from '../../common/utils/httpClient';
export const VALIDATE_EMAIL = "VALIDATE_EMAIL";


export function validateEmail(appUid, uid, token) {
   let url = "v5/verification-email/"
   if(process.env.REACT_APP_NAME_APP==="bancoelcomercio"){
      url = "v5/boc/verification-email/"
   }
   return dispatch =>
      HttpClient.getLogout(
         {},
         dispatch,
         VALIDATE_EMAIL,
         `${url}${appUid}/${uid}/${token}/`
      );
}


