import React, {Component} from "react";
import "./style.scss"

var images = require.context("../../../common/images", true);

class PoliticsBancoElComercio extends Component {
   render() {
      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      return (
         <div className="container tyc">
            <div className="row">
               <div className="col-lg-12 mt-5 mb-5 d-flex flex-column">

                  <img
                     alt="logo"
                     className="logo"
                     src={logo}
                  />
                  <h2 style={{fontWeight: 700, textAlign: "center", fontSize: 28}}>Política de Tratamiento de Datos Personales</h2>

                  <p className={"mt-4"}>De conformidad con lo establecido en la Ley N° 29733, Ley de Protección de Datos Personales,
                     y su Reglamento, aprobado por Decreto Supremo N° 003-2013-JUS y sus modificatorias; el
                     Banco de Comercio S.A (en adelante, el Banco) ha implementado mecanismos y medidas
                     técnicas organizativas y legales que nos permitan proteger su información personal y efectuar
                     el tratamiento de los mismos. En consecuencia, bajo la presente política se informa las
                     condiciones bajo las cuales son tratados los datos personales de los clientes que se inscriban a
                     “Beneficios Banco de Comercio”</p>
                  <h4>Responsable del tratamiento de datos personales</h4>
                  <p>Banco de Comercio S.A., con RUC N° 20509507199 y domicilio en Av. Canaval y Moreyra 452 –
                     454, San Isidro, Lima, Perú.</p>


                  <h4 className={"mt-4"}>Finalidades necesarias del tratamiento de datos personales</h4>
                  <p>
                     El Banco de Comercio, tratará sus datos con las siguientes finalidades necesarias vinculadas
                     con el Servicio:
                  </p>

                  <ul>
                     <li>
                        Verificar y validar si el usuario es cliente del Banco de Comercio y cumple con las
                        condiciones indicadas en los Términos y Condiciones, para permitirle ingresar la
                        plataforma app y web “Beneficios Banco de Comercio”.
                     </li>
                     <li>
                        Identificar los descuentos y/o beneficios que le corresponden al cliente.
                     </li>
                     <li>
                        Identificar si el cliente utilizó el descuento y/o beneficio reflejado en “Beneficios Banco
                        de Comercio”, conforme a los términos y condiciones.
                     </li>
                     <li>
                        Identificar su ubicación (geolocalización), dependiendo del servicio y el dispositivo que
                        esté usando para ubicar los establecimientos donde están disponibles los descuentos.
                     </li>
                     <li>
                        Gestionar la participación de los clientes en los sorteos que se realicen en “Beneficios
                        Banco de Comercio”.
                     </li>
                     <li>
                        Enviar información de nuevos beneficios, premios y/o recompensas por ser parte de
                        “Beneficios Banco de Comercio”
                     </li>
                  </ul>
                  <p>
                     En caso proporciones algún dato personal de un tercero para compartir tus beneficios, éste
                     será utilizado únicamente para dicha finalidad.
                  </p>

                  <h4 className={"mt-4"}>Finalidades adicionales del tratamiento de datos personales</h4>
                  <p>
                     Cuando usted otorgue el consentimiento expreso del tratamiento de sus datos personales para
                     las finalidades adicionales, sus datos podrán ser utilizados para las siguientes finalidades:
                  </p>
                  <ol type={"a"}>
                     <li>Ofrecerle productos y/o servicios del Banco, y/o de terceros, vinculados al Banco o no
                        (por ejemplo, cuentas, préstamos, entre otros) lo que podremos hacer también a través
                        de diversos medios de comunicación y/o de terceras personas;</li>
                     <li>Enviarle ofertas comerciales y/o promociones (personalizadas o generales), obsequios,
                        publicidad e información de portafolio de los productos y/o servicios del Banco y/o de
                        terceros vinculados o no, (por ejemplo, con empresas con las que tengamos asociaciones o alianzas comerciales), mediante cualquiera de los canales físicos y/o
                        virtuales del Banco;</li>
                     <li>Evaluar su comportamiento en el sistema bancario y su capacidad de pago para evaluar
                        si califica para algún otro producto o servicio.</li>
                     <li>Enviarle invitaciones y saludos personales.</li>
                     <li>Desarrollar acciones comerciales y realizar estudios de mercado</li>
                     <li>Elaborar perfiles de compra y evaluaciones financieras.</li>
                     <li>Gestionar el contacto con el proveedor de beneficios Bederr SAC a su solicitud para la
                        categoría de emprendedores, según los términos y condiciones.</li>
                  </ol>
                  <p>En caso usted autorice el tratamiento de sus datos mediante línea telefónica, el Banco utilizará
                     sus datos para las finalidades informadas a través de dicho canal, es decir, además de las
                     finalidades antes indicadas, también serán utilizados para evaluar si califica para contratar
                     algún producto o servicio del Banco, y para que le puedan informar la oferta de esa
                     oportunidad.
                  </p>
                  <p>El otorgamiento del consentimiento es libre, por tanto, usted puede otorgar su consentimiento
                     o puede negarse a hacerlo. Cuando usted nos brinde su consentimiento, sus datos podrán ser
                     tratados solo para las finalidades señaladas. Del mismo modo, su negativa al consentimiento
                     no afectará la prestación del producto o servicio que el Banco le brinde y sus datos podrán ser
                     usados para cumplir con la preparación, celebración y ejecución de la relación contractual y/o
                     solicitar el cumplimiento de obligaciones adquiridas mediante los contratos, anexos y demás
                     documentación del producto o servicio que haya contratado con nosotros.
                  </p>

                  <h4 className={"mt-4"}>
                     Datos Personales Obligatorios del tratamiento de datos personales
                  </h4>
                  <p>Para llevar a cabo las finalidades descritas es obligatorio nos proporcione los siguientes datos
                     personales: N° de Documento de Identidad, N° de Celular y correo electrónico.</p>

                  <h4 className={"mt-4"}>
                     Consecuencias de proporcionar los datos o su negativa a hacerlo
                  </h4>
                  <p>De no proporcionar los datos obligatorios no se le podrá registrar en “Beneficios Banco de
                     Comercio”</p>

                  <h4 className={"mt-4"}>
                     Transferencia y Destinatarios del tratamiento de datos personales
                  </h4>
                  <p>Para la ejecución de las finalidades el Banco compartirá los datos sus proveedores como
                     encargados del tratamiento, de manera directa o a través de proveedores; quienes están
                     comprometidos a salvaguardar la confidencialidad de sus datos personales, salvo que por ley
                     se exija su revelación.</p>
                  <p>
                     Los proveedores que realizan el tratamiento de sus datos personales por encargo del Banco de
                     Comercio en virtud de una relación jurídica se encuentran listadas en el ítem 02 de la sección {" "}
                     <a href="https://www.bancomercio.com/elbanco/categoria/proteccion-de-datos-personales/545/c-545" target={"_blank"} rel="noopener noreferrer">“Protección de Datos Personales”</a>.
                  </p>
                  <p>
                     Asimismo, se realiza el flujo transfronterizo de datos personales, de acuerdo con el siguiente
                     detalle:
                  </p>
                  <table className="table table-responsive mt-4 table-bordered">
                     <thead>
                     <tr style={{background: "#1f3763", color: "#fff"}}>
                        <th scope="col" style={{textAlign: "center"}}>Destinatario</th>
                        <th scope="col" style={{textAlign: "center"}}>País</th>
                        <th scope="col" style={{textAlign: "center"}}>Finalidad</th>
                     </tr>
                     </thead>
                     <tbody>
                        <tr>
                           <th scope="row" style={{textAlign: "center"}}>AWS</th>
                           <td style={{textAlign: "center"}}>Estados Unidos de América</td>
                           <td style={{textAlign: "center"}}>Administrar la información de clientes para
                              brindarles servicios financieros y los
                              beneficios asociados.</td>
                        </tr>
                     </tbody>
                  </table>

                  <h4 className={"mt-4"}>
                     Banco de Datos del tratamiento de datos personales
                  </h4>
                  <p>La información será almacenada en los Bancos de Datos mediante su inscripción en el Registro
                     Nacional de Protección de Datos Personales, con la denominación ‘‘Clientes Persona Natural”
                     RNPDP-PJP N° 5464.</p>

                  <h4 className={"mt-4"}>Tiempo del tratamiento de datos personales</h4>
                  <p>El tratamiento se efectuará mientras sean necesario para cumplir con las finalidades o hasta
                     por diez años luego de concluida la relación contractual, de acuerdo a lo establecido en la
                     legislación bancaria. En caso haya dado su consentimiento para las finalidades adicionales, sus
                     datos se conservarán mientras no solicite su cancelación.</p>

                  <h4 className={"mt-4"}>Ejercicio de los derechos ARCO</h4>
                  <p>Como titular se sus datos personales, usted podrá ejercer su derecho de acceso, rectificación,
                     cancelación y/o oposición al tratamiento de sus datos personales, presentando su solicitud en
                     cualquier agencia u oficina del Banco. Asimismo, usted podrá ejercer su derecho de revocación
                     del consentimiento en cualquier momento.</p>
                  <p>Los procedimientos y requisitos se pueden consultar en los teléfonos: 0-800-00-2222 o al 513-
                     6001 al interior del país; a través del Portal {" "}
                     <a href="https://www.bancomercio.com/elbanco/categoria/proteccion-de-datos-personales/545/c-545" target={"_blank"} rel="noopener noreferrer">https://www.bancomercio.com/elbanco/categoria/proteccion-de-datos-personales/545/c-545</a>
                     {" "}o en cualquier agencia u oficina. Sin embargo, es importante que tenga en cuenta que no en
                     todos los casos podremos atender su solicitud de oposición o cancelación de forma inmediata,
                     ya que es posible que por alguna obligación legal o causales de improcedencia que estipula la
                     Ley de Protección de datos Personales y su Reglamento requiramos seguir tratando sus datos
                     personales.</p>
                  <p>Los cambios y actualizaciones de las condiciones de tratamiento del presente documento se
                     harán de tu conocimiento en cualquiera de nuestras agencias u oficinas; en nuestro portal {" "}
                     <a href="https://www.bancomercio.com" target={"_blank"} rel="noopener noreferrer">www.bancomercio.com</a> y/o en el estado de cuenta del producto que tenga contratado con
                     nosotros.</p>

                  <h4 className={"mt-4"}>Contactar al Banco</h4>
                  <p>Si considera que sus derechos de protección de datos personales han sido vulnerados por
                     alguna conducta de nuestros trabajadores, de nuestras actuaciones o respuestas, puede
                     contactar a nuestra Área de Protección de Datos Personales, email:{" "}
                     <a href="mailto:derechos_pdp@bancomercio.com.pe">derechos_pdp@bancomercio.com.pe</a> o a los teléfonos 0-800-00-2222 o al 513-6001 al interior
                     del país. Sin perjuicio de ello, puede presentar una reclamación ante la Autoridad Nacional de Protección de Datos Personales, dirigiéndose a la Mesa de Partes del Ministerio de Justicia y
                     Derechos Humanos: Calle Scipion Llona 350, Miraflores, Lima, Perú. </p>

               </div>
            </div>
         </div>
      )
   }
}




export default PoliticsBancoElComercio;
