/* eslint-disable no-restricted-globals */
import axios from 'axios';
import {APP_TYPE_HHRR} from "./generic";


const name = process.env.REACT_APP_NAME_APP;
let API_URL = 'https://api.bederr.com/';
if(process.env.REACT_APP_URL_REST !== undefined )
   API_URL = process.env.REACT_APP_URL_REST;

const getHeadersAuth = () => {
   let token = window.localStorage.getItem(`token${name}`)!==null ? window.localStorage.getItem(`token${name}`):window.localStorage.getItem(`token${name}2`);
   if (token == null) {
      if (process.env.REACT_APP_TYPE === APP_TYPE_HHRR) {
         return {
            "API-KEY": `${process.env.REACT_APP_APY_KEY}`,
            "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
            Pragma: "no-cache"
         };
      }
      return {
         "API-KEY": `${window.localStorage.getItem(`apikey${name}`)}`,
         "Cache-Control": "no-cache, no-store, max-age=0, must-revalidate",
         Pragma: "no-cache"
      };
   } else {
      if (process.env.REACT_APP_TYPE === APP_TYPE_HHRR) {
         return {
            "API-KEY": `${window.localStorage.getItem(`apikey${name}`)}`,
            Authorization: `Token ${token}`
         };
      } else {
         return {
            "API-KEY": `${process.env.REACT_APP_APY_KEY}`,
            Authorization: `Token ${token}`
         };
      }
   }
};

const getHeaders = () => ({
   "Content-Type": "application/json",
   "API-KEY": `${window.localStorage.getItem(`apikey${name}`)}`
});

export default class HttpClient {

   static get(dispatch, type, url) {
      dispatch({ type: `${type}_PENDING` });
      return dispatch({
         type,
         payload: axios
            .get(`${API_URL}${url}`, { headers: getHeadersAuth() })
            .then(response => {
               dispatch({
                  type: `${type}_FULFILLED`,
                  payload: {
                     status: response.status,
                     data: response.data
                  }
               });

               return { status: response.status, data: response.data };
            })
            .catch(error => {
               if (
                  error.response &&
                  (error.response.status === 401 ||
                     error.response.status === 403)
               ) {
                  dispatch({
                     type: `${type}_FULFILLED`,
                     payload: error.response
                  });
                  return {
                     status: error.response.status,
                     data: error.response.data
                  };
               } else {
                  if (error.response) {
                     dispatch({
                        type: `${type}_FULFILLED`,
                        payload: error.response
                     });
                     return {
                        status: error.response.status,
                        data: error.response.data
                     };
                  } else {
                     dispatch({
                        type: `${type}_FULFILLED`,
                        payload: { status: 500 }
                     });

                     return { status: 500, data: [] };
                  }
               }
            })
      });
   }

   static getUrl(dispatch, type, url) {
      dispatch({ type: `${type}_PENDING` });
      return dispatch({
         type,
         payload: axios
            .get(`${url}`, { headers: getHeadersAuth() })
            .then(response => {
               dispatch({ type: `${type}_FULFILLED`, payload: response });
               return { status: response.status, data: response.data };
            })
            .catch(error => {
               if (
                  error.response &&
                  (error.response.status === 401 ||
                     error.response.status === 403)
               ) {
                  return {
                     status: error.response.status,
                     data: error.response.data
                  };
               } else {
                  return {
                     status: error.response.status,
                     data: error.response.data
                  };
               }
            })
      });
   }

   static delete(dispatch, type, url) {
      return dispatch({
         type,
         payload: axios.delete(`${API_URL}${url}`, {
            headers: getHeadersAuth()
         })
      });
   }

   static post(data, dispatch, type, url) {
      dispatch({ type: `${type}_PENDING` });
      return dispatch({
         type,
         payload: axios
            .post(`${API_URL}${url}`, data, { headers: getHeadersAuth() })
            .then(response => {
               dispatch({ type: `${type}_FULFILLED`, payload: response });
               return { status: response.status, data: response.data };
            })
            .catch(error => {
               return {
                  status: error.response.status,
                  data: error.response.data
               };
            })
      });
   }

   static postResponse(data, dispatch, type, url) {
      return dispatch({
         type,
         payload: axios.post(`${API_URL}${url}`, data, {headers: getHeadersAuth()}).then((response) => response)
            .catch((error) => error.response),
      });
   }

   static patch(data, dispatch, type, url) {
      dispatch({ type: `${type}_PENDING` });
      return dispatch({
         type,
         payload: axios
            .patch(`${API_URL}${url}`, data, { headers: getHeadersAuth() })
            .then(response => {
               dispatch({ type: `${type}_FULFILLED`, payload: response });
               return { status: response.status, data: response.data };
            })
            .catch(error => {

               if (
                  error.response &&
                  (error.response.status === 400 ||
                     error.response.status === 401 ||
                     error.response.status === 403)
               ) {
                  dispatch({ type: `${type}_ERROR`, payload: error.response });
                  return {
                     status: error.response.status,
                     data: error.response.data
                  };
               } else {
                  dispatch({ type: `${type}_ERROR`, payload: error.response });
                  return {
                     status: 0,
                     data: { default: "Algo inesperado ha sucedido" }
                  };
               }
            })
      });
   }

   static postLogout(data, dispatch, type, url, headers) {
      dispatch({ type: `${type}_PENDING` });
      console.log(getHeaders())
      return dispatch({
         type,
         payload: axios
            .post(`${API_URL}${url}`, data, { headers: getHeaders() })
            .then(response => {
               dispatch({ type: `${type}_FULFILLED`, payload: response });
               return { status: response.status, data: response.data };
            })
            .catch(error => {
               try {
                  return error.response;
               } catch (e) {
                  return { error: { response: "algo sucedió mal" } };
               }
            })
      });
   }

   static getLogout(data, dispatch, type, url, headers) {
      dispatch({ type: `${type}_PENDING` });
      return dispatch({
         type,
         payload: axios
            .get(`${API_URL}${url}`, { headers: getHeaders() })
            .then(response => {
               dispatch({ type: `${type}_FULFILLED`, payload: response });
               return { status: response.status, data: response.data };
            }).catch(error => {
               if (
                  error.response &&
                  (error.response.status === 400 ||
                     error.response.status === 401 ||
                     error.response.status === 403)
               ) {
                  dispatch({ type: `${type}_ERROR`, payload: error.response });
                  return {
                     status: error.response.status,
                     data: error.response.data
                  };
               } else {
                  dispatch({ type: `${type}_ERROR`, payload: error.response });
                  return {
                     status: 0,
                     data: { default: "Algo inesperado ha sucedido" }
                  };
               }
            })
      });
   }
}

