/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import HttpClient from '../../common/utils/httpClient';
import {COMPANY_GET, LOGIN, VERIFY} from "./constants";


export function getCompany(subdomain) {
   return dispatch => HttpClient.get( dispatch, COMPANY_GET, `v5/companies/subdomain/${subdomain}/`);
}
export function logIn(data) {
   return dispatch =>
      HttpClient.postLogout(data, dispatch, LOGIN, "v4/login/?using=dni");
}
export function login(data) {
   return dispatch =>
      HttpClient.postLogout(data, dispatch, LOGIN, "v5/login/");
}

export function   logInWithPassword(data) {
   return dispatch =>
      HttpClient.postLogout(data, dispatch, LOGIN, "v5/login/");
}

export function logInFacebook(data) {
   return dispatch =>
      HttpClient.postLogout(
         data,
         dispatch,
         LOGIN,
         "v5/login/mobile/facebook/"
      );
}




export function verifyUser(data) {
   return dispatch =>
      HttpClient.postLogout(data, dispatch, VERIFY, "v5/validate/");
}


export function verifyUserBancoElComercio(data) {
   let url = "v5/boc/validate/clients/"
   if (data.document_number && data.document_number) {
      url = `${url}?document_number=${data.document_number}&document_type=${data.document_type}`;
   }
   return dispatch =>
      HttpClient.getLogout({}, dispatch, VERIFY, url);
}


export function verifyBirthdateBancoElComercio(document_number, birthdate) {
   let url = "v5/boc/validate/documents/"
   if (document_number && birthdate) {
      url = `${url}?document_number=${document_number}&issue_date=${birthdate}`;
   }
   return dispatch =>
      HttpClient.getLogout({}, dispatch, VERIFY, url);
}

