import * as types from "../common/constants/actionTypes";

const initialState = {
   data: [],
   popups: [],
   isFetching: false,
   isFetchingPopups: false
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case `${types.BANNERS_FETCH}_PENDING`:
         return { ...state, isFetching: true };
      case `${types.BANNERS_FETCH}_REJECTED`:
         return { ...state, isFetching: false, error: action.payload };
      case `${types.BANNERS_FETCH}_FULFILLED`:
         return Object.assign({}, state, {
            isFetching: false,
            data: action.payload.data
         });
      case `${types.POPUPS_FETCH}_PENDING`:
         return { ...state, isFetchingPopups: true };
      case `${types.POPUPS_FETCH}_REJECTED`:
         return { ...state, isFetchingPopups: false, error: action.payload };
      case `${types.POPUPS_FETCH}_FULFILLED`:
         return Object.assign(
            {},
            state,
            {
               isFetchingPopups: false,
               popups: action.payload.data
            }
         );
      case `${types.CLEAN_BANNERS}`:
         return {
            ...state,
            isFetching: false,
            data: []
         };
      default:
         return state;
   }
}
