import * as types from '../common/constants/actionTypes';

const initialState = {
  data: null,
  next: null,
  isFetching: false,
  isFetchingMore: false,
  no_read: false,
  notification: null,
  isFechingNotification: false
};

export default function reducer(state = initialState, action) {

  switch (action.type) {
    case `${types.NOTIFICATIONS_FETCH}_PENDING`:
      return { ...state, isFetching: true, data: [], next: null };
    case `${types.NOTIFICATIONS_FETCH}_REJECTED`:
      return { ...state, isFetching: false, error: action.payload };
    case `${types.NOTIFICATIONS_FETCH}_FULFILLED`:
      if(action.payload&&action.payload.data) {
        let no_read = false;
        for (let j = 0; j < action.payload.data.results.length; j++) {
          if (action.payload.data.results[j].seen_at == null) {
            no_read = true
          }
        }
        return Object.assign({},
           state, {
             isFetching: false,
             no_read: no_read,
             data: action.payload.data.results,
             next: action.payload.data.next,
           }
        );
      }else{
        return {
          ...state,
          isFetchingEntity: false,
          notification: null
        };
      }
    case `${types.NOTIFICATIONS_MORE_FETCH}_PENDING`:
      return { ...state, isFetchingMore: true };
    case `${types.NOTIFICATIONS_MORE_FETCH}_REJECTED`:
      return { ...state, isFetchingMore: false, error: action.payload };
    case `${types.NOTIFICATIONS_MORE_FETCH}_FULFILLED`:
      let data = state.data
      for (let i = 0; i < action.payload.data.results.length; i++) {
        data.push(action.payload.data.results[i])
      }
      return Object.assign({},
        state, {
          isFetchingMore: false,
          data: data,
          next: action.payload.data.next,
        }
      );
    case `${types.DETAIL_NOTIFICATION}_PENDING`:
      return { ...state, isFechingNotification: true, notification: null };
    case `${types.DETAIL_NOTIFICATION}_REJECTED`:
      return { ...state, isFechingNotification: false, error: action.payload, notification: null };
    case `${types.DETAIL_NOTIFICATION}_FULFILLED`:
      return {
        ...state,
        isFechingNotification: false,
        notification: action.payload.data
      };
    default:
      return state;
  }
}
