import * as types from "../common/constants/actionTypes";
import HttpClient from "../common/utils/httpClient";


const initialState = {
  app: [],
  isFetching: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${types.GET_APP}_PENDING`:
      return { ...state, isFetching: true };
    case `${types.GET_APP}_REJECTED`:
      return { ...state, isFetching: false, error: action.payload };
    case `${types.GET_APP}_FULFILLED`:
       let newData = [];
       newData = action.payload.data
      return Object.assign({}, state, {
        isFetching: false,
         app: newData
      });
    default:
      return state;
  }
}

export function getApp(api_key) {
   return dispatch => HttpClient.get( dispatch, types.GET_APP, `v7/apps/?api_key=${api_key}`);
}