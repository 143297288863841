import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getProgramPromotions} from "./actions";
import ProgramPromotions from "./ProgramPromotions";

import {HOME} from "../../common/constants/actionTypes";
import {changeMenu} from "../Menu/actions";

class SearchProgramPromotions extends Component {
   static contextTypes = {
      router: PropTypes.object
   };

   state = {
      locality: null,
      searchText: ""
   };

   componentDidMount() {

      let search = this.props.location.search;
      this.getProgramPromotions(
         this.getParameterByName("search", search),
         this.getParameterByName("loc", search)
      );
      this.props.dispatch(changeMenu(HOME));
   }


   getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[[\]]/g, "\\$&");
      let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
         results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      let search = this.props.location.search;
      this.getProgramPromotions(
         this.getParameterByName("search", search),
         this.getParameterByName("loc", search)
      );
   }

   getProgramPromotions = (search, loc) => {
      this.props.dispatch(
         getProgramPromotions(
            this.props.benefit_program.uid,
            null,
            loc,
            search,
            null,
            null,
            null,
            false
         )
      );
   };

   render() {
      return (
         <div className="main-body d-flex flex-wrap justify-content-end">
            <div className="col-sm-12">
               <ProgramPromotions {...this.props} />
            </div>
         </div>
      );
   }
}

SearchProgramPromotions.defaultProps = {
   from_search: true
};
export default connect(({benefitPrograms}) => ({
   benefit_program: benefitPrograms.benefitProgram
}))(SearchProgramPromotions);
