import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import InfiniteScroll from "react-infinite-scroller";

import {COMPANY_PLACES} from "../../common/constants/actionTypes";
import MyPlace from "../../common/components/widgets/MyPlace";
import {changeMenu} from "../Menu/actions";
import {getMorePlaces} from "../ProgramPromotions/actions";
import {getCompanyPlaces} from "./actions";

class CompanyPlaces extends Component {
   static contextTypes = {
      router: PropTypes.object
   };

   state = {};

   componentDidMount() {
      const { benefit_program } = this.props;
      if (benefit_program != null) {
         this.props.dispatch(getCompanyPlaces(benefit_program.company.uid));
         this.props.dispatch(changeMenu(COMPANY_PLACES));
      }
   }


   loadMore = () => {
      const { next, loadingPLacesMore } = this.props;
      if (!loadingPLacesMore && next != null) {
         this.props.dispatch(getMorePlaces(next));
      }
   };

   renderPlaces() {
      const { places } = this.props;

      if (places.length === 0) {
         return (
            <div className="content-no-items">
               <p>Ups! , no pudimos encontrar locales</p>
            </div>
         );
      } else {
         return places.map((place, index) => (
            <MyPlace key={place.uid} place={place} />
         ));
      }
   }

   render() {
      const { loadingPLaces, next } = this.props;
      let more = next != null;
      var loaderMore = (
         <div
            key={0}
            className="col-lg-12 content-loader"
            style={{ display: "inline-flex" }}
         >
            <p>Cargando...</p>
         </div>
      );
      if (loadingPLaces)
         return (
            <div className="row content-loader">
               <p>Cargando...</p>
            </div>
         );

      return (
         <div className="main-body d-flex flex-wrap justify-content-end">
            <div className="col-sm-12">
               <div style={{ position: "relative" }}>
                  <InfiniteScroll
                     className="entities-section row"
                     pageStart={0}
                     loadMore={this.loadMore.bind(this)}
                     hasMore={more}
                     loader={loaderMore}
                  >
                     {this.renderPlaces()}
                  </InfiniteScroll>
               </div>
            </div>
         </div>
      );
   }
}

CompanyPlaces.defaultProps = {
   loadingPLaces: false,
   benefit_program: null,
   places: [],
   loadingPLacesMore: false
};

export default connect(({ places, benefitPrograms }) => ({
   benefit_program: benefitPrograms.benefitProgram,
   places: places.data,
   loadingPLaces: places.isFetching,
   loadingPLacesMore: places.isFetchingMore,
   next: places.next
}))(CompanyPlaces);
