import React from 'react';
import Page from '../Page';

const NotFound = props => (
   <Page
      id="not-found"
      title="Not Found"
      description="This is embarrassing."
      noCrawl
   >
      <p>Lo sentimos esta ruta no está disponible.</p>
   </Page>
);
export default NotFound;