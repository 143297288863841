export const LANDING_WHITE = "WHITE";
export const LANDING_IMAGE = "IMAGE";
export const APP_TYPE_WHITELABEL = "WHITELABEL";
export const APP_TYPE_DIPPO = "DIPPO";
export const APP_TYPE_HHRR= "HHRR";
export const PROFILE_COMPLEX= "PROFILE_COMPLEX";
export const PROFILE_SIMPLE= "PROFILE_SIMPLE";

export const APP_LOGIN_PASSWORD= "LOGIN_PASSWORD";
export const APP_LOGIN_DIRECT= "LOGIN_DIRECT";

export const buildConfig = {

   colors_main_color: "#3958f8",
   colors_secondary_color: "#dfff36",

   company_config_name: "bederr",
   company_config_name_business: "bederr",
   company_config_url_site: "https://bederr.com",
   company_config_static_logo: false,
   company_config_alternative_logo: false,


   styles_external:"",
   styles_local: "",

   app_landing:LANDING_IMAGE,
   app_type: APP_TYPE_WHITELABEL,
   app_soon: true,

   app_login_type: APP_LOGIN_PASSWORD,
   app_allow_recovery_password:true,
   app_allow_register:true,
   app_allow_fb_login: false,
   app_fb_uid: "",

   app_profile_type: PROFILE_COMPLEX,
   app_profile_allow_edit_profile:false,
   app_profile_show_level_rating_bar:false,
   app_profile_show_badges:false,
   app_profile_show_products:false,
   app_profile_show_levels:false,
   app_profile_show_points:false,

   app_menu_show_locals:false,
   app_menu_show_points:false,
   app_menu_show_notifications:true,
   app_menu_show_preferences:false,
   app_menu_show_polls:false,
   app_menu_show_all_sections:true,

};


export const loaderItem = {
   name: "bubbles",
   color: "#4c524a",
   with: "100%"
};



let text = {
   textProducts: "Para acumular más puntos adquiere los siguientes productos",
   loginExtraText: "",
   textApp: "App de \nbeneficios para\nclientes de",
   webTitle: "",
   webDescription: "",
   textLocals: "Mis Locales"
};




if (process.env.REACT_APP_NAME_APP === "rewards") {
   text.webTitle = "";
   text.textApp = "";
}


if (process.env.REACT_APP_NAME_APP === "lapositiva") {
   text.webTitle = "";
   text.textApp = "";
}

if (process.env.REACT_APP_NAME_APP === "banbif") {
   text.webTitle = "";
   text.textApp = "";
}

if (process.env.REACT_APP_NAME_APP === "bederrapoya") {
   text.webTitle = "";
   text.textApp = "";
}

if (process.env.REACT_APP_NAME_APP === "cooperativapacifico") {
   text.webTitle = "";
   text.textApp = "";
   text.textProducts = "Actualmente tienes los siguientes productos";
   text.textLocals = "Nuestras Agencias";
}

if (process.env.REACT_APP_NAME_APP === "aptitus_loyalty") {
}

if (process.env.REACT_APP_NAME_APP === "mapfre") {
   text.textApp = "";
   text.textProducts =
      "MAPFRE te recomienda crecer en prevención con los siguientes seguros (dale click a las imágenes en color rojo)";
}

if (process.env.REACT_APP_NAME_APP === "petroperu") {
   text.textProducts = "Actualmente tienes los siguientes badges";
}

if (process.env.REACT_APP_NAME_APP === "mibanco") {
   text.textProducts = "Actualmente tienes los siguientes productos";
}

if (process.env.REACT_APP_NAME_APP === "pacificoseguros") {
   text.textProducts = "Actualmente tienes los siguientes seguros";
   text.textApp = "";
}

if (process.env.REACT_APP_NAME_APP === "fidelio") {
   text.textApp = "";
}

if (process.env.REACT_APP_NAME_APP === "bancoelcomercio") {
   text.textApp = "";
   text.loginExtraText = "Consulta tus beneficios"
}

export const TextDefaul = text;

