import * as types from "../common/constants/actionTypes";
import {VALIDATE_EMAIL} from "../modules/ValidateEmail/actions";
import {FACEBOOK_STATUS} from "../modules/FacebookStatus/actions";

const initialState = {
   user: null,
   isFetching: false,
   isLoadingValidateEmail: false,
   messageValidateEmail: "",
   isLoadingFacebookStatus: false,
   messageFacebookStatus: "",
   isFetchingCity: false,
   changeInUser: false,
   error: null,
   code: 0
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case `${types.USER_GET}_PENDING`:
         return {...state, isFetching: true};
      case `${types.USER_GET}_REJECTED`:
         return {
            ...state,
            isFetching: false,
            changeInUser: false,
            error: action.payload
         };
      case `${types.USER_GET}_FULFILLED`:
         let userData_full = null;

         if (action.payload.data != null && action.payload.status === 200) {
            userData_full = action.payload.data;
            userData_full["is_complete"] = !(
               userData_full.first_name === "" ||
               userData_full.last_name === "" ||
               userData_full.document_number.indexOf("DOC-") === 0
            );
         }

         return {
            ...state,
            isFetching: false,
            changeInUser: false,
            user: userData_full,
            code: action.payload.status
         };


      case `${types.USER_PATCH}_PENDING`:
         return {...state, isFetching: true};
      case `${types.USER_PATCH}_REJECTED`:
         return {...state, isFetching: false, error: action.payload};
      case `${types.USER_PATCH}_ERROR`:
         return {...state, isFetching: false, error: action.payload.data};
      case `${types.USER_PATCH}_FULFILLED`:
         let newUser = null;
         if (action.payload.data != null) {
            newUser = action.payload.data;
            newUser["is_complete"] = !(
               newUser.first_name === "" ||
               newUser.last_name === "" ||
               (newUser.document_number!==undefined && newUser.document_number.indexOf("DOC-") === 0)
            );
         }

         return {
            ...state,
            user: newUser,
            isFetching: false,
            changeInUser: true
         };
      case `${types.EDIT_CITY}_PENDING`:
         return {...state, isFetchingCity: true};
      case `${types.EDIT_CITY}_REJECTED`:
         return {...state, isFetchingCity: false, error: action.payload};
      case `${types.EDIT_CITY}_FULFILLED`:
         let userData = null;
         if (action.payload.data != null) {
            userData = action.payload.data;
            userData["is_complete"] = !(
               userData.first_name === "" ||
               userData.last_name === "" ||
               userData.document_number.indexOf("DOC-") === 0
            );
         } else {
            userData = state.user;
         }
         return {
            ...state,
            user: userData,
            isFetchingCity: false
         };
      case `${VALIDATE_EMAIL}_PENDING`:
         return {...state, isLoadingValidateEmail: true, messageValidateEmail:'Validando email'};
      case `${VALIDATE_EMAIL}_REJECTED`:
         return {...state, isLoadingValidateEmail: false, messageValidateEmail:'No se ha podido validar el correo electrónico'};
      case `${VALIDATE_EMAIL}_FULFILLED`:
        let msg = "No se ha podido validar el correo"
         if (action.payload.data !== null &&
            (action.payload.status === 200 || action.payload.status === 400)) {
            msg = action.payload.data.detail
         }
         return {
            ...state,
            isLoadingValidateEmail: false,
            messageValidateEmail: msg
         };
      case `${FACEBOOK_STATUS}_PENDING`:
         return {...state, isLoadingFacebookStatus: true, messageFacebookStatus:'Cargando'};
      case `${FACEBOOK_STATUS}_REJECTED`:
         return {...state, isLoadingFacebookStatus: false, messageFacebookStatus:'No se ha podido obtener la información'};
      case `${FACEBOOK_STATUS}_FULFILLED`:
         let msg2 = "No se ha podido obtener la información"
         if (action.payload.data !== null &&
            (action.payload.status === 200 || action.payload.status === 400)) {
            msg2 = action.payload.data.detail
         }
         return {
            ...state,
            isLoadingFacebookStatus: false,
            messageFacebookStatus: msg2
         };
      default:
         return state;
   }
}
