import {combineReducers} from "redux";

import entities from "./entities";
import users from "./users";
import tags from "./tags";
import boxes from "./boxes";
import benefitPrograms from "./benefitPrograms";
import countries from "./countries";
import localities from "./localities";
import offers from "./offers";
import places from "./places";
import notifications from "./notifications";
import banners from "./banners";
import polls from "./polls";
import auth from "./auth";
import raffles from "./raffles";
import points from "./points";
import menu from "./menu";
import programPromotions from "./programPromotions";
import redemptions from "./redemptions";
import app from "./app";

export default combineReducers({
   auth,
   entities,
   redemptions,
   users,
   tags,
   benefitPrograms,
   countries,
   localities,
   offers,
   places,
   notifications,
   banners,
   polls,
   boxes,
   raffles,
   points,
   programPromotions,
   menu,
   app
});
