import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
   getDetailProgramPromotion,
   getMorePlaces,
   getMoreRedemptions,
   getPlaces,
   getRedemptions,
   payPromotion
} from "./actions";
import InfiniteScroll from "react-infinite-scroller";
import Place from "../../common/components/widgets/Place";
import Promotion from "../../common/components/widgets/Promotion";
import {Link} from "react-router-dom";
import moment from "moment";
import ShoopingBagIcon from "./icons/ShoopingBagIcon"
import DownIcon from "./icons/DownIcon"
import {Culqi, CulqiProvider} from "../../common/components/Culqi";
import NotificationSystem from "react-notification-system";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import firebase from "firebase";

class DetailProgramPromotion extends Component {
   static contextTypes = {
      router: PropTypes.object
   };

   state = {
      promotion_id: null,
      new_register : false
   };

   componentDidMount() {
      const {promotion_id, benefit_program} = this.props;
      if (
         benefit_program != null &&
         promotion_id !== undefined &&
         promotion_id != null
      ) {
         this.setState({promotion_id});
         this.props.dispatch(
            getDetailProgramPromotion(benefit_program.uid, promotion_id)
         );
         this.props.dispatch(getPlaces(promotion_id));
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      const {promotion_id, benefit_program, program_promotion} = this.props;
      if (benefit_program != null && this.state.promotion_id !== promotion_id) {
         this.setState({promotion_id});
         this.setState({new_register:false});

         this.props.dispatch(
            getDetailProgramPromotion(benefit_program.uid, promotion_id)
         );
         this.props.dispatch(getPlaces(promotion_id));
      }else{
         if(program_promotion!=null && !this.state.new_register ){
            this.setState({new_register:true});
            try{
               firebase.analytics().logEvent('visit_promotion_web',{
                  promotion_id: program_promotion.uid,
                  title:  program_promotion.title,
                  company: program_promotion.company_retail.name
               });
            }catch (ignored) {

            }
         }
      }

   }

   loadMore = () => {
      const {next, loadingPLacesMore} = this.props;
      if (!loadingPLacesMore && next != null) {
         this.props.dispatch(getMorePlaces(next));
      }
   };

   loadMoreRedemptions = () => {
      const {nextRedemptions, loadingRedemptions} = this.props;
      if (!loadingRedemptions && nextRedemptions != null) {
         this.props.dispatch(getMoreRedemptions(nextRedemptions));
      }
   };


   loadRedemptions = () => {
      const {loadingRedemptions, benefit_program, program_promotion} = this.props;
      if (!loadingRedemptions) {
         this.props.dispatch(getRedemptions(benefit_program.uid, program_promotion.uid));
      }
   };

   payPromotion = (token) => {
      const {isLoadingPayment, benefit_program, program_promotion} = this.props;
      let data = {
         token: token.id,
         email: token.email
      };
      if (!isLoadingPayment) {
         this.props.dispatch(payPromotion(data, benefit_program.uid, program_promotion.promotion_uid));
      }
   };

   errorPayment = () => {
      if(this.notificationSystemRef !== undefined && this.notificationSystemRef !== null) {
         this.notificationSystemRef.addNotification({
            message: "Tu tarjeta no ha pasado la validación respectiva",
            level: "error"
         });
      }

   };

   renderPlaces() {
      const {places} = this.props;
      if (places.length === 0) {
         return (
            <div className="content-no-items">
               <p>Ups! , no pudimos encontrar locales</p>
            </div>
         );
      } else {
         return places.map((place, index) => (
            <div key={place.uid}>
               <Place place={place}/>
            </div>
         ));
      }
   }

   renderRedemptions() {
      const {redemptions } = this.props;
      if (redemptions == null) {
         return <div className=""/>;
      }
      if (redemptions.length === 0) {
         return (

            <p>No se ha encontrado redenciones asociadas a esta promoción</p>

         );
      } else {
         return redemptions.map((redemption, index) => (

            <div key={redemption.uid} className={"row"} style={{paddingTop:10}}>
               <div  className="col-3"> {moment(redemption.created_at).format("DD/MMM")}</div>
               <div  className="col-6"> { redemption.place!=null? redemption.place.address : "--"}</div>
               <div className="col-3 align-content-end" >S/{redemption.amount}</div>
               <div className={"line col"} style={{margin:0, paddingLeft:1, background:"rgba(23,162,184,0.27)"}}/>
            </div>

         ));
      }
   }



   render() {
      const {
         isFetchingProgramPromotion,
         program_promotion,
         next,
         isLoadingPayment,
         benefit_program,
         loaderRedemptions,
         nextRedemptions
      } = this.props;
      let more = next != null;
      let moreRedemptions = nextRedemptions != null;
      let logoRetail = "";

      var loaderMore = (
         <div
            key={0}
            className="col-lg-12 content-loader"
            style={{display: "inline-flex"}}
         >
            <LoadingIndicator/>
         </div>
      );

      var loaderMoreRedemtions = (
         <tr
         >
            <td colSpan={2}>Cargando...</td>
         </tr>
      );

      if (isLoadingPayment)
         return (
            <div className="row content-loader">
               <div className={"col"}>
                  <div className={"col-12"}>
                     <LoadingIndicator/>
                  </div>
                  <div className={"col-12"}>
                     <p>Procesando Pago, por favor no salir de esta pantalla</p>
                  </div>
               </div>
            </div>
         );
      if (isFetchingProgramPromotion)
         return (
            <div className="row content-loader">
               <div className={"col"}>
                  <div className={"col-12"}>
                     <LoadingIndicator/>
                  </div>
                  <div className={"col-12"}>
                     <p>Cargando...</p>
                  </div>
               </div>
            </div>
         );
      if (program_promotion == null) {
         return (
            <div className="content-no-items">
               <p> Ups! , no pudimos encontrar la oferta </p>
            </div>
         );
      } else {
         let bp_color = benefit_program.color;
         if(program_promotion.company_retail!==undefined && program_promotion.company_retail!==null)
            logoRetail = program_promotion.company_retail.logo;
         if(logoRetail.length>0){
            logoRetail=logoRetail.replace("https://","//")
         }
         if(bp_color!=null && bp_color.length>0)
            bp_color = bp_color.replace("#","")
         else{
            bp_color = "000000"
         }
         let terms =
            program_promotion.promotion_terms !== null &&
            program_promotion.promotion_terms !== "" &&
            program_promotion.promotion_terms !== "."
               ? program_promotion.promotion_terms
               : "";
         terms = terms === "" ? terms : terms + "\n\n";
         terms =
            terms +
            (program_promotion.terms !== null &&
            program_promotion.terms !== "" &&
            program_promotion.terms !== "."
               ? program_promotion.terms
               : "");
         let see_leads_btn =
            program_promotion.enable_lead &&
            program_promotion.lead_form != null &&
            !program_promotion.interested;

         let show_place_description =
            program_promotion.company_retail != null &&
            program_promotion.company_retail.description !== null &&
            program_promotion.company_retail.description !== "" &&
            program_promotion.company_retail.description !== ".";
         let see_information_btn =
            program_promotion.link != null && program_promotion.link.length > 0;
         let see_raffle = program_promotion.raffle != null;
         let btn_text_lead = "Estoy Interesado(a)";
         if (
            see_leads_btn &&
            program_promotion.lead_form != null &&
            program_promotion.lead_form.button_text != null &&
            program_promotion.lead_form.button_text.length > 0
         ) {
            btn_text_lead = program_promotion.lead_form.button_text;
         }
         let that = this;
         return (
            <div className="row justify-content-md-center entity-detail">
               <NotificationSystem
                  ref={notificationSystem =>
                     (this.notificationSystemRef = notificationSystem)
                  }
               />
               <div className="col-lg-12">
                  <div className="row detail-offer">
                     <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <Promotion

                           is_detail={true}
                           promotion={program_promotion}
                        />
                        <div className="offer col">
                           {see_information_btn && (
                              <a

                                 href={program_promotion.link}
                                 target="_blank"
                                 rel="noopener noreferrer"
                                 className="btn btn-whitelabel-auxiliar"
                              >
                                 Más Información
                              </a>
                           )}
                           {see_leads_btn && (
                              <Link
                                 to={`/offers/${
                                    program_promotion.uid
                                    }/interest`}
                                 className="btn btn-whitelabel-auxiliar"
                              >
                                 {btn_text_lead}
                              </Link>
                           )}
                           {see_raffle && (
                              program_promotion.raffle.show_input_button &&
                              <Link
                                 to={`/offers/${program_promotion.uid}/raffle/${
                                    program_promotion.raffle.uid
                                    }`}
                                 className="btn btn-whitelabel-auxiliar"
                              >
                                 {program_promotion.raffle.button_text !=
                                 null &&
                                 program_promotion.raffle.button_text}
                                 {program_promotion.raffle.button_text ==
                                 null && "Entrar al Sorteo"}
                              </Link>
                           )}
                        </div>
                     </div>
                     <div className="description col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className="justify-content-md-center">
                           <h5
                              style={{paddingTop: 0}}
                              className="title-section bold "
                           >
                              {program_promotion.title}
                           </h5>
                        </div>
                        {program_promotion.type === "GIFT_CARD" && program_promotion.allow_payment  &&
                        <CulqiProvider
                           publicKey={process.env.REACT_APP_CULQI}
                           amount={parseFloat(program_promotion.original_price)*100}
                           title={program_promotion.title}
                           description="Necesitamos los datos de su tarjeta para hacer efectiva la compra"
                           onToken={token => {
                              that.payPromotion(token)
                           }}
                           onError={error => {
                              that.errorPayment()
                           }}
                           // Uncomment `options` to see customizations take place
                           options={{
                              style: {
                                 maincolor: `#${bp_color}`,
                                 buttontext: "#fff",
                                 maintext: "#000000",
                                 desctext: "#000000",
                                 logo: `${logoRetail}`
                              }
                           }}
                        >
                           <div>
                              <Culqi>
                                 {({ openCulqi, amount }) => {
                                    return (
                                       <div>
                                          <button className={"btn btn-whitelabel"} onClick={openCulqi}> <ShoopingBagIcon className={"icon-bag"}/>Comprar</button>
                                       </div>
                                    );
                                 }}
                              </Culqi>
                           </div>
                        </CulqiProvider>
                        }
                        <div className="line"/>

                        {program_promotion.type === "GIFT_CARD" &&program_promotion.purchase == null &&
                        <div>
                           <div className="alert alert-info justify-content-md-center">
                              <p className="title">Aún no tienes crédito en esta promoción</p>
                           </div>
                        </div>
                        }
                        {program_promotion.type === "GIFT_CARD" && program_promotion.purchase != null &&
                        <div>
                           {
                              program_promotion.purchase.total_balance <= 0.0 &&
                              <div className="alert alert-info justify-content-md-center">
                                 <span className="title">No te queda saldo en esta promoción</span>
                                 <div>
                                    <button type="button" className="btn  btn-sm btn-outline-primary">Ver Consumos
                                    </button>
                                 </div>
                              </div>
                           }
                           {
                              program_promotion.purchase.total_balance > 0.0 &&
                              <div className="alert alert-info justify-content-md-center">
                                 <span
                                    className="title">Tienes un saldo de s/ {program_promotion.purchase.total_balance} en esta promoción</span>


                              </div>

                           }

                        </div>
                        }
                        {show_place_description && (
                           <div>
                              <div className="justify-content-md-center">
                                 <p className="title">El establecimiento:</p>
                              </div>
                              <p className="terms-and-conditions">
                                 {program_promotion.company_retail.description}
                              </p>
                              <div className="line"/>
                           </div>
                        )}
                        {program_promotion.content !== null &&
                        program_promotion.content !== "" &&
                        program_promotion.content !== "." && (
                           <div>
                              <div className="justify-content-md-center">
                                 <p className="title">El beneficio:</p>
                              </div>
                              <p className="terms-and-conditions">
                                 {program_promotion.content}
                              </p>
                              <div className="line"/>
                           </div>
                        )}

                        {terms !== "" && (
                           <div>
                              <div className="justify-content-md-center">
                                 <p className="title">Términos y Condiciones</p>
                              </div>
                              <p className="terms-and-conditions">{terms}</p>
                              <div className="line"/>
                           </div>
                        )}
                        {program_promotion.type === "GIFT_CARD" && program_promotion.purchase != null &&
                        <div>

                           {
                              program_promotion.purchase.total_balance > 0.0 &&
                              <div className="justify-content-md-center">
                                 <div className={"d-flex"}>
                                    <button onClick={e=>this.loadRedemptions()} type="button" className="btn btn-redemptions">
                                       { loaderRedemptions === true?"Cargando...":"Cargar Consumos" }
                                       <DownIcon />
                                    </button>
                                 </div>

                                 <InfiniteScroll
                                    className="redemptions-section"
                                    pageStart={0}
                                    loadMore={this.loadMoreRedemptions.bind(this)}
                                    hasMore={moreRedemptions}
                                    loader={loaderMoreRedemtions}
                                 >
                                    {this.renderRedemptions()}
                                 </InfiniteScroll>
                              </div>
                           }

                        </div>
                        }
                        {!program_promotion.is_digital_entity && (
                           <div className="justify-content-md-center">
                              <p className="title">Locales</p>
                           </div>
                        )}
                        {!program_promotion.is_digital_entity && (
                           <InfiniteScroll
                              className="container-places"
                              pageStart={0}
                              loadMore={this.loadMore.bind(this)}
                              hasMore={more}
                              loader={loaderMore}
                           >
                              {this.renderPlaces()}
                           </InfiniteScroll>
                        )}
                     </div>
                  </div>
               </div>
            </div>
         );
      }
   }
}

DetailProgramPromotion.defaultProps = {
   program_promotion: null,
   isFetchingProgramPromotion: false,
   benefit_program: null,
   places: [],
   loadingPLacesMore: false,
   loaderRedemptions: false,
   isLoadingPayment: false,
   nextRedemptions: null,
   redemptions: []
};

export default connect(({places, programPromotions, benefitPrograms, redemptions}) => ({
   benefit_program: benefitPrograms.benefitProgram,
   program_promotion: programPromotions.program_promotion,
   isLoadingPayment: programPromotions.isLoadingPayment,
   isFetchingProgramPromotion: programPromotions.isFetchingProgramPromotion,
   places: places.data,
   loadingPLaces: places.isFetching,
   loadingPLacesMore: places.isFetchingMore,
   next: places.next,
   loaderRedemptions: redemptions.isFetching,
   nextRedemptions: redemptions.next,
   redemptions: redemptions.data
}))(DetailProgramPromotion);
