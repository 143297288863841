
export const LOGIN = "LOGIN";
export const USER_GET = "USER_GET";
export const GET_APP = "GET_APP";
export const USER_DATA_BOC_GET = "USER_DATA_BOC_GET";
export const CLIENT_DATA_BOC_GET = "CLIENT_DATA_BOC_GET";
export const USER_DATA_BOC_CREATE = "USER_DATA_BOC_CREATE";
export const USER_EMAIL_DATA_BOC_CREATE = "USER_EMAIL_DATA_BOC_CREATE";
export const COMPANY_GET = "COMPANY_GET";
export const USER_PATCH = "USER_PATCH";
export const EDIT_CITY = "EDIT_CITY";
export const SEND_SMS = "SEND_SMS";
export const VERIFY_SMS = "VERIFY_SMS";


export const GET_APP_FIELDS = "GET_APP_FIELDS";



export const VERIFY = "VERIFY";
export const REGISTER = "REGISTER";
export const UPDATE = "UPDATE";
export const RECOVERY = "RECOVERY";
export const LOGOUT = "LOGOUT";
export const BENEFIT_PROGRAM_GET = "BENEFIT_PROGRAM_GET";
export const SET_BENEFIT_PROGRAM = "SET_BENEFIT_PROGRAM";
export const BENEFIT_PROGRAM_GET_V5 = "BENEFIT_PROGRAM_GET_V5";
export const BENEFIT_PROGRAM_V5 = "BENEFIT_PROGRAM";
export const BENEFIT_PROGRAMS = "BENEFIT_PROGRAMS";
export const TAGS = "TAGS";
export const FECH_ENTITIES = "FECH_ENTITIES";
export const CLEAN_ENTITIES = "CLEAN_ENTITIES";
export const FECH_MORE_ENTITIES = "FECH_MORE_ENTITIES";
export const FECH_OFFERS = "FECH_OFFERS";

export const CLEAN_OFFERS = "CLEAN_OFFERS";
export const DETAIL_RAFFLE = "DETAIL_RAFFLE";
export const SEND_RAFFLE = "SEND_RAFFLE";
export const CLEAN_RAFFLE = "CLEAN_RAFFLE";
export const SHOW_MODAL_RAFFLE = "SHOW_MODAL_RAFFLE";
export const DETAIL_OFFER = "DETAIL_OFFER";
export const DETAIL_OFFER_INTEREST = "DETAIL_OFFER_INTEREST";
export const TRANSFER_OFFER = "TRANSFER_OFFER";

export const PAY_PROGRAM_PROMOTION = "PAY_PROGRAM_PROMOTION";
export const CLEAN_PAYMENT = "CLEAN_PAYMENT";
export const DETAIL_NOTIFICATION = "DETAIL_NOTIFICATION";
export const DETAIL_ENTITY = "DETAIL_ENTITY";
export const TAGS_FETCH = "TAGS_FETCH";
export const FECH_BOXES = "FECH_BOXES";
export const DETAIL_BOX = "DETAIL_BOX";
export const BOX_CHANGE = "BOX_CHANGE";
export const CLEAN_BOXES = "CLEAN_BOXES";
export const BANNERS_FETCH = "BANNERS_FETCH";
export const CLEAN_BANNERS = "CLEAN_BANNERS";
export const POLLS_FETCH = "POLLS_FETCH";
export const POLL_DETAIL = "POLL_DETAIL";
export const SEND_POLL = "SEND_POLL";
export const POPUPS_FETCH = "POPUPS_FETCH";
export const LOCALITIES_FETCH = "LOCALITIES_FETCH";
export const NOTIFICATIONS_FETCH = "NOTIFICATIONS_FETCH";
export const NOTIFICATIONS_MORE_FETCH = "NOTIFICATIONS_MORE_FETCH";
export const PLACES_FETCH = "PLACES_FETCH";
export const PLACES_MORE_FETCH = "PLACES_MORE_FETCH";
export const REDEMPTIONS_FETCH = "REDEMPTIONS_FETCH";
export const REDEMPTIONS_MORE_FETCH = "REDEMPTIONS_MORE_FETCH";
export const DETAIL_PLACE = "DETAIL_PLACE";
export const COUNTRIES_FETCH = "COUNTRIES_FETCH";
export const TAGS_CHANGE = "TAGS_CHANGE";
export const CHANGE_MENU = "CHANGE_MENU";
export const VALIDATE_CODE = "VALIDATE_CODE";

export const FECH_POINTS = "FECH_POINTS";
export const FECH_MORE_POINTS = "FECH_MORE_POINTS";
export const CLEAN_POINTS = "CLEAN_POINTS";

//program promotions
export const GET_PROGRAM_PROMOTIONS = "GET_PROGRAM_PROMOTIONS";
export const MORE_PROGRAM_PROMOTIONS = "MORE_PROGRAM_PROMOTIONS";
export const DETAIL_PROGRAM_PROMOTION = "DETAIL_PROGRAM_PROMOTION";
export const RELATED_PROMOTIONS = "RELATED_PROMOTIONS";
export const CLEAN_PROGRAM_PROMOTIONS = "CLEAN_PROGRAM_PROMOTIONS";

//MENU
export const HOME = "HOME";
export const COMPANY_PLACES = "COMPANY_PLACES";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const PROFILE = "PROFILE";
export const POLLS = "POLLS";
