import * as types from "../common/constants/actionTypes";

const initialState = {
   data: null,
   next: null,
   isFetching: false,
   isFetchingMore: false,
   place: null
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case `${types.REDEMPTIONS_FETCH}_PENDING`:
         return { ...state, isFetching: true, next: null };
      case `${types.REDEMPTIONS_FETCH}_REJECTED`:
         return { ...state, isFetching: false, error: action.payload };
      case `${types.REDEMPTIONS_FETCH}_FULFILLED`:
         if (action.payload.status === 200) {
            return Object.assign({}, state, {
               isFetching: false,
               data: action.payload.data.results,
               next: action.payload.data.next
            });
         } else {
            return Object.assign({}, state, {
               isFetching: false,
               data: []
            });
         }
      case `${types.REDEMPTIONS_MORE_FETCH}_PENDING`:
         return { ...state, isFetching: true };
      case `${types.REDEMPTIONS_MORE_FETCH}_REJECTED`:
         return { ...state, isFetching: false, error: action.payload };
      case `${types.REDEMPTIONS_MORE_FETCH}_FULFILLED`:
         let data = state.data;
         for (var i = 0; i < action.payload.data.results.length; i++) {
            data.push(action.payload.data.results[i]);
         }
         return Object.assign({}, state, {
            isFetching: false,
            data: data,
            next: action.payload.data.next
         });

      default:
         return state;
   }
}
