import HttpClient from "../../common/utils/httpClient";
import {POLL_DETAIL, POLLS_FETCH, SEND_POLL} from "../../common/constants/actionTypes";


export function getPolls(programId) {
  return dispatch => HttpClient.get(dispatch, POLLS_FETCH, `v5/me/programs/${ programId }/polls/`);
}

export function sendPoll(programId, pollUid, data) {
    return dispatch => HttpClient.post(data, dispatch, SEND_POLL, `v5/me/polls/${pollUid}/responses/`);
}

export function getPoll(programId, pollUid) {
    return dispatch => HttpClient.get(dispatch, POLL_DETAIL, `v5/me/programs/${ programId }/polls/${pollUid}/`);
}




