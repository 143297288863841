import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import HomeIcon from "./icons/HomeIcon";
import BellIcon from "./icons/BellIcon";
import UserIcon from "./icons/UserIcon";
import PollIcon from "./icons/PollsIcon";
import PlaceIcon from "./icons/PlaceIcon";
import ExitIcon from "./icons/ExitIcon";

import validation_icon from "../../common/images/success_icon.png";
import {TextDefaul} from "../../common/utils/generic";
import Filters from "../Filters/Filters";
import {logoutUser} from "../../reducersapp/auth";
import firebase from "firebase"
import {
   changeBox,
   changeTag,
   cleanBanners,
   cleanBoxes,
   cleanOffers,
   cleanProgramPromotions,
   getBoxes,
   getTags
} from "./actions";
import {COMPANY_PLACES, HOME, NOTIFICATIONS, POLLS, PROFILE} from "../../common/constants/actionTypes";
import ExitIconBC from "./icons/ExitIconBC";
import BellIconBC from "./icons/BellIconBC";


class SecondaryMenu extends Component {
   constructor(props) {
      super(props);
      this.toggle = this.toggle.bind(this);
      this.state = {
         isOpen: false,
         isLoading: true,
         selectTag: null,
         uidTag: "",
         uidCity: "",
         tags: [],
         boxes: [],
         current_user: null,
         app_menu_show_points: false,
         app_menu_show_notifications: false,
         app_menu_show_locals: false,
         app_menu_show_preferences: false,
         app_menu_show_polls: false,
         app_menu_show_all_sections: false,
         app_menu_show_validate_code: false

      };
   }

   /**
    *  Cambio manejado a travez de URL
    *
    */
   componentDidMount() {
      const { selectTag, match, user } = this.props;

      let firebaseConfig = firebase.remoteConfig()
      if(firebaseConfig!=null){
         this.setState({
            app_menu_show_points: firebaseConfig.getBoolean("app_menu_show_points"),
            app_menu_show_notifications: firebaseConfig.getBoolean("app_menu_show_notifications"),
            app_menu_show_locals: firebaseConfig.getBoolean("app_menu_show_locals"),
            app_menu_show_preferences: firebaseConfig.getBoolean("app_menu_show_preferences"),
            app_menu_show_polls: firebaseConfig.getBoolean("app_menu_show_polls"),
            app_menu_show_all_sections: firebaseConfig.getBoolean("app_menu_show_all_sections"),
            app_menu_show_validate_code:firebaseConfig.getBoolean("app_menu_show_validate_code"),
         })
      }
      this.setState({ current_user: user.uid });

      const uidUrl = match.params.uid;
      const uidBox = match.params.uidBox;
      if (uidBox === undefined) {
         this.props.dispatch(changeBox(null));
      }
      if (uidUrl !== undefined && uidUrl !== "all") {
         if (selectTag != null) {
            if (selectTag.uid !== uidUrl) {
               this.props.dispatch(changeTag(uidUrl));
            }
         } else {
            try{
               firebase.analytics().logEvent('click_section_web',{
                  section_uid: uidUrl,
                  url: match.url
               });
            }catch (ignored) {

            }

            this.props.dispatch(changeTag(uidUrl));
         }
         this.props.dispatch(changeBox(null));
      }
      if (this.props.tags.length <= 0) {
         this.props.dispatch(getTags(this.props.benefit_program.uid));
         this.props.dispatch(cleanBanners());
      } else {
      }
      if (this.props.boxes.length <= 0) {
         this.props.dispatch(getBoxes(this.props.benefit_program.uid));
      }
   }




   componentDidUpdate(prevProps, prevState) {
      const { selectTag, match } = this.props;

      const uidUrl = match.params.uid;
      const uidBox = match.params.uidBox;

      if (uidBox === undefined) {
         this.props.dispatch(changeBox(null));
      }
      if (
         (uidUrl === undefined || uidUrl === "all") &&
         this.props.selectTag != null
      ) {
         this.props.dispatch(changeTag(null));
         this.props.dispatch(cleanBanners());
      } else {
         if (selectTag != null) {
            if (selectTag.uid !== uidUrl) {
               this.props.dispatch(changeTag(uidUrl));
            }
         } else {
            this.props.dispatch(changeTag(uidUrl));
         }
      }
   }

   toggle() {
      this.setState({
         isOpen: !this.state.isOpen
      });
   }

   renderTags = () => {
      const { tags, isFetching, selectTag, menu } = this.props;
      if (isFetching) return null;
      if (tags.length === 0) return "";
      return tags.map(tag => {
         if (selectTag != null)
            if (selectTag.uid === tag.uid && menu === HOME) {
               return (
                  <li key={tag.uid}>
                     <Link className="select" to={`/category/${tag.uid}/`}>
                        {tag.name}
                     </Link>
                     <Filters/>
                  </li>
               );
            } else {
               return (
                  <li key={tag.uid}>
                     <Link to={`/category/${tag.uid}/`}>{tag.name}</Link>
                  </li>
               );
            }
         else
            return (
               <li key={tag.uid}>
                  <Link to={`/category/${tag.uid}/`}>{tag.name}</Link>
               </li>
            );
      });
   };

   renderBoxes = () => {
      const { boxes, isFetchingBoxes, selectBox, menu } = this.props;
      if (isFetchingBoxes) return null;
      if (boxes.length === 0) return "";
      return boxes.map(box => {
         if (selectBox != null)
            if (selectBox === box.uid && menu === HOME) {
               return (
                  <li key={box.uid}>
                     <Link className="select" to={`/boxes/${box.uid}/`}>
                        {box.title}
                     </Link>
                  </li>
               );
            } else {
               return (
                  <li key={box.uid}>
                     <Link to={`/boxes/${box.uid}/`}>{box.title}</Link>
                  </li>
               );
            }
         else
            return (
               <li key={box.uid}>
                  <Link to={`/boxes/${box.uid}/`}>{box.title}</Link>
               </li>
            );
      });
   };

   closeSession = async event => {
      event.preventDefault();
      let name = process.env.REACT_APP_NAME_APP;
      window.localStorage.removeItem(`token${name}`);
      this.props.dispatch(cleanBoxes());
      this.props.dispatch(cleanProgramPromotions());
      this.props.dispatch(cleanOffers());
      this.props.dispatch(logoutUser());
      setTimeout(() => {
         (document.location.href = '/login');
         return true;
      }, 1000);
   };

   render() {
      const { user, selectTag, selectBox, menu } = this.props;
      const fecha = new Date();
      const options = { weekday: 'long', month: 'long', day: 'numeric' };
      let date_formated = fecha.toLocaleDateString("es-ES", options).replace(',', '').replace(' de', ',')

      let that = this;
      return (
         <div className="sidebar-sticky">
            <div className="col user ">
               <p>
                  {
                     date_formated
                  }
                  <br />
                  <span> {user.first_name} </span>
               </p>
            </div>
            <div className="col" style={{ paddingRight: 0 }}>
               <div className="left-section">
                  {/* <span className="title">Filtrar Por</span>  */}
                  <ul>
                     <li
                        className={`header-list option ${
                           menu === HOME ? "select" : ""
                        }`}
                        data-toggle="collapse"
                        href="#collapseTags"
                        aria-expanded="false"
                        aria-controls="collapseTags"
                     >
                        <HomeIcon />
                        <span>Inicio</span>
                     </li>
                     <div className="collapse show" id="collapseTags">
                        <li className="body-list">
                           <ul>
                              {this.state.app_menu_show_all_sections && (
                                 <li>
                                    {selectTag == null &&
                                       selectBox == null && (
                                          <Link
                                             to={`/category/all/`}
                                             className={`${
                                                menu === HOME ? "select" : ""
                                             }`}
                                          >
                                             Ver Todo
                                          </Link>
                                       )}
                                    {(selectTag != null ||
                                       selectBox != null) && (
                                       <Link to={`/category/all/`}>
                                          Ver Todo
                                       </Link>
                                    )}
                                 </li>
                              )}
                              {that.renderBoxes()}
                              {that.renderTags()}
                           </ul>
                        </li>
                     </div>
                     <li
                        className={`header-list option ${
                           menu === NOTIFICATIONS ? "select" : ""
                        }`}
                     >
                        <Link to={`/notifications`} className={"menu-option"}>
                           {
                              process.env.REACT_APP_NAME_APP==="bancoelcomercio" ?
                                 <BellIconBC/>
                                 :
                                 <BellIcon/>
                           }
                           <span>Notificaciones</span>
                        </Link>
                     </li>

                     {this.state.app_menu_show_polls === true && (
                        <li
                           className={`header-list option ${
                              menu === POLLS ? "select" : ""
                           }`}
                        >
                           <Link to={`/polls`} className={"menu-option"}>
                              <PollIcon />
                              <span>Encuestas</span>
                           </Link>
                        </li>
                     )}

                     {this.state.app_menu_show_locals === true && (
                        <li
                           className={`header-list option ${
                              menu === COMPANY_PLACES ? "select" : ""
                           }`}
                        >
                           <Link to={`/places`} className={"menu-option"}>
                              <PlaceIcon />
                              <span>{TextDefaul.textLocals}</span>
                           </Link>
                        </li>
                     )}

                     {this.state.app_menu_show_validate_code && (
                        <li className="header-list option">
                           <Link to={`/validation`} className={"menu-option"}>
                              <img alt={"validation"} src={validation_icon} />
                              <span>Validar Código</span>
                           </Link>
                        </li>
                     )}


                        <li
                           className={`header-list option ${
                              menu === PROFILE ? "select" : ""
                           }`}
                        >
                           <Link to={`/profile`} className="menu-option">
                              <UserIcon />
                              <span>Mi Perfil</span>
                           </Link>
                        </li>


                     <li className="header-list option">
                        <a href={"/"}
                           onClick={this.closeSession.bind(this)}
                           className="menu-option"
                        >
                           {
                              process.env.REACT_APP_NAME_APP==="bancoelcomercio" ?
                                 <ExitIconBC/>
                                 :
                                 <ExitIcon/>
                           }
                           <span>Salir</span>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      );
   }
}

SecondaryMenu.defaultProps = {
   addNotification: () => {},
   user: {},
   isLoadingBasic: false,
   tags: [],
   boxes: [],
   benefit_program: {},
   isFetching: false,
   selectBox: null,
   isFetchingBoxes: false,
   menu: null
};

export default connect(
   ({ users, boxes, tags, benefitPrograms, countries, menu }) => ({
      user: users.user,
      benefit_program: benefitPrograms.benefitProgram,
      tags: tags.data,
      boxes: boxes.data,
      isFetching: tags.isFetching,
      isFetchingBoxes: boxes.isFetching,
      selectTag: tags.selectTag,
      selectBox: boxes.selectBoxUid,
      areas: countries.data,
      menu: menu.selectMenu
   })
)(SecondaryMenu);
