import React from "react";
import YouTube from "react-youtube";

const NotificationVideo = props => {
  const video = props.video;
  let title = video.title;
  let uid_video = video.video_id;
  const opts = {
    height: "390",
    width: "100%",
    playerVars: {
      autoplay: 1
    }
  };
  return (
    <div className="notification-detail row justify-content-center">
      <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12 video">
        <div className="title">{title}</div>
        <YouTube className="youtube" videoId={uid_video} opts={opts} />
      </div>
    </div>
  );

  // onReady = (event) => {
  //     event.target.pauseVideo();
  // }
};

export default NotificationVideo;
