import * as types from "../common/constants/actionTypes";


const initialState = {
   isFetchingDetail: false,
   detailRaffle: null,
   showModal: null,
   image: null,
};

export default function reducer(state = initialState, action) {

   switch (action.type) {


      case `${types.DETAIL_RAFFLE}_PENDING`:
         return { ...state, isFetchingDetail: true };
      case `${types.DETAIL_RAFFLE}_REJECTED`:
         return { ...state, isFetchingDetail: false, error: action.payload };
      case `${types.DETAIL_RAFFLE}_FULFILLED`:
         let detail = null;
         if (action.payload.data !== undefined && action.payload.data !== null) {
            detail = action.payload.data;
         }
         return Object.assign({},
            state, {
               isFetchingDetail: false,
               detailRaffle: detail
            }
         );

      case `${types.CLEAN_RAFFLE}`:
         return {
            ...state,
            isFetchingDetail: false,
            detailRaffle: null
         };
      case `${types.SHOW_MODAL_RAFFLE}`:
         return {
            ...state,
            showModal: action.showModal,
            image: action.image
         };
      default:
         return state;
   }
}
