/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
   BOX_CHANGE,
   CHANGE_MENU,
   CLEAN_BANNERS,
   CLEAN_BOXES,
   CLEAN_OFFERS,
   CLEAN_PROGRAM_PROMOTIONS,
   EDIT_CITY,
   FECH_BOXES,
   TAGS_CHANGE,
   TAGS_FETCH
} from "../../common/constants/actionTypes";
import HttpClient from "../../common/utils/httpClient";

export function editCity(uidCity) {
   return dispatch =>
      HttpClient.patch({ area: uidCity }, dispatch, EDIT_CITY, "v5/me/");
}


export function getTags(programId) {
   return dispatch =>
      HttpClient.get(
         dispatch,
         TAGS_FETCH,
         `v5/me/programs/${programId}/sections/`
      );
}



export function changeTag(uid) {
   return {
      type: TAGS_CHANGE,
      uid
   };
}

export function cleanOffers() {
   return {
      type: CLEAN_OFFERS,
   };
}

export function getBoxes(programId) {
   return dispatch => HttpClient.get(dispatch, FECH_BOXES, `v5/me/programs/${ programId }/boxes/`);
}

export function changeBox(uid) {
   return {
      type: BOX_CHANGE,
      uid,
   };
}

export function cleanBoxes() {
   return {
      type: CLEAN_BOXES,
   };
}

export function cleanProgramPromotions() {
   return {
      type: CLEAN_PROGRAM_PROMOTIONS,
   };
}

export function cleanBanners() {
   return { type: CLEAN_BANNERS };
}

export function changeMenu(menu) {
   return {
      type: CHANGE_MENU,
      menu
   };
}
