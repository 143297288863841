import React from "react";
import {Link} from "react-router-dom";

const PollItem = props => {
  let web_image = props.poll.web_image;
  let title = props.poll.title;
  let description = props.poll.message;

  return (
    <div className="notification-list col-lg-12">
      <Link to={`/polls/${props.poll.uid}`} className="row ">
        <div className="col-md-auto detail-data">
          <img alt="logo" className="logo" src={web_image} />
        </div>
        <div className="col detail-data align-self-center">
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </Link>
    </div>
  );
};

export default PollItem;
