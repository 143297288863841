import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Page from "../../common/components/Page";
import {getClientDataBoc, getUser} from "../Register/actions";
import moment from "moment";
import {getBenefitProgramsV5, getBenefitProgramV5, setProgram} from "./actions";
import {cleanBoxes, cleanOffers} from "../Menu/actions";
import {logoutUser} from "../../reducersapp/auth";
import {getCountries} from "../../reducersapp/countries";
import {getApp} from "../../reducersapp/app";
import Popups from "../Popups/Popups";
import {Route, Switch} from "react-router";
import MainMenu from "../Menu/MainMenu";
import SecondaryMenu from "../Menu/SecondaryMenu";
import {APP_TYPE_HHRR, TextDefaul} from "../../common/utils/generic";
import ContainerProgramPromotion from "../ProgramPromotions/ContainerProgramPromotion";
import ContainerDetailProgramPromotion from "../ProgramPromotions/ContainerDetailProgramPromotion";
import Notifications from "../Notifications/Notifications";
import DetailNotification from "../Notifications/DetailNotification";
import SearchProgramPromotions from "../ProgramPromotions/SearchProgramPromotions";
import CompanyPlaces from "../CompanyPlaces/CompanyPlaces";
import Rafle from "../Raffle/Rafle";
import PollsList from "../Polls/PollsList";
import Poll from "../Polls/Poll";
import DetailProfileRewards from "../Profile/DetailProfileRewards";
import Points from "../Points/Points";
import InterestedOffer from "../ProgramPromotions/InterestedOffer";
import DetailBox from "../Boxes/DetailBox";
import firebase from "firebase";
const $ = require('jquery');


class Bederr extends Component {
   constructor(props) {
      super(props);
      this.state = {
         benefit_program: null,
         user: {},
         isLoading: true,
         showModal: false,
         maintenance: false,
         app_allow_preferences: false

      };
   }

   static contextTypes = {
      router: PropTypes.object
   };

   getUser = async () => {
      const responseUser = await this.props.dispatch(getUser({}));
      let these = this;
      if (responseUser != null) {

         let {status, data} = responseUser.value;
         if (status === 200) {
            these.setState({user: data});
            if (
               data != null &&
               !data.is_complete &&
               process.env.REACT_APP_NAME_APP !== "mapfre"
            ) {
               these.props.history.push("/complete-data");
            } else {
               these.getBenefitProgram();
            }
         } else {
            if (status === 410) {
               these.setState({maintenance: true});
            } else {
               these.redirectToLogin();
            }
         }


      } else {
         this.redirectToLogin();
      }
   };

   getBenefitProgram = async () => {
      try {
         if (process.env.REACT_APP_TYPE === APP_TYPE_HHRR) {
            const responseBP = await this.props.dispatch(
               getBenefitProgramsV5()
            );
            if (responseBP != null) {

               let {status, data} = responseBP.value;
               if (status === 200) {

                  if(process.env.REACT_APP_NAME_APP==="bancoelcomercio")
                     this.getBocClientDetail();

                  const list = data.results;
                  if (list.length > 0) {
                     const benefit_program = list[0];
                     const name_hhrr = process.env.REACT_APP_NAME_APP;
                     if (
                        benefit_program.color != null &&
                        benefit_program.color.length > 0
                     ) {
                        window.localStorage.setItem(
                           `color${name_hhrr}`,
                           benefit_program.color
                        );
                     }
                     this.setState({benefit_program: benefit_program});
                     this.getBenefitProgramRewardsDetail(
                        benefit_program.uid
                     );
                  } else {
                     this.redirectToLogin();
                  }
               } else {
                  this.redirectToLogin();
               }


            } else {
               this.redirectToLogin();
            }
         } else {

            if(process.env.REACT_APP_NAME_APP==="bancoelcomercio")
               this.getBocClientDetail();

            this.getBenefitProgramRewardsDetail(process.env.REACT_APP_PROGRAM_UID);

         }
      } catch (error) {
         this.redirectToLogin();
      }
   };

   componentDidMount() {
      moment.locale("es");

      let configFirebase = firebase.remoteConfig()
      this.getUser();
      this.props.dispatch(getApp(process.env.REACT_APP_APY_KEY));

      if(configFirebase!=null){
         this.setState({app_allow_preferences: configFirebase.getBoolean("app_allow_preferences")})
      }
      this.props.dispatch(getCountries());

      $('html').removeClass("notOverflow");
   }

   componentWillReceiveProps(nextProps, nextContext) {

      if (nextProps.code === 401) {
         this.redirectToLogin()
      }
   }


   getBenefitProgramRewardsDetail = async uid => {
      try {

         const responseBP = await this.props.dispatch(getBenefitProgramV5(uid));
         let these = this;
         if (responseBP != null) {

            let {status, data} = responseBP.value;
            if (status === 200) {
               const benefitProgram = data;
               these.setState({benefit_program: benefitProgram});
               these.props.dispatch(setProgram(benefitProgram));
               these.setState({isLoading: false});
               let isPollClosed = "false";
               if (typeof window !== undefined) {
                  isPollClosed = window.localStorage.getItem(`closePoll`);
               }

               if (
                  this.state.app_allow_preferences &&
                  benefitProgram.tags !== undefined &&
                  benefitProgram.tags !== null &&
                  benefitProgram.tags.length === 0 &&
                  (isPollClosed === "false" || isPollClosed == null)
               ) {
                  these.props.history.push("/preferences");
               }
            } else {
               these.redirectToLogin();
            }


         } else {
            this.redirectToLogin();
         }
      } catch (error) {
         this.redirectToLogin();
      }
   };
   getBocClientDetail = async () => {
      try {

         const responseBoc = await this.props.dispatch(getClientDataBoc());
         let these = this;
         if (responseBoc != null) {

            let {status, data} = responseBoc.value;
            if (status === 200) {
               const user = data;
               console.log(user)
            } else {
               these.redirectToLogin();
            }


         } else {
            this.redirectToLogin();
         }
      } catch (error) {
         this.redirectToLogin();
      }
   };


   redirectToLogin = () => {
      let name = process.env.REACT_APP_NAME_APP;
      window.localStorage.removeItem(`token${name}`);
      this.props.dispatch(cleanBoxes());
      this.props.dispatch(cleanOffers());
      this.props.dispatch(logoutUser());
   };

   render() {
      const {isUserLoad, isUserChange} = this.props;
      if (this.state.isLoading) {
         return (
            <div className="overload">
               <p>Cargando</p>
            </div>
         );
      }
      return (
         <Page
            id="bederr"
            title={TextDefaul.webTitle}
            description={TextDefaul.webDescription}
         >
            {this.state.maintenance === false && (
               <div className="content">
                  <Route path="/" component={Popups}/>
                  <div
                     id="header"
                     className="main-header flex-column flex-md-row sticky-top"
                  >
                     <MainMenu {...this.props}  />
                  </div>
                  <div className="container-fluid">
                     <div className="row">
                        <div
                           className="collapse col-md-3 col-lg-2 bg-light sidebar secondary-menu"
                           id="navbarFilters"
                        >
                           <Route exact path="/" component={SecondaryMenu}/>
                           <Route
                              path="/category/:uid"
                              component={SecondaryMenu}
                           />
                           <Route
                              path="/boxes/:uidBox"
                              component={SecondaryMenu}
                           />
                           <Route path="/offers" component={SecondaryMenu}/>
                           <Route path="/benefits" component={SecondaryMenu}/>
                           <Route path="/places" component={SecondaryMenu}/>
                           <Route
                              path="/notifications"
                              component={SecondaryMenu}
                           />
                           <Route path="/polls" component={SecondaryMenu}/>
                           <Route path="/profile" component={SecondaryMenu}/>
                           <Route
                              path="/validation"
                              component={SecondaryMenu}
                           />
                        </div>
                        <div className="col-md-9 ml-sm-auto col-lg-10 home-boc">
                           <Switch>
                              <Route
                                 path="/category/:uid"
                                 component={ContainerProgramPromotion}
                              />
                              <Route
                                 exact
                                 path="/"
                                 component={ContainerProgramPromotion}
                              />
                              <Route
                                 exact
                                 path="/offers/:uidProgramPromotion"
                                 component={ContainerDetailProgramPromotion}
                              />
                              <Route
                                 exact
                                 path="/notifications"
                                 component={Notifications}
                              />
                              <Route
                                 exact
                                 path="/notifications/:uidNotification"
                                 component={DetailNotification}
                              />

                              <Route
                                 exact
                                 path="/benefits"
                                 component={SearchProgramPromotions}
                              />

                              <Route
                                 exact
                                 path="/places"
                                 component={CompanyPlaces}
                              />

                              <Route
                                 path="/offers/:uidOffer/raffle/:uidRaffle"
                                 component={Rafle}
                              />

                              <Route
                                 exact
                                 path="/polls"
                                 component={PollsList}
                              />

                              <Route
                                 exact
                                 path="/polls/:uidPoll"
                                 component={Poll}
                              />

                              {/*{this.state.app_profile_type === PROFILE_SIMPLE && (
                                 <Route
                                    exact
                                    path="/profile"
                                    component={DetailProfile}
                                 />
                              )}*/}

                                 <Route
                                    exact
                                    path="/profile"
                                    component={DetailProfileRewards}
                                 />



                              <Route
                                 exact
                                 path="/profile/points"
                                 component={Points}
                              />


                              <Route
                                 path="/boxes/:uidBox"
                                 component={DetailBox}
                              />
                              <Route
                                 exact
                                 path="/offers/:uidOffer/interest"
                                 component={InterestedOffer}
                              />

                           </Switch>
                        </div>
                     </div>
                  </div>
               </div>
            )}
            {(isUserLoad || isUserChange) && (
               <div className="overload">
                  {/*<FoldingCube size={100} color={color}/>*/}
               </div>
            )}
            {this.state.maintenance && (
               <div className="overload">
                  <p>
                     Actualmente nos encontramos en Mantenimiento. <br/> En las
                     proximas horas estaremos en lines con muchas mejoras.
                  </p>
               </div>
            )}
         </Page>
      );
   }
}

Bederr.propTypes = {
   dispatch: PropTypes.func,
   match: PropTypes.object,
   isUserLoad: PropTypes.bool
};
export default connect(({users}) => ({
   isUserLoad: users.isFetching,
   user: users.user,
   code: users.code,
   isUserChange: users.changeInUser
}))(Bederr);
