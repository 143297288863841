import * as types from "../common/constants/actionTypes";

const initialState = {
  data: [],
  isFetching: false,
  isFetchingMore: false,
  error: "",
  count: 0,
  next: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${types.FECH_POINTS}_PENDING`:
      return { ...state, isFetching: true, data: [] };
    case `${types.FECH_POINTS}_REJECTED`:
      return { ...state, isFetching: false, error: action.payload, data: [] };
    case `${types.FECH_POINTS}_FULFILLED`:
      if (
        action.payload != null &&
        action.payload.data !== undefined &&
        action.payload.status === 200
      ) {
        return Object.assign({}, state, {
          isFetching: false,
          data: action.payload.data.results,
          error: "",
          next: action.payload.data.next,
          count: action.payload.data.count
        });
      } else {
        return Object.assign({}, state, {
          data: [],
          isFetching: false,
          error: "",
          next: null,
          count: 0
        });
      }

    case `${types.CLEAN_POINTS}`:
      return {
        data: [],
        isFetching: false,
        error: "",
        count: 0,
        next: null
      };

    case `${types.FECH_MORE_POINTS}_PENDING`:
      return { ...state, isFetchingMore: true };
    case `${types.FECH_MORE_POINTS}_REJECTED`:
      return { ...state, isFetchingMore: false, error: action.payload };
    case `${types.FECH_MORE_POINTS}_FULFILLED`:
      if (
        action.payload != null &&
        action.payload.data !== undefined &&
        action.payload.status === 200
      ) {
        let data = state.data;
        if (action.payload.data.results != null) {
          for (let i = 0; i < action.payload.data.results.length; i++) {
            data.push(action.payload.data.results[i]);
          }
        }
        return {
          ...state,
          isFetchingMore: false,
          data: data,
          next: action.payload.data.next,
          count: action.payload.data.count
        };
      } else {
        return Object.assign({}, state, {
          isFetching: false,
          error: "",
          next: null,
          count: 0
        });
      }
    default:
      return state;
  }
}
