import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {changeBox, getBox} from "./actions";
import Promotion from "../../common/components/widgets/Promotion";
import {HOME} from "../../common/constants/actionTypes";
import {changeMenu, changeTag} from "../Menu/actions";
import "./styles.scss"


class DetailBox extends Component {
   static contextTypes = {
      router: PropTypes.object
   };

   state = {
      idBox: ""
   };



   componentDidMount() {
      const {match, benefit_program} = this.props;
      const idBox = match.params.uidBox;

      if (idBox !== undefined || idBox != null || idBox !== "all") {
         this.props.dispatch(getBox(benefit_program.uid, idBox));
         this.props.dispatch(changeBox(idBox));
         this.props.dispatch(changeTag(null));
         this.props.dispatch(changeMenu(HOME));
      }
   }

   componentWillReceiveProps(nextProps, nextContext) {
      const {match, benefit_program} = nextProps;
      const idBox = match.params.uidBox;
      const path = match.path;
      if (path === "/boxes") {
      } else {
         if (idBox !== this.state.idBox) {
            this.props.dispatch(getBox(benefit_program.uid, idBox));
            this.props.dispatch(changeBox(idBox));
            this.props.dispatch(changeTag(null));
            this.setState({idBox: idBox});
         }
      }
   }

   renderProgramPromotions() {
      const {detailBox} = this.props;
      if (detailBox == null) {
         return <div className="content-no-items"/>;
      }
      if (detailBox.promotions.length === 0) {
         return (
            <div className="content-no-items">
               <p>No pudimos encontrar beneficios</p>
            </div>
         );
      } else {
         return detailBox.promotions.map((promotion, index) => (
            <Promotion
               is_locked={detailBox.is_locked}
               key={index}
               promotion={promotion}
            />
         ));
      }
   }

   render() {
      const {loader, detailBox} = this.props;
      let that = this;

      if (loader || detailBox == null) {
         return (
            <div className="row content-loader">
               <p>Cargando...</p>
            </div>
         );
      }

      return (
         <div style={{position: "relative"}}>
            <div className="row">
               <div className="col-lg-12">
                  <h3 className="title-section bold ">{detailBox.title}</h3>
               </div>
               <div className="col-lg-12">
                  <h5 className="title-section bold ">
                     Te quedan {detailBox.usage_limit - detailBox.used_promotions} de{" "}
                     {detailBox.usage_limit}
                  </h5>
               </div>
               <div className="col-lg-12">
                  <p className={"description-box"}>{detailBox.description}</p>
               </div>
            </div>
            <div className="entities-section row">
               {that.renderProgramPromotions()}
            </div>
         </div>
      );
   }
}

DetailBox.defaultProps = {
   addNotification: () => {
   },
   selectBox: null,
   detailBox: null,
   loader: false
};

export default connect(({boxes, benefitPrograms}) => ({
   selectBox: boxes.selectBox,
   detailBox: boxes.detailBox,
   loader: boxes.isFetchingDetail,
   benefit_program: benefitPrograms.benefitProgram
}))(DetailBox);
