import React from "react";
import {Link} from "react-router-dom";

const Notification = props => {
   /*
   let logo = "";
   if (
      props.notification.company !== null &&
      props.notification.company.logo !== null
   ) {
      logo = props.notification.company.logo;
   } else {
      logo = "../images/bghome.jpg";
   }

    */
   let title = props.notification.title;
   let description = props.notification.message;

   let web_image = "";

   if (
      props.notification.object.image
   ) {
      web_image = props.notification.object.image
   } else {
      if (props.notification && props.notification.object && props.notification.object.poll && props.notification.object.poll.image)
         web_image = props.notification.object.poll.image
   }

   return (
      <div className="notification-list col-lg-12">
         <Link to={`/notifications/${props.notification.uid}`} className="row ">
            <div className="col-md-auto detail-data">
               <img alt="" className="logo" src={web_image} />
            </div>
            <div className="col detail-data align-self-center">
               <h3>{title}</h3>
               <p>{description}</p>
            </div>
         </Link>
      </div>
   );
};

export default Notification;
