import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import {getMoreNotifications, getNotifications} from "./actions";
import Notification from "../../common/components/widgets/Notification";

import {NOTIFICATIONS} from "../../common/constants/actionTypes";
import {changeMenu} from "../Menu/actions";

import "./style.scss"


class Notifications extends Component {
  static contextTypes = {
    router: PropTypes.object
  };




  componentDidMount() {
     this.props.dispatch(getNotifications());
     this.props.dispatch(changeMenu(NOTIFICATIONS));
  }

   renderNotifications() {
    const { notifications } = this.props;
    if (notifications != null) {
      if (notifications.length === 0) {
        return (
          <div className="content-no-items">
            <p>No encontrarmos notificaciones</p>
          </div>
        );
      } else {
        return notifications.map((notification, index) => (
          <Notification key={index} notification={notification} />
        ));
      }
    }
  }

  loadMore = () => {
    const { next, moreLoader } = this.props;
    if (!moreLoader && next != null) {
      this.props.dispatch(getMoreNotifications(next));
    }
  };

  render() {
    const { loader, next, notifications } = this.props;
    let more = next != null;
    if (loader || notifications == null) {
      return (
        <div className="row content-loader">
          <p>Cargando...</p>
        </div>
      );
    }
    var loaderMore = (
      <div
        key={0}
        className="col-lg-12 content-loader"
        style={{ display: "inline-flex" }}
      >
        <p>Cargando...</p>
      </div>
    );
    return (
      <div>
        <div className="container left-container">
          <h3 className="title-section">Notificaciones</h3>
        </div>
        <div
          className="container left-container"
          style={{ position: "relative" }}
        >
          <InfiniteScroll
            className="notification-section row"
            pageStart={0}
            loadMore={this.loadMore.bind(this)}
            hasMore={more}
            loader={loaderMore}
          >
            {this.renderNotifications()}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

Notifications.defaultProps = {
  addNotification: () => {},
  notifications: [],
  moreLoader: false,
  loader: false
};

export default connect(({ notifications }) => ({
  notifications: notifications.data,
  loader: notifications.isFetching,
  moreLoader: notifications.isFetchingMore,
  next: notifications.next
}))(Notifications);
