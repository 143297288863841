import * as types from "../common/constants/actionTypes";

const initialState = {
   data: [],
   isFetching: false,
   isFetchingMore: false,
   error: null,
   count: 0,
   next: null,
   previous: null,
   program_promotion: null,
   isFetchingProgramPromotion: false,
   isLoadingRelated: false,
   isLoadingPayment: false,
   errorPayment: null,
   successPayment: null,

   errorTransfer: null,
   successTransfer: null,
   isLoadingTransfer: false,

   errorRelated: "",
   dataRelated: false
};

export default function reducer(state = initialState, action) {
   switch (action.type) {

      case `${types.GET_PROGRAM_PROMOTIONS}_PENDING`:
         return {...state, isFetching: true};
      case `${types.GET_PROGRAM_PROMOTIONS}_REJECTED`:
         return {...state, isFetching: false, error: action.payload};
      case `${types.GET_PROGRAM_PROMOTIONS}_FULFILLED`:
         return {
            ...state,
            isFetching: false,
            data: action.payload.data.results,
            next: action.payload.data.next,
            count: action.payload.data.count
         };
      case `${types.MORE_PROGRAM_PROMOTIONS}_PENDING`:
         return {...state, isFetchingMore: true};
      case `${types.MORE_PROGRAM_PROMOTIONS}_REJECTED`:
         return {...state, isFetchingMore: false, error: action.payload};
      case `${types.MORE_PROGRAM_PROMOTIONS}_FULFILLED`:
         let data = state.data;
         for (let i = 0; i < action.payload.data.results.length; i++) {
            data.push(action.payload.data.results[i])
         }
         return {
            ...state,
            isFetchingMore: false,
            data: data,
            next: action.payload.data.next,
            count: action.payload.data.count
         };

      case `${types.PAY_PROGRAM_PROMOTION}_PENDING`:
         return {...state, isLoadingPayment: true, successPayment: null, errorPayment: null};
      case `${types.PAY_PROGRAM_PROMOTION}_REJECTED`:
         return {
            ...state,
            isLoadingPayment: false,
            successPayment: null,
            errorPayment: {user_message: "La transacción no se pudo ejecutar"}
         };
      case `${types.PAY_PROGRAM_PROMOTION}_FULFILLED`:
         let successPayment = null;
         let errorPayment = null;
         if (action.payload === undefined) {
            errorPayment = {user_message: "Algo salió mal intentelo nuevamente"}
         } else if (action.payload.status === 200 || action.payload.status === 201) {
            successPayment = action.payload.data
         } else if (action.payload.data !== undefined && action.payload.data !== null) {
            if(action.payload.code === 404){
               errorPayment =  {
                  user_message: 'Mil disculpas pero el producto solicitado por el momento no está disponible'
               }
            }else{
               errorPayment = action.payload.data
            }
         }
         return {
            ...state,
            isLoadingPayment: false, successPayment: successPayment, errorPayment: errorPayment
         };

      case `${types.TRANSFER_OFFER}_PENDING`:
         return {...state, isLoadingTransfer: true, successTransfer: null, errorTransfer: null};
      case `${types.TRANSFER_OFFER}_REJECTED`:
         return {
            ...state,
            isLoadingTransfer: false,
            successTransfer: null,
            errorTransfer: {user_message: "La transacción no se pudo ejecutar"}
         };
      case `${types.TRANSFER_OFFER}_FULFILLED`:
         let successTransfer = null;
         let errorTransfer = null;
         if (action.payload === undefined) {
            errorTransfer = {user_message: "Algo salió mal intentelo nuevamente"}
         } else if (action.payload.status === 200 || action.payload.status === 201) {
            successTransfer = action.payload.data
         } else if (action.payload.data !== undefined && action.payload.data !== null) {
            if(action.payload.code === 404){
               errorTransfer =  {
                  user_message: 'Mil disculpas pero no se ha podido transferir la promoción'
               }
            }else{
               errorTransfer = action.payload.data
            }
         }
         return {
            ...state,
            isLoadingTransfer: false, successTransfer: successTransfer, errorTransfer: errorTransfer
         };

      case `${types.DETAIL_PROGRAM_PROMOTION}_PENDING`:
         return {
            ...state,
            isFetchingProgramPromotion: true,
            program_promotion: null,
            successPayment: null,
            isLoadingPayment: false,
            errorPayment: null
         };
      case `${types.DETAIL_PROGRAM_PROMOTION}_REJECTED`:
         return {
            ...state,
            isFetchingProgramPromotion: false,
            error: action.payload,
            program_promotion: null,
         };
      case `${types.DETAIL_PROGRAM_PROMOTION}_FULFILLED`:
         return {
            ...state,
            isFetchingProgramPromotion: false,
            program_promotion: action.payload.data
         };

      case `${types.RELATED_PROMOTIONS}_PENDING`:
         return {...state, dataRelated: [], isLoadingRelated: true, errorInterested: false};
      case `${types.RELATED_PROMOTIONS}_REJECTED`:
         return {
            ...state,
            dataRelated: [],
            isLoadingRelated: false,
            errorInterested: true,
            errorRelated: action.payload
         };
      case `${types.RELATED_PROMOTIONS}_FULFILLED`:
         return {
            ...state,
            isLoadingRelated: false,
            dataRelated: action.payload.data.results,
            errorRelated: ""
         };
      case `${types.CLEAN_PROGRAM_PROMOTIONS}`:
         return {
            ...state,
            data: [],
            isFetching: false,
            error: null,
            count: 0,
            next: null,
            previous: null,
            program_promotion: null,
            isFetchingProgramPromotion: false,
            isFetchingMore: false,
            isLoadingInterest: false,
            errorInterested: false,
            successInterested: false,
            isLoadingPayment: false,
            errorPayment: null,
            successPayment: null
         }
      case `${types.CLEAN_PAYMENT}`:
         return {
            ...state, isLoadingPayment: false, successPayment: null, errorPayment: null
         }
      default:
         return state;

   }
}
