import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

var images = require.context("../../common/images", true);


class WelcomePage extends Component {

   redirectHome(){
      let name = process.env.REACT_APP_NAME_APP;
      let token =  window.localStorage.getItem(`token${name}2`);

      window.localStorage.setItem(`token${name}`, token);
      window.localStorage.removeItem(`token${name}2`);
      (document.location.href = '/');
   }

   render() {
      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      let welcomeImg = images(`./welcomeBancoElComercio.png`);

      return (
         <div className="col welcome-page p-0">
            <header
               className="hello"
            >
               <img
                  alt="logo"
                  className="logo"
                  src={logo}
                  style={{cursor: "pointer"}}
                  onClick={this.redirectHome}
               />
            </header>
            <div className={"body"}>
               <p className={"loginExtraText"}>{this.props.first_name ? `¡Hola ${this.props.first_name}!` : "¡Hola!"}</p>
               <p className="subtitle" style={{marginTop: 2}}>Bienvenido a un nuevo mundo de beneficios</p>
               <img
                  alt="img"
                  className="img"
                  src={welcomeImg}
               />
               <button className="btn-start" onClick={this.redirectHome}>
                  Comencemos
               </button>
            </div>

         </div>
      );
   }

   static contextTypes = {
      router: PropTypes.object
   };
}

export default connect()(WelcomePage);
