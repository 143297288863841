/*
 * Home Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import HttpClient from '../../common/utils/httpClient';
import {
   EDIT_CITY,
   REGISTER,
   UPDATE,
   USER_GET,
   USER_PATCH,
   USER_DATA_BOC_GET,
   USER_DATA_BOC_CREATE,
   USER_EMAIL_DATA_BOC_CREATE,
   SEND_SMS,
   VERIFY_SMS,
   CLIENT_DATA_BOC_GET
} from "../../common/constants/actionTypes";


export function registerUser(data) {
   return dispatch =>
      HttpClient.postLogout(data, dispatch, REGISTER, "v5/register/");
}

export function registerUserRewards(data) {
   return dispatch =>
      HttpClient.postLogout(data, dispatch, REGISTER, "v5/register/");
}

export function updateUserRewards(data) {
   return dispatch =>
      HttpClient.patch(data, dispatch, UPDATE, "v5/me/");
}

export function getUser(data) {
   return dispatch => HttpClient.get(dispatch, USER_GET, "v5/me/");
}

export function getUserDataBoc() {
   return dispatch => HttpClient.get(dispatch, USER_DATA_BOC_GET, "v5/boc/me/");
}

export function createUserDataBoc() {
   return dispatch => HttpClient.post({}, dispatch, USER_DATA_BOC_CREATE, "v5/boc/clients/");
}

export function getClientDataBoc() {
   return dispatch => HttpClient.get(dispatch, CLIENT_DATA_BOC_GET, "v5/boc/clients/");
}

export function registerEmailUserDataBoc() {
   return dispatch => HttpClient.post({}, dispatch, USER_EMAIL_DATA_BOC_CREATE, "v5/boc/clients/email/");
}

export function editUser(data) {
   return dispatch =>
      HttpClient.patch(data, dispatch, USER_PATCH, "v5/me/");
}

export function editCity(uidCity) {
   return dispatch =>
      HttpClient.patch({ area: uidCity }, dispatch, EDIT_CITY, "v5/me/");
}

export function sendSMS() {
   return dispatch =>
      HttpClient.post({}, dispatch, SEND_SMS, "v5/boc/notify/mobile-number/");
}


export function verifySMS(data) {
   return dispatch =>
      HttpClient.post(data, dispatch, VERIFY_SMS, "v5/boc/validate/mobile-number/");
}

