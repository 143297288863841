import React from "react";

const Place = props => {
  const { place } = props;

  return (
    <div className=" place-list">
      <div className="data col align-self-center">
        <h3>{place.name}</h3>

        <p>
          <i>Dirección</i>
          <br />
          {place.address}
        </p>
      </div>

      <div className="line" />
    </div>
  );
};
export default Place;

