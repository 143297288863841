import * as types from "../common/constants/actionTypes";

const initialState = {
   data: [],
   isFetching: false,
   selectTag: null
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case `${types.TAGS_FETCH}_PENDING`:
         return { ...state, isFetching: true };
      case `${types.TAGS_FETCH}_REJECTED`:
         return { ...state, isFetching: false, error: action.payload };
      case `${types.TAGS_FETCH}_FULFILLED`:
         let selectTag = null;

         return Object.assign({}, state, {
            isFetching: false,
            data: action.payload.data,
            selectTag: selectTag
         });

      case `${types.TAGS_CHANGE}`:
         if (action.uid != null) {
            const oldSelectTag = state.selectTag;
            const data = state.data;
            let selectTagNew = oldSelectTag;
            if (oldSelectTag != null) {
               let filterTags = data.filter(tag => tag.uid === action.uid);
               if (filterTags.length > 0) {
                  selectTagNew = filterTags[0];
               }
            } else {
               let filterTags = data.filter(tag => tag.uid === action.uid);
               if (filterTags.length > 0) {
                  selectTagNew = filterTags[0];
               }
            }
            return { ...state, selectTag: selectTagNew };
         } else {
            return { ...state, selectTag: null };
         }

      default:
         return state;
   }
}
