import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import Validator from "../../common/utils/validator";
import {TextDefaul} from "../../common/utils/generic";
import {Link} from "react-router-dom";
import {setToken} from "../../reducersapp/auth";
import {verifyUser} from "../Login/actions";
import {registerUser} from "./actions";


var images = require.context("../../common/images", true);

class Register extends Component {
   constructor(props) {
      super(props);
      this.state = {
         document_number: "",
         document_type: "",
         email: "",
         error: false,
         isLoading: false,
         accepted_terms_cond: false,
         data_processing_accepted: false,
         accepted_politics_privacity: false
      };
   }

   async componentDidMount() {
      const queryString = require("query-string");
      let params = queryString.parse(this.props.location.search);
      const document_number = params.dni;
      const document_type = params.document_type;
      if (document_number !== undefined) {
         this.setState({document_number: document_number, document_type: document_type});
         const responseVerify = await this.props.dispatch(
            verifyUser({document_number, document_type})
         );

         let these = this;
         if (responseVerify != null) {

            const {status} = responseVerify.value;
            if (status !== 200) {
               these.setState({error: true});
               try {
                  these.props.history.pop();
               } catch (ignored) {
               }
            }

         } else {
            these.setState({error: true});
            try {
               these.props.history.pop();
            } catch (ignored) {
            }
         }
      } else {
         this.setState({error: true});
         try {
            this.props.history.pop();
         } catch (ignored) {
         }
      }

      this.validator = new Validator([
         {id: "email", ref: this.emailInput, rules: ["required", "email"]}
      ]);
   }

   handleEmailPaste = event => this.setFormValues({email: event.target.value});
   handleEmail = event => this.setFormValues({email: event.target.value});

   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({message: msg, level});
   }

   handleSubmit = async event => {
      event.preventDefault();

      const {document_number, email, document_type, data_processing_accepted} = this.state;
      const isValid = this.validator.validate({document_number});

      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }
      if (!this.state.accepted_terms_cond) {
         if(process.env.REACT_APP_NAME_APP !== "lapositiva"){
            this.addNotification("Debe aceptar los términos y condiciones", "error");
         }else{
            this.addNotification("Debe aceptar los términos y condiciones y políticas de privacidad", "error");
         }
         return;
      }
/*
      if (!this.state.data_processing_accepted && process.env.REACT_APP_NAME_APP !== "lapositiva") {
         this.addNotification("Debe aceptar las finales secundarias", "error");
         return;
      }
*/
      if (!this.state.accepted_politics_privacity && process.env.REACT_APP_NAME_APP !== "lapositiva") {
         this.addNotification("Debe aceptar las políticas de privacidad", "error");
         return;
      }


      this.setState({isLoading: true});

      if (!this.state.isLoading) {
         const registerResponse = await this.props.dispatch(
            registerUser({document_number, email, document_type, data_processing_accepted})
         );
         let these = this;
         if (
            registerResponse !== undefined &&
            registerResponse != null
         ) {

            let {status, data} = registerResponse.value;
            if (status === 200 || status === 201) {
               these.setState({isLoading: false});
               const {token} = data;
               let name = process.env.REACT_APP_NAME_APP;
               window.localStorage.setItem(`token${name}`, token);
               these.props.dispatch(setToken(token));
               setTimeout(() => {
                  (document.location.href = '/');
                  return true;
               }, 1000);
            } else {
               if (status === 400) {
                  let errorStr = "";
                  if (data.email !== undefined && data.email != null)
                     errorStr = data.email.join();
                  if (errorStr !== "") {
                     these.setState({isLoading: false});
                     these.addNotification(errorStr, "error");
                  } else {
                     these.setState({isLoading: false});
                     these.addNotification(
                        "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                        "error"
                     );
                  }
               } else {
                  these.setState({isLoading: false});
                  these.addNotification(
                     "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                     "error"
                  );
               }
            }

         } else {
            this.setState({isLoading: false});
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      }
   };

   handleInputChange = event => {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
      this.setState({[name]: value});
   };

   render() {
      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);


      return (
         <div className="col login-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container">
               <header className="hello" style={{marginBottom: 25, marginTop: 25}}>
                  <h2 className="text-app">{TextDefaul.textApp}</h2>
                  <img alt="logo" className="logo" src={logo}/>
               </header>

               <form onSubmit={this.handleSubmit}>
                  <label> Por último ingresa tu correo electrónico </label>{" "}
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.emailInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className="form-control"
                           value={this.state.email}
                           onChange={this.handleEmail}
                           type="email"
                           placeholder="Correo Electrónico"
                           required=""
                        />
                     </div>
                  </div>
                  <div className="form-group  pl-2 d-flex align-items-start  mt-4">
                     <label style={{transform: "scale(2)", marginRight: 15}}>
                        <input
                           name="accepted_terms_cond"
                           type="checkbox"
                           onChange={this.handleInputChange}
                           checked={this.state.accepted_terms_cond}
                           className="red"
                           style={{marginTop: 10}}
                        />
                        <span></span>
                     </label>
                     <div style={{marginTop: 5}}>
                        Aceptar los {" "}
                        <Link to={`/terms`} target="_blank">
                           Términos y condiciones
                        </Link>
                        {
                           process.env.REACT_APP_NAME_APP === "lapositiva" &&
                           <Fragment>
                              {" "} y aceptar las {" "}
                              <Link to={`/politics`} target="_blank">
                                 Políticas de privacidad
                              </Link>
                           </Fragment>
                        }
                     </div>
                  </div>
                  {
                     process.env.REACT_APP_NAME_APP !== "lapositiva" &&
                     <div className="form-group  pl-2 d-flex align-items-start  mt-4">
                        <label style={{transform: "scale(2)", marginRight: 15}}>
                           <input
                              name="accepted_politics_privacity"
                              type="checkbox"
                              onChange={this.handleInputChange}
                              checked={this.state.accepted_politics_privacity}
                              className="red"
                              style={{marginTop: 10}}
                           />
                           <span></span>
                        </label>
                        <div style={{marginTop: 5}}>
                           Aceptar las {" "}
                           <Link to={`/politics`} target="_blank">
                              Políticas de privacidad
                           </Link>
                        </div>
                     </div>
                  }

                  {
                     process.env.REACT_APP_NAME_APP === "lapositiva" &&
                     <div className="form-group  pl-2 d-flex align-items-start mt-2">
                        <label style={{transform: "scale(2)", marginRight: 15}}>
                           <input
                              name="data_processing_accepted"
                              type="checkbox"
                              onChange={this.handleInputChange}
                              checked={this.state.data_processing_accepted}
                              className="red"
                              style={{marginTop: 10}}
                           />
                           <span></span>
                        </label>
                        <div style={{marginTop: 5}}>
                           Aceptar las {" "}
                           <Link to={`/secondaryPurposes`} target="_blank">
                              Finalidades secundarias
                           </Link>
                        </div>
                     </div>
                  }

                  <div style={{marginBottom: 10, marginTop: 20}}>
                     <button
                        type="submit"
                        className="btn btn-whitelabel btn-lg btn-block"
                        onClick={this.handleSubmit}
                     >
                        {this.state.isLoading && "Cargando..."}
                        {!this.state.isLoading && "Registrar"}
                     </button>
                  </div>
               </form>
            </div>
         </div>
      );
   }
}

export default connect()(Register);
