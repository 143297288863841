import React from "react";
import {Link} from "react-router-dom";

const OfferDetail = props => {
   let offer = props.offer;
   let logoImage = {
      backgroundImage: "none"
   };
   if (offer.company_retail != null) {
      if (
         offer.company_retail.logo_rectangle != null &&
         offer.company_retail.logo_rectangle !== ""
      )
         logoImage = {
            backgroundImage: "url(" + offer.company_retail.logo_rectangle + ")",
            float: "right"
         };
      else
         logoImage = {
            backgroundImage: "url(" + offer.company_retail.logo + ")",
            float: "right"
         };
   }
   let pinImage =
       offer.pin != null && offer.pin.image != null;

   let pin = "";
   if (offer.pin != null && offer.pin.name != null)
      pin = offer.pin.name.split(" ")[0];
   let see_leads_btn =
      props.offer.enable_lead &&
      offer.lead_form != null &&
      !props.offer.interested;
   let see_transferable_btn =
      props.offer.is_transferable && !props.offer.transferred && false;
   let see_information_btn = offer.link != null && props.offer.link.length > 0;
   let see_raffle = offer.raffle != null;
   let btn_text_lead = "Estoy Interesado(a)";
   if (
      see_leads_btn &&
      offer.lead_form != null &&
      offer.lead_form.button_text != null &&
      offer.lead_form.button_text.length > 0
   ) {
      btn_text_lead = offer.lead_form.button_text;
   }
   return (
      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
         <div className="item no-hover col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <figure>
               <img className="cover" alt="cover" src={offer.image}/>
               <div className="shadow-offer">
                  <div className="company-logo" style={logoImage}/>
               </div>
               <div className="info">
                  {pinImage && <img alt="pin" className="pin-image" src={offer.pin.image}/>}
                  {pinImage === false && <p className="pin">{pin}</p>}

                  <p className="title">{offer.company_retail.name}</p>
                  {see_transferable_btn && (
                     <Link
                        to={`/offers/${offer.uid}/transfer`}
                        className="btn btn-whitelabel"
                     >
                        Transferir oferta
                     </Link>
                  )}
               </div>
               {offer.is_used && (
                  <div className="used-promotion">
                     <p>La promoción ya fue usada</p>
                  </div>
               )}
            </figure>
         </div>
         <div className="offer col">
            {see_information_btn && (
               <a
                  href={offer.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-whitelabel-auxiliar"
               >
                  Más Información
               </a>
            )}
            {see_leads_btn && (
               <Link
                  to={`/offers/${offer.uid}/interest`}
                  className="btn btn-whitelabel-auxiliar"
               >
                  {btn_text_lead}
               </Link>
            )}
            {see_raffle && (
               <Link
                  to={`/offers/${offer.uid}/raffle/${offer.raffle.uid}`}
                  className="btn btn-whitelabel-auxiliar"
               >
                  {offer.raffle.button_text != null && offer.raffle.button_text}
                  {offer.raffle.button_text == null && "Entrar al Sorteo"}
               </Link>
            )}
         </div>
      </div>
   );
};

export default OfferDetail;
