import React, {Component, Fragment} from "react";
import {Link} from "react-router-dom";
import "./style.scss"
import Switch from "react-switch";
const $ = require('jquery');

export default class Cookies extends Component {

   constructor(props) {
      super(props);
      this.state = {
         cookie_ga: true,
         cookie_guid: true,
      };
   }
   componentDidMount() {

      if(Object.keys(localStorage).includes("cookie_ga")){
         this.setState({cookie_ga: localStorage.getItem("cookie_ga")==="true"? true : false})
      }

      if(Object.keys(localStorage).includes("cookie_guid")){
         this.setState({cookie_guid: localStorage.getItem("cookie_guid")==="true" ? true : false})
      }
   }

   componentDidUpdate(prevProps, prevState, snapshot) {
      if ( $(".modal-backdrop").length > 1 ) {
         $('.modal-backdrop:first').remove();
      }
   }

   closeModal = (e) => {
      e.preventDefault()
      $(this.modal).modal('hide');
      $('body').removeClass('modal-open');
      $('.modal-backdrop').remove();
   }
   openModal = (e) => {
      e.preventDefault()
      $('#cookieModal').modal('show');
   }

   acceptCookies = (e) => {
      e.preventDefault()
      let expire = new Date()
      let oneDay =24*60*60*1000
      expire = new Date(expire.getTime() + oneDay)
      if(this.state.cookie_guid){
         document.cookie = 'cookie_guid=aceptada; expires='+expire.toUTCString()
      }
      localStorage.setItem("cookie_ga", this.state.cookie_ga)
      localStorage.setItem("cookie_guid", this.state.cookie_guid)
      this.props.setOverlay(false)
      if(!this.state.cookie_ga){

         $(document).ready(function()
         {
            let aux = "<script async src=\"https://www.googletagmanager.com/gtag/js?id="+process.env.REACT_APP_F_MEASUREMENT_ID+"\"></script>\n" +
               "    <script>\n" +
               "       window['ga-disable-"+process.env.REACT_APP_F_MEASUREMENT_ID+"'] = true;\n" +
               "       window.dataLayer = window.dataLayer || [];\n" +
               "       function gtag(){dataLayer.push(arguments);}\n" +
               "       gtag('js', new Date());\n" +
               "\n" +
               "       gtag('config', '"+process.env.REACT_APP_F_MEASUREMENT_ID+"');\n" +
               "    </script>";
            $("body").append(aux);
         });

         let cookies = document.cookie.split(';');
         cookies.map( item => {
            if(item.includes('_ga')){
               document.cookie = item+'=aceptada; max-age='+0+";"
            }
            return true;
         })
      }
   }

   setFormValues = (propertiesToUpdate) => {
      this.setState(propertiesToUpdate);
      console.log(Object.keys(propertiesToUpdate)[0], propertiesToUpdate[Object.keys(propertiesToUpdate)[0]])
      localStorage.setItem(Object.keys(propertiesToUpdate)[0], propertiesToUpdate[Object.keys(propertiesToUpdate)[0]])
   }

   render() {
      return (
         <Fragment>
            <div className={`cookies-box`}>
               <div className={`container-cookies d-flex justify-content-between align-items-center flex-column flex-md-row`}>
                  <div className={"col-12 col-md-8"}>
                     Utilizamos cookies propias y de terceros con fines analíticos para mejorar y personalizar tu navegación en base a tus intereses. Más información en nuestra{" "}
                     <Link to={`/cookies`} target="_blank">
                        política de cookies
                     </Link>.
                  </div>
                  <div className={"d-flex justify-content-around col-12 col-md-4 flex-column flex-md-row"}>
                     <button onClick={this.openModal} className={"config-cookies mb-2 mb-md-0"} style={{color: localStorage.getItem("color")}}>Configurar cookies</button>
                     <button onClick={this.acceptCookies} className={"accept-cookies mt-2 mt-md-0"}
                             style={{background: localStorage.getItem("color"), border: localStorage.getItem("color")}}>Aceptar cookies</button>
                  </div>
               </div>
            </div>
            <div>
               <div
                  className={"modal fade"}
                  id="cookieModal"
                  ref={(modal) => this.modal = modal}
                  tabIndex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel2"
                  aria-hidden="true"
               >
                  <div
                     className={"modal-dialog modal-dialog-popup modal-lg"}
                     role="document">
                     <div
                        className={"modal-content"}>
                        <div className="modal-header">
                           <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                   style={{zIndex: 998, paddingRight: "25px"}}>
                              <span aria-hidden="true" style={{fontSize:"30px"}}>&times;</span>
                           </button>
                        </div>
                        <div className="modal-body">
                           <h5>Cookies Utilizadas</h5>
                           <p>Utilizamos cookies propias y de terceros con fines analíticos para mejorar y personalizar tu navegación en base a tus intereses. Más información en nuestra {""}
                              <Link to={`/cookies`} target="_blank">
                                 política de cookies
                              </Link>
                              .</p>
                           <strong>Definición de cookies</strong>
                           <p>Las cookies son pequeños archivos de texto que las páginas web pueden utilizar para hacer más eficiente la experiencia del usuario. Estos datos proveen información sobre cómo y cuándo usted visita un sitio. Se almacena en su dispositivo en la forma de un archivo de texto pequeño. Las "cookies" no recopilan información como su nombre, dirección, número telefónico o dirección de correo electrónico.</p>

                           <strong>Finalidades de las cookies</strong>
                           <p>Este sitio Web utiliza las cookies, para recopilar información sobre su actividad  que nos permite mejorar su experiencia como cliente.
                              <br/>Entre los diferentes tipos de cookies y su finalidad tenemos:</p>
                           <strong>Cookies necesarias</strong>
                           <p>Son cookies de carácter técnico que nos ayudan a hacer los sitios web y apps utilizables, activando funciones básicas como la navegación en la página y app, y el acceso a áreas seguras de las mismas. Estas cookies son necesarias pues la página web/app o los servicios no pueden funcionar adecuadamente sin ellas y no requieren de tu consentimiento.</p>


                           <strong>Cookies de estadísticas</strong>
                           <p>Son cookies que nos permiten obtener datos agregados, y siempre anónimos, que utilizamos con una finalidad estrictamente estadística para comprender cómo los usuarios interactúan con la página web/app y sus servicios. Nos permiten cuantificar el número de usuarios que han visitado el sitio web, un determinado servicio, o se han interesado por un anuncio, y extraer estadísticas. Gracias a ello podemos mejorar nuestros sitios webs y apps, así como la oferta de productos o servicios que realizamos. Pueden ser propias o de terceros.</p>
                           <table className="table">
                              <thead>
                              <tr>
                                 <th scope="col">ACTIVO</th>
                                 <th scope="col">NOMBRE</th>
                                 <th scope="col">PROPÓSITO</th>
                                 <th scope="col">CADUCIDAD</th>
                                 <th scope="col">TIPO</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                 <th scope="row"><Switch onChange={(checked) => {
                                    this.setFormValues({cookie_ga: !this.state.cookie_ga})
                                 }} checked={this.state.cookie_ga}/></th>
                                 <td>_ga</td>
                                 <td>Cookie analítica de Google Analytics. Sirve para distinguir a los usuarios.</td>
                                 <td>2 años</td>
                                 <td>HTTP</td>
                              </tr>
                              <tr>
                                 <th scope="row"><Switch onChange={(checked) => {
                                    this.setFormValues({cookie_guid: !this.state.cookie_guid})
                                 }} checked={this.state.cookie_guid}/></th>
                                 <td>_guid</td>
                                 <td>Cookie analítica de Google Analytics. Sirve para distinguir a los usuarios.</td>
                                 <td>24 horas</td>
                                 <td>HTTP</td>
                              </tr>
                              </tbody>
                           </table>

                           <strong>Tiempo</strong>
                           <p>Las cookies serán utilizadas hasta su fecha de caducidad o hasta que usted opte por deshabilitarlas, lo cual podrá hacer en cualquier momento configurando las opciones de privacidad y seguridad de su navegador.</p>

                           <strong>Transferencia y Destinatarios</strong>
                           <p>“Los Datos” no se transmitirán a terceros, salvo obligación legal.</p>

                           <strong>Ejercicio de los derechos ARCO</strong>
                           <p>Como titular se sus datos personales, usted podrá ejercer su derecho de acceso, rectificación, cancelación y/o oposición al tratamiento de sus datos personales. Asimismo, usted puede modificar o revocar su consentimiento en cualquier momento configurando su navegador.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Fragment>

      );
   }
}

