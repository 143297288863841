import * as types from "../common/constants/actionTypes";

const initialState = {
    data: [],
    isFetching: false,
    error: null,
    count: 0,
    next: null,
    previous: null,
    offer: null,
    isFechingOffer: false,
    isLoadingInterest: false,
    errorInterested: false,
    successInterested: 0
};

export default function reducer(state = initialState, action) {
    switch (action.type) {

        case `${types.FECH_OFFERS}_PENDING`:
            return { ...state, isFetching: true };
        case `${types.FECH_OFFERS}_REJECTED`:
            return { ...state, isFetching: false, error: action.payload };
        case `${types.FECH_OFFERS}_FULFILLED`:
            return {
                ...state,
                isFetching: false,
                data: action.payload.data.results,
                next: action.payload.data.next,
                count: action.payload.data.count
            };
        case `${types.DETAIL_OFFER}_PENDING`:
            return { ...state, isFechingOffer: true, offer: null };
        case `${types.DETAIL_OFFER}_REJECTED`:
            return { ...state, isFechingOffer: false, error: action.payload, offer: null };
        case `${types.DETAIL_OFFER}_FULFILLED`:
            return {
                ...state,
                isFechingOffer: false,
                offer: action.payload.data
            };
        case `${types.DETAIL_OFFER_INTEREST}_PENDING`:
            return { ...state, isLoadingInterest: true, errorInterested: false };
        case `${types.DETAIL_OFFER_INTEREST}_REJECTED`:
            return { ...state, isLoadingInterest: false, errorInterested: true, error: action.payload };
        case `${types.DETAIL_OFFER_INTEREST}_FULFILLED`:
            return {
                ...state,
                isLoadingInterest: false,
                errorInterested: false,
                successInterested :200
            };
        case `${types.CLEAN_OFFERS}`:
            return {...state,
                data: [],
                isFetching: false,
                error: null,
                count: 0,
                next: null,
                previous: null,
                offer: null,
                isFechingOffer: false,
                isLoadingInterest: false,
                errorInterested: false,
                successInterested: 0
            }
        default:
            return state;

    }
}
