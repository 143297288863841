import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import Validator from "../../common/utils/validator";
import {logIn, logInWithPassword} from "./actions";
import {setToken} from "../../reducersapp/auth";
import {getUserDataBoc} from "../Register/actions";

import {Link} from "react-router-dom";

var images = require.context("../../common/images", true);


class LoginWhiteStep2 extends Component {
   constructor(props) {
      super(props);
      this.state = {
         password: "",
         business: "",
         isLoading: false,
         visibility: false
      };
   }

   componentDidMount() {
      this.setState({business: process.env.REACT_APP_NAME_APP});
      this.validator = new Validator([
         {
            id: "password",
            ref: this.passwordInput,
            rules: ["required"]
         }
      ]);
   }


   handlePassword = event =>
      this.setFormValues({password: event.target.value});

   setFormValues = propertiesToUpdate => {
      this.setState(propertiesToUpdate);
      this.validator.validate(propertiesToUpdate);
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   loginUser = async dni => {
      try {
         let document_type = this.state.type;
         const logInResponse = await this.props.dispatch(
            logIn({dni, document_type})
         );
         let these = this;
         if (
            logInResponse !== undefined &&
            logInResponse != null
         ) {

                  let {status, data} = logInResponse.value;
                  if (status === 200) {
                     const {token} = data;
                     let name = process.env.REACT_APP_NAME_APP;
                     window.localStorage.setItem(`token${name}`, token);
                     these.props.dispatch(setToken(token));
                     setTimeout(() => {
                        (document.location.href = '/');
                        return true;
                     }, 1000);
                  } else {
                     these.setState({isLoading: false});
                     if (status === 400) {
                        these.redirectToLogin(dni);
                     } else {
                        these.addNotification(
                           "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                           "error"
                        );
                     }
                  }

         } else {
            this.setState({isLoading: false});
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };

   redirectToLogin(dni) {
      this.props.history.push(
         `/register?dni=${dni}&document_type=${this.state.type}`
      );
   }

   selectDocumentType = (e, name, type_id) => {
      e.preventDefault();
      this.setState({type_text: name, type: type_id});
   };

   loginPassword = async password => {
      try {
         let document_number = this.props.document_number
         const logInResponse = await this.props.dispatch(
            logInWithPassword({"document_type": "NATIONAL_ID", "document_number":document_number, "password": password})
         );

         let these = this;
         if (
            logInResponse !== undefined &&
            logInResponse != null
         ) {
            let {status, data} = logInResponse.value;
            if (status === 200) {
               const {token, first_name} = data;
               let name = process.env.REACT_APP_NAME_APP;
               if(process.env.REACT_APP_NAME_APP === "bancoelcomercio"){
                  window.localStorage.setItem(`token${name}2`, token);
                  these.props.dispatch(setToken(token));
                  these.props.setFirstName(first_name);

                  const responseUser = await this.props.dispatch(getUserDataBoc());
                  if (
                     responseUser !== undefined &&
                     responseUser != null
                  ) {
                     let {data:{verified_mobile_number, mobile_number}} = responseUser.value;
                     if(!verified_mobile_number){
                        these.props.setMobileNumber(mobile_number)
                        these.props.setSendFirstSMS(true)
                        these.props.setVerifyMobile(true)
                     }else{
                        these.props.setWelcome(true)
                     }
                  }
               }else{
                  window.localStorage.setItem(`token${name}`, token);
                  these.props.dispatch(setToken(token));
                  setTimeout(() => {
                     (document.location.href = '/');
                     return true;
                  }, 1000);
               }
            } else {
               these.setState({isLoading: false});
               these.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            }

         } else {
            this.setState({isLoading: false});
            this.addNotification(
               "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
               "error"
            );
         }
      } catch (error) {
         this.setState({isLoading: false});
         this.addNotification(
            "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
            "error"
         );
      }
   };

   handleSubmit = async event => {
      event.preventDefault();

      const {password} = this.state;
      const isValid = this.validator.validate({password});

      if (!isValid) {
         this.addNotification("Completar los campos requeridos", "error");
         return;
      }

      this.setState({isLoading: true});
      if (!this.state.isLoading) {
         this.loginPassword(password);
      }
   };

   render() {
      let visibilty = images(
         `./visibility.png`
      );
      let noVisibilty = images(
         `./no-visibility.png`
      );

      return (
         <div className="col login-form">
            <NotificationSystem
               ref={notificationSystem =>
                  (this.notificationSystemRef = notificationSystem)
               }
            />
            <div className="grid-container">
               <header
                  className="hello"
                  style={{marginBottom: 15, marginTop: 25}}
               >
                  <p className={"loginExtraText"}>Consulta tus beneficios</p>
               </header>
               <form onSubmit={this.handleSubmit} style={{marginTop: 45}}>
                  <div style={{position: "relative"}}>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.passwordInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className={"form-control"}
                           value={this.state.password}
                           onChange={this.handlePassword}
                           type={this.state.visibility ? "text" : "password"}
                           placeholder="Ingresa clave"
                        />
                     </div>
                     {
                        process.env.REACT_APP_NAME_APP==="bancoelcomercio" &&
                        <img
                           alt="visibilty"
                           className="visibilty"
                           style={{cursor:"pointer"}}
                           src={!this.state.visibility ? noVisibilty : visibilty }
                           onClick={e=>this.setState({visibility: !this.state.visibility})}
                        />
                     }
                  </div>

                  <div style={{marginBottom: 10, marginTop: 10}}>
                     <button
                        type="submit"
                        className={ process.env.REACT_APP_NAME_APP === "bancoelcomercio"  ? (this.state.password !== "" ? "btn btn-whitelabel btn-lg btn-block"  : "btn btn-whitelabel-disabled btn-lg btn-block ") : "btn btn-whitelabel btn-lg btn-block" }
                        onClick={this.handleSubmit}
                     >
                        {this.state.isLoading ? "Cargando..." : "Ingresar" }
                     </button>
                  </div>

                  {
                     (process.env.REACT_APP_NAME_APP === "bancoelcomercio") &&
                     <div className="links link-recovery pt-2">
                        <Link to={"/recovery"}>¿Olvidaste tu contraseña?</Link>
                     </div>
                  }
               </form>
            </div>
         </div>
      );
   }

   static contextTypes = {
      router: PropTypes.object
   };
}

export default connect()(LoginWhiteStep2);
