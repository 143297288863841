import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getFacebookStatus} from "./actions";
import LoadingIndicator from "../../common/components/LoadingIndicator";
import { LANDING_IMAGE, TextDefaul} from "../../common/utils/generic";
import Page from "../../common/components/Page";
import firebase from "firebase";


var images = require.context("../../common/images", true);

class FacebookStatus extends Component {
   constructor(props) {
      super(props);
      this.state = {
         sending: false,
         message: "",
         loading: true,
         isLoading: false,
         accepted_terms_cond: true
      };
   }


   componentDidMount() {
      const {match} = this.props;
      const uidBederrApp = match.params.uidBederrApp;
      const uidUser = match.params.uidUser;
      const code = match.params.code;
      let name_hhrr = process.env.REACT_APP_NAME_APP;
      let api_key = process.env.REACT_APP_APY_KEY;
      window.localStorage.setItem(`apikey${name_hhrr}`, api_key);
      if (uidBederrApp !== undefined && uidUser !== undefined && code !== undefined) {
         this.props.dispatch(getFacebookStatus(uidBederrApp, uidUser, code))
      } else {
         this.context.router.history.push(`/`);
      }


   }


   render() {
      let remoteConfig = firebase.remoteConfig()
      let app_landing = remoteConfig.getString('app_landing');

      const {msg, isLoading} = this.props
      let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);
      let background = images(
         `./background_${process.env.REACT_APP_NAME_APP}.jpg`
      );
      let backgroundImage = {
         backgroundImage: "url(" + background + ")"
      };
      return (
         <Page
            id="login"
            title={TextDefaul.webTitle}
            description={TextDefaul.webDescription}
         >
            <div className="welcome"

            >
               <div className="col login-form"
                    style={{maxWidth: "40%"}}>
                  <div
                     className="grid-container"
                     style={{
                        background: process.env.REACT_APP_NAME_APP === "rewards" ? "#00808a" : ""
                     }}
                  >
                     <header
                        className="hello"
                        style={{marginBottom: 25, marginTop: 25}}
                     >
                        <img alt="logo" className="logo" src={logo}/>
                     </header>
                     <div>
                        {
                           isLoading &&
                           <LoadingIndicator/>
                        }
                        <p className={msg==="Cargando" && "text-center"}>{msg}</p>
                     </div>
                  </div>
               </div>
               {
                  app_landing === LANDING_IMAGE &&
                  <div className="publicity" style={backgroundImage} />
               }
            </div>
         </Page>
      );
   }

   static contextTypes = {
      router: PropTypes.object
   };
}

export default connect(({users}) => ({
   isLoading: users.isLoadingFacebookStatus,
   msg: users.messageFacebookStatus,
}))(FacebookStatus);

