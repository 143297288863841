import * as types from "../common/constants/actionTypes";


const initialState = {
   data: [],
   isFetching: false,
   isFetchingDetail: false,
   selectBoxUid: null,
   detailBox: null
};

export default function reducer(state = initialState, action) {

   switch (action.type) {

      case `${types.FECH_BOXES}_PENDING`:
         return { ...state, isFetching: true, data:[]};
      case `${types.FECH_BOXES}_REJECTED`:
         return { ...state, isFetching: false, error: action.payload , data:[]};
      case `${types.FECH_BOXES}_FULFILLED`:
         let selectBoxLet = null;
         return Object.assign({},
            state, {
               isFetching: false,
               data: action.payload.data.results,
               selectBox: selectBoxLet
            }
         );
      case `${types.DETAIL_BOX}_PENDING`:
         return { ...state, isFetchingDetail: true };
      case `${types.DETAIL_BOX}_REJECTED`:
         return { ...state, isFetchingDetail: false, error: action.payload };
      case `${types.DETAIL_BOX}_FULFILLED`:
         let detailBoxLet = null;
         if(action.payload.data!==undefined && action.payload.data!==null){
            detailBoxLet = action.payload.data
         }
         return Object.assign({},
            state, {
               isFetchingDetail: false,
               detailBox: detailBoxLet
            }
         );
      case `${types.BOX_CHANGE}`:
         if (action.uid != null) {
            return {...state, selectBoxUid: action.uid};
         } else {
            return {...state, selectBoxUid: null}
         }
      case `${types.CLEAN_BOXES}`:
            return {...state,
                  data: [],
                  isFetching: false,
                  isFetchingDetail: false,
                  selectBoxUid: null,
                  detailBox: null
            }
      default:
         return state;
   }
}
