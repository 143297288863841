import * as types from "../common/constants/actionTypes";

const initialState = {
  selectMenu: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case `${types.CHANGE_MENU}`:
      return { ...state, selectMenu: action.menu };
    default:
      return state;
  }
}
