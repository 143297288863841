import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
   APP_LOGIN_PASSWORD,
   APP_TYPE_WHITELABEL,
   buildConfig,
   LANDING_IMAGE,
   TextDefaul
} from "../../common/utils/generic";
import Page from "../../common/components/Page";
import Recovery from "./recovery";
import firebase from "firebase";
import {Link} from "react-router-dom";

const images = require.context("../../common/images", true);

const LayoutRecovery = props => {
   let background = images(
      `./background_${process.env.REACT_APP_NAME_APP}.jpg`
   );
   let backgroundImage = {
      backgroundImage: "url(" + background + ")"
   };
   let remoteConfig = firebase.remoteConfig()
   let app_type = process.env.REACT_APP_TYPE;
   let login_type = buildConfig.app_login_type;
   let app_landing = buildConfig.app_landing;
   if (remoteConfig != null) {
      login_type = remoteConfig.getString('app_login_type');
      app_landing = remoteConfig.getString('app_landing');
   }

   let logo = images(`./logo_${process.env.REACT_APP_NAME_APP}.png`);

   return (
      <Page
         id="register"
         title={TextDefaul.webTitle}
         description={TextDefaul.webDescription}
      >
         {
            ((app_type === APP_TYPE_WHITELABEL &&
            login_type === APP_LOGIN_PASSWORD) || process.env.REACT_APP_NAME_APP==="bancoelcomercio") &&
            <div className="welcome">
               <div
                  className="login"
                  style={{
                     alignItems: "baseline"
                  }}
               >
                  {
                     process.env.REACT_APP_NAME_APP === "bancoelcomercio" &&
                     <Link to={"/login"}>
                        <img
                           alt="logo"
                           className="logo"
                           src={logo}
                        />
                     </Link>
                  }
                  <Recovery {...props} />
               </div>
               {
                  app_landing === LANDING_IMAGE &&
                  <div className="publicity" style={backgroundImage}/>
               }
            </div>
         }
      </Page>
   );
};

LayoutRecovery.propTypes = {
   location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
   }).isRequired,
   dispatch: PropTypes.func.isRequired
};

export default connect()(LayoutRecovery);
