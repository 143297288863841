import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import NotificationSystem from "react-notification-system";
import {getDetailRaffle, sendCodeRaffle, showModal} from "./actions";

import {HOME} from "../../common/constants/actionTypes";
import Validator from "../../common/utils/validator";
import {changeMenu} from "../Menu/actions";

class Rafle extends Component {
   static contextTypes = {
      router: PropTypes.object
   };
   state = {
      code: "",
      area: null,
      tel: "",
      offer_id: "",
      raffle_id: "",
      loading: false,
      showModal: true,
      image: ""
   };

   showModal = () => {
      this.setState({ showModal: true });
   };

   hideModal = () => {
      this.setState({ showModal: false });
   };

   changeCity(e, cityId) {
      e.preventDefault();
      const { localities } = this.props;
      for (let i = 0; i < localities.length; i++) {
         if (localities[i].uid === cityId) {
            this.setState({ area: localities[i] });
            break;
         }
      }
   }

   componentDidMount() {
      this.validator = new Validator([
         {
            id: "code",
            ref: this.codeInput,
            rules: ["required"]
         },
         {
            id: "tel",
            ref: this.telInput,
            rules: ["required", "tel", "positiveNumber"]
         }
      ]);
      const { match, benefit_program, localities } = this.props;
      const offer_id = match.params.uidOffer;
      const raffle_id = match.params.uidRaffle;
      if (
         benefit_program != null &&
         offer_id !== undefined &&
         offer_id != null &&
         raffle_id !== undefined &&
         raffle_id != null
      ) {
         this.setState({ offer_id, raffle_id });
         this.props.dispatch(
            getDetailRaffle(benefit_program.uid, offer_id, raffle_id)
         );
      }
      for (let i = 0; i < localities.length; i++) {
         if (localities[i].name.toLowerCase() === "lima") {
            this.setState({ area: localities[i] });
            break;
         }
      }
      this.props.dispatch(changeMenu(HOME));
   }



   renderLocalities() {
      const { localities, loadLocalities } = this.props;
      if (localities != null && localities.length > 0) {
         return localities.map((locality, index) => (
            <a
               href={"/"}
               key={`locality${index}`}
               onClick={e => this.changeCity(e, locality.uid)}
               className="dropdown-item dropdown-type select-type"
            >
               {locality.name}
            </a>
         ));
      } else {
         if (loadLocalities) {
            return <p> Cargando...</p>;
         } else {
            return <p> No se encontraron localidades</p>;
         }
      }
   }

   handleCodeInput = event => this.setState({ code: event.target.value });

   handleTelInput = event => this.setState({ tel: event.target.value });

   handleSubmit = async event => {
      event.preventDefault();
      const { benefit_program } = this.props;
      const { offer_id, raffle_id, area, tel, code } = this.state;
      const isValid = this.validator.validate({
         tel,
         code
      });
      if (area == null) {
         this.addNotification("Debes escoger una ciudad", "error");
      } else {
         if (isValid) {
            this.setState({ loading: true });
            const responseCode = await this.props.dispatch(
               sendCodeRaffle(
                  { code: code, mobile_number: tel, area: area.uid },
                  benefit_program.uid,
                  offer_id,
                  raffle_id
               )
            );

            let these = this;
            if (
               responseCode !== undefined &&
               responseCode != null
            ) {

                     let { status, data } = responseCode.value;
                     if (status === 200 || status === 201) {
                        these.setState({ loading: false });
                        these.addNotification(
                           "Se ha validado el código correctamente",
                           "success"
                        );
                        these.setState({ code: "" });
                        if (data.image != null) {
                           these.showImage(data.image);
                        }
                     } else {
                        these.addNotification("El código es invalido", "error");
                        these.setState({ code: "" });
                        these.setState({ loading: false });
                     }

            } else {
               this.setState({ isLoading: false });
               this.addNotification(
                  "Ups! Lo sentimos ha sucedido un error, intentelo nuevamente",
                  "error"
               );
            }
         }
      }
   };

   addNotification(msg, level) {
      this.notificationSystemRef.addNotification({
         message: msg,
         level
      });
   }

   showImage(image) {
      this.props.dispatch(showModal(true, image));
   }

   render() {
      let {
         raffle,
         isFetchingDetail,
         showModal,
         image,
         loadLocalities
      } = this.props;
      let style = {};
      let styleLink = {
         display: "none"
      };
      if (isFetchingDetail || raffle == null) {
         style = {
            display: "none"
         };
      } else {
         if (
            raffle.link_more_information !== undefined &&
            raffle.link_more_information != null &&
            raffle.link_more_information !== ""
         ) {
            styleLink = {
               display: "block",
               marginTop: 10
            };
         }
      }

      return (
         <div className="container left-container">
            <div className="row">
               <NotificationSystem
                  ref={notificationSystem =>
                     (this.notificationSystemRef = notificationSystem)
                  }
               />
               <div
                  style={style}
                  className="col col-lg-8 col-md-12 dinamic-form"
               >
                  <h4 className="title-section bold ">
                     {raffle != null && raffle.name}
                  </h4>
                  <p className="subtitle-left">Ingrese el código a validar</p>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.codeInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className="form-control"
                           value={this.state.code}
                           type="text"
                           onChange={this.handleCodeInput}
                           placeholder="Código"
                        />
                     </div>
                  </div>

                  <p className="subtitle-left">Número telefónico</p>
                  <div>
                     <div
                        className="form-group"
                        ref={input => {
                           if (input) {
                              this.telInput = input.querySelector("input");
                           }
                        }}
                     >
                        <input
                           className="form-control"
                           value={this.state.tel}
                           type="text"
                           onChange={this.handleTelInput}
                           placeholder="Número de celular"
                        />
                     </div>
                  </div>
                  <p className="subtitle-left">Localidad</p>

                  <div className="search-types form-group  dropdown">
                     <div className="form-group">
                        <div
                           className="form-control"
                           id="city"
                           data-toggle="dropdown"
                           aria-haspopup="true"
                           aria-expanded="false"
                        >
                           {loadLocalities && <span>Cargando...</span>}
                           {loadLocalities === false && (
                              <span>
                                 {this.state.area != null &&
                                    this.state.area.name}
                              </span>
                           )}
                           {loadLocalities === false && (
                              <span>
                                 {this.state.area == null &&
                                    "Escoger localidad"}
                              </span>
                           )}
                        </div>
                        <div
                           className="dropdown-menu"
                           style={{
                              width: "100%",
                              overflowY: "scroll",
                              maxHeight: 200
                           }}
                           aria-labelledby="city"
                        >
                           {this.renderLocalities()}
                        </div>
                     </div>
                  </div>

                  <form onSubmit={this.handleSubmit}>
                     <div className="content-btn">
                        {!this.state.loading &&
                        raffle != null &&
                        raffle.button_text.length!==0 &&
                           <button
                              type="submit"
                              className="btn btn-whitelabel btn-lg "
                           >
                              {this.state.loading && "Cargando"}
                              {!this.state.loading &&
                              raffle != null &&
                              raffle.button_text ? raffle.button_text : "Registrar"}
                           </button>
                        }
                     </div>
                  </form>
               </div>
               {showModal && (
                  <div className="notification-detail col col-lg-8 col-md-12 ">
                     <div className="item notification-item col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mx-auto">
                        <figure>
                           <img alt={"cover"} className="cover" src={image} />
                        </figure>
                        <div className="content-btn">
                           <a
                              href={raffle.link_more_information}
                              className={"btn btn-whitelabel btn-lg"}
                              style={styleLink}
                              target="_blank"
                              rel="noopener noreferrer"
                           >
                              {" "}
                              para más información click aquí !
                           </a>
                        </div>
                     </div>
                  </div>
               )}
               {isFetchingDetail && (
                  <div>
                     <p>Cargando...</p>
                  </div>
               )}
            </div>
         </div>
      );
   }
}

Rafle.defaultProps = {
   isFetchingDetail: false,
   showModal: false,
   raffle: null,
   loadLocalities: false
};

export default connect(({ benefitPrograms, raffles, countries }) => ({
   benefit_program: benefitPrograms.benefitProgram,
   raffle: raffles.detailRaffle,
   showModal: raffles.showModal,
   image: raffles.image,
   loadLocalities: countries.isFetching,
   localities: countries.data,
   isFetchingDetail: raffles.isFetchingDetail
}))(Rafle);
