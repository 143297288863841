import React from "react";
import {Link} from "react-router-dom";
import Img from "react-image";

import {buildConfig} from "../../utils/generic";
import LoadingIndicator from "../LoadingIndicator";
import firebase from "firebase"

const Promotion = props => {

   let firebaseRemoteConfig = firebase.remoteConfig()
   let color = buildConfig.colors_main_color;
   if(firebaseRemoteConfig){
      color = firebaseRemoteConfig.getString("colors_main_color")
   }

   let logoImage = {
      backgroundImage: "none"
   };
   if (props.promotion.company_retail != null) {
      logoImage = {
         backgroundImage: "url(" + props.promotion.company_retail.logo + ")",
         float: "right"
      };
   }


   let pinImage =
      props.promotion.pin != null && props.promotion.pin.image != null;

   let pin = "";

   if (props.promotion.pin != null && props.promotion.pin.name != null)
      pin = props.promotion.pin.name.split(" ")[0];

   let style_class = props.is_detail
      ? "item no-hover"
      : "item col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4";
   let hasInactive =
      props.promotion.is_available === false || props.is_locked === true;

   let stamps = [];
   if (props.promotion.required_stamps === undefined)
      props.promotion.required_stamps = 0;
   else if (props.promotion.required_stamps === null)
      props.promotion.required_stamps = 0;

   if (props.promotion.available_stamps === undefined)
      props.promotion.available_stamps = 0;
   else if (props.promotion.available_stamps === null)
      props.promotion.available_stamps = 0;

   if (props.promotion.required_stamps > 0) {
      for (let i = 0; i < props.promotion.required_stamps; i++) {
         stamps.push(props.promotion.available_stamps > i);
      }
   }

   let body = (
      <figure>

         <Img
            className="cover"
            src={props.promotion.image}
            loader={<LoadingIndicator />}
         />
         {
            props.promotion.type !== "GIFT_CARD" &&
            <div className="shadow-offer">
               <div className="company-logo" style={logoImage} />
            </div>
         }
         {
            props.promotion.type !== "GIFT_CARD" &&
            <div className="info">
               {pinImage &&
               props.promotion.required_stamps <= 0 && (
                  <img alt={"pin"} className="pin-image" src={props.promotion.pin.image}/>
               )}
               {pinImage === false &&
               props.promotion.required_stamps <= 0 && (
                  <p className="pin">{pin}</p>
               )}
               {props.promotion.required_stamps > 0 && (
                  <div className="row stamps">
                     {stamps.map(function (item, i) {
                        return (
                           <div
                              className="stamp"
                              style={item ? {background: color} : {}}
                              key={"stamp" + props.promotion.uid + i}
                           />
                        );
                     })}
                  </div>
               )}
               <div className="row">
                  <div className="col">
                     <p className="title">{props.promotion.company_retail.name}</p>
                     {props.is_detail === false && (
                        <p className="subtitle">{props.promotion.title}</p>
                     )}
                  </div>
               </div>
            </div>
         }
         {props.promotion.is_available === false && (
            <div className="used-promotion">
               {props.promotion.is_used > 0 && <p>La promoción ya fue usada</p>}
               {props.promotion.is_used === 0 && (
                  <p>La promoción ya no puede ser usada</p>
               )}
            </div>
         )}
         {props.is_locked &&
            props.promotion.is_available && (
               <div className="used-promotion">{<p />}</div>
            )}
      </figure>
   );
   return (
      <div className={style_class}>
         {(props.is_detail === false && hasInactive === false ) && (
            <Link to={`/offers/${props.promotion.uid}`}>{body}</Link>
         )}
         {(props.is_detail === false && hasInactive === true ) && (
            <div>{body}</div>
         )}
         {props.is_detail && <div>{body}</div>}
      </div>
   );
};
Promotion.defaultProps = {
   is_locked: false,
   is_detail: false
};
export default Promotion;
