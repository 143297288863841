import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import {getPolls} from "./actions";
import PollItem from "../../common/components/widgets/PollItem";
import {POLLS} from "../../common/constants/actionTypes";
import {changeMenu} from "../Menu/actions";

class PollsList extends Component {
  static contextTypes = {
    router: PropTypes.object
  };



  componentDidMount() {
     const { benefit_program } = this.props;
     this.props.dispatch(getPolls(benefit_program.uid));
     this.props.dispatch(changeMenu(POLLS));
  }



  renderPolls() {
    const { polls } = this.props;

    if (polls.length === 0) {
      return (
        <div className="content-no-items">
          <p>Por el momento no tiene encuestas que responder.</p>
        </div>
      );
    } else {
      return polls.map((poll, index) => <PollItem key={index} poll={poll} />);
    }
  }

  loadMore = () => {
    const { next, moreLoader } = this.props;
    if (!moreLoader && next != null) {
      // this.props.dispatch(getMoreNotifications(next))
    }
  };

  render() {
    const { loader, next } = this.props;
    let more = next != null;
    if (loader) {
      return (
        <div className="row content-loader">
          <p>Cargando...</p>
        </div>
      );
    }
    let loaderMore = (
      <div
        key={0}
        className="col-lg-12 content-loader"
        style={{ display: "inline-flex" }}
      >
        <p>Cargando...</p>
      </div>
    );
    return (
      <div>
        <div className="container left-container">
          <h3 className="title-section">Encuestas</h3>
        </div>
        <div
          className="container left-container"
          style={{ position: "relative" }}
        >
          <InfiniteScroll
            className="notification-section row"
            pageStart={0}
            loadMore={this.loadMore.bind(this)}
            hasMore={more}
            loader={loaderMore}
          >
            {this.renderPolls()}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}

PollsList.defaultProps = {
  addNotification: () => {},
  polls: [],
  moreLoader: false,
  loader: false,
  benefit_program: null
};

export default connect(({ polls, benefitPrograms }) => ({
  benefit_program: benefitPrograms.benefitProgram,
  polls: polls.data,
  loader: polls.isFetching,
  moreLoader: polls.isFetchingMore,
  next: polls.next
}))(PollsList);
