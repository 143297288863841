import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Banner from "../../common/components/widgets/Banner";
import {getPopups} from "./actions";
import Slider from "react-slick";

class Popups extends Component {
   constructor(props) {
      super(props);
      this.state = { isOpen: true };
   }

   static contextTypes = {
      router: PropTypes.object
   };

   toggleModal = () => {
      this.setState({
         isOpen: !this.state.isOpen
      });
   };

   componentDidMount() {
      const { benefit_program } = this.props;
      if (benefit_program != null) {
         this.props.dispatch(getPopups(benefit_program.uid));
      }
   }

   showBannerPromotion = (e, uidPromotion, uidPoll, menu) => {
      e.preventDefault();
      if (menu !== undefined && menu != null && menu !== "") {
         switch (menu) {
            case "NOTIFICATIONS":
               this.setState({
                  isOpen: !this.state.isOpen
               });
               this.props.history.push({ pathname: "/notifications" });
               break;
            case "POLLS":
               this.setState({
                  isOpen: !this.state.isOpen
               });
               this.props.history.push({ pathname: "/polls" });
               break;
            case "PROFILE":
               this.setState({
                  isOpen: !this.state.isOpen
               });
               this.props.history.push({ pathname: "/profile" });
               break;
            default:
               break;
         }
      } else {
         this.setState({
            isOpen: !this.state.isOpen
         });
         if (
            uidPromotion !== undefined &&
            uidPromotion != null &&
            uidPromotion !== ""
         ) {
            this.props.history.push({
               pathname: "/offers/" + uidPromotion + ""
            });
         } else {
            if (uidPoll !== undefined && uidPoll != null && uidPoll !== "") {
               this.props.history.push({ pathname: "/polls/" + uidPoll + "" });
            }
         }
      }
   };

   componentDidUpdate(prevProps, prevState, snapshot)  {
      if (this.props.banners.length > 0) {
         document.body.classList.add("modal-open");
      }
   }


   renderBanners() {
      const { benefit_program } = this.props;
      const { banners } = this.props;
      return banners.map((banner) => (
         <Banner
            key={banner.uid}
            bp={benefit_program.uid}
            banner={banner}
            onClick={e =>
               this.showBannerPromotion(
                  e,
                  banner.promotion_uid,
                  banner.poll_uid,
                  banner.mobile_menu
               )
            }
         />
      ));
   }

   render() {
      const { banners } = this.props;

      if (banners.length > 0) {
         let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            autoplaySpeed: 5000,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1
         };
         let styles = {
            display: "none"
         };
         if (this.state.isOpen) {
            styles = {
               display: "block"
            };
         } else {

            document.body.classList.remove("modal-open");
            document.body.classList.add("modal-close");
         }

         return (
            <div>
               <div
                  className="modal fade show"
                  id="exampleModal"
                  tabIndex="-1"
                  role="dialog"
                  style={styles}
               >
                  <div className="modal-dialog" role="document">
                     <div className="modal-content" style={{ padding: 0 }}>
                        <div className="modal-body" style={{ padding: 0 }}>
                           <button
                              type="button"
                              className="close"
                              onClick={this.toggleModal}
                           >
                              <span aria-hidden="true">&times;</span>
                           </button>
                           <Slider {...settings}>{this.renderBanners()}</Slider>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="modal-backdrop fade show" style={styles} />
            </div>
         );
      } else {
         return <div />;
      }
   }
}

Popups.defaultProps = {
   benefit_program: null,
   banners: []
};

export default connect(({ banners, benefitPrograms }) => ({
   benefit_program: benefitPrograms.benefitProgram,
   banners: banners.popups
}))(Popups);
