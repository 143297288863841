import * as types from "../common/constants/actionTypes";

const initialState = {
   data: [],
   next: null,
   isFetching: false,
   isFetchingMore: false,
   isFetchingPlace: false,
   place: null
};

export default function reducer(state = initialState, action) {
   switch (action.type) {
      case `${types.PLACES_FETCH}_PENDING`:
         return { ...state, isFetching: true, next: null };
      case `${types.PLACES_FETCH}_REJECTED`:
         return { ...state, isFetching: false, error: action.payload };
      case `${types.PLACES_FETCH}_FULFILLED`:
         if (action.payload.status === 200) {
            return Object.assign({}, state, {
               isFetching: false,
               data: action.payload.data.results,
               next: action.payload.data.next
            });
         } else {
            return Object.assign({}, state, {
               isFetching: false,
               data: []
            });
         }
      case `${types.PLACES_MORE_FETCH}_PENDING`:
         return { ...state, isFetchingMore: true };
      case `${types.PLACES_MORE_FETCH}_REJECTED`:
         return { ...state, isFetchingMore: false, error: action.payload };
      case `${types.PLACES_MORE_FETCH}_FULFILLED`:
         let data = state.data;
         for (var i = 0; i < action.payload.data.results.length; i++) {
            data.push(action.payload.data.results[i]);
         }
         return Object.assign({}, state, {
            isFetchingMore: false,
            data: data,
            next: action.payload.data.next
         });
      case `${types.DETAIL_PLACE}_PENDING`:
         return { ...state, isFetchingPlace: true, place: null };
      case `${types.DETAIL_PLACE}_REJECTED`:
         return {
            ...state,
            isFetchingPlace: false,
            place: null,
            error: action.payload
         };
      case `${types.DETAIL_PLACE}_FULFILLED`:
         return Object.assign({}, state, {
            isFetchingPlace: false,
            place: action.payload.data
         });
      default:
         return state;
   }
}
