import React from "react";

const SVG = ({className = ""}) => (
   <svg
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
   >
      <path d="m437.019531 74.980469c-48.351562-48.351563-112.640625-74.980469-181.019531-74.980469-68.382812 0-132.667969 26.628906-181.019531 74.980469-48.351563 48.351562-74.980469 112.636719-74.980469 181.019531 0 68.378906 26.628906 132.667969 74.980469 181.019531 48.351562 48.351563 112.636719 74.980469 181.019531 74.980469 68.378906 0 132.667969-26.628906 181.019531-74.980469 48.351563-48.355469 74.980469-112.640625 74.980469-181.019531 0-68.382812-26.628906-132.667969-74.980469-181.019531zm-181.019531 407.019531c-124.617188 0-226-101.382812-226-226s101.382812-226 226-226 226 101.382812 226 226-101.382812 226-226 226zm0 0"/>
      <path d="m384.53125 208.867188h-193.867188v-34.265626h129.605469c8.28125 0 15-6.71875 15-15v-64.269531c0-8.28125-6.71875-15-15-15h-144.605469c-8.28125 0-15 6.71875-15 15v321.332031c0 8.285157 6.71875 15 15 15h160.671876c8.28125 0 15-6.714843 15-15v-64.265624c0-8.285157-6.71875-15-15-15h-145.671876v-34.265626h193.867188c8.285156 0 15-6.71875 15-15v-64.269531c0-8.28125-6.714844-14.996093-15-14.996093zm-193.867188-98.535157h114.605469v34.269531h-114.605469zm130.671876 257.066407v34.265624h-130.671876v-34.265624zm48.195312-94.265626h-178.867188v-34.265624h178.867188zm0 0"/>

   </svg>
);

export default SVG;