import * as types from '../common/constants/actionTypes';

const initialState = {
  data: [],
  isFetching: false,
};

export default function reducer(state = initialState, action) {

  switch (action.type) {
    case `${types.LOCALITIES_FETCH}_PENDING`:
      return { ...state, isFetching: true };
    case `${types.LOCALITIES_FETCH}_REJECTED`:
      return { ...state, isFetching: false, error: action.payload };
    case `${types.LOCALITIES_FETCH}_FULFILLED`:
      return Object.assign({},
        state, {
          isFetching: false,
          data: action.payload.data,
        }
      );
    default:
      return state;
  }

}
