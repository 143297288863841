import {BOX_CHANGE, CLEAN_BOXES, DETAIL_BOX, FECH_BOXES} from "../../common/constants/actionTypes";
import HttpClient from "../../common/utils/httpClient";

export function getBoxes(programId) {
   return dispatch => HttpClient.get(dispatch, FECH_BOXES, `v5/me/programs/${ programId }/boxes/`);
}

export function getBox(programId, boxId) {
   return dispatch => HttpClient.get(dispatch, DETAIL_BOX, `v5/me/programs/${ programId }/boxes/${ boxId }/`);
}

export function changeBox(uid) {
   return {
      type: BOX_CHANGE,
      uid,
   };
}

export function cleanBoxes() {
   return {
      type: CLEAN_BOXES,
   };
}


